import { plusSignSVG } from '../../lib/allCustomSVGs';
import '../../styles/pages/products/productsPage.scss';
import { useEffect, useState } from 'react';
import { RootState } from '../../store/store';

//api call imports
import { PRODUCTS_ENDPOINT } from '../../lib/constants/urls';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import store from '../../store/store';

// other imports
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../ui/noData/NoData';
import { useNavigate } from 'react-router-dom';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import useTitle from '../../customHooks/useTitle';
import Popup from '../../ui/popup/Popup';
import ShareProduct from './ShareProduct';
import { useSelector } from 'react-redux';
import CustomDataGrid from '../../ui/customDataGrid.tsx/CustomDataGrid';
import CommanModal from './CommanModal';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import returnArrayOrNull from '../../lib/helper-functions/returnArrayOrNull';

interface Props {
  currentPageNumber: number;
  setCurrentPageNumber: (input: any) => void;
}

const AllProducts = ({ currentPageNumber, setCurrentPageNumber }: Props) => {
  const { t } = useTranslation();
  useTitle('Products');
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const [loading, setLoading] = useState('pending');
  // const shopId = useSelector((state: RootState) => state.user.company.shop_id);
  const navigate = useNavigate();

  /*------------------------- PAGINATION STATE START ------------------------- */
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  // const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [productData, setProductData] = useState([]);
  const itemsPerPage = 8;
  /*------------------------- PAGINATION STATE END ------------------------- */

  /* ----------- pagination stuf start ------------ */
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(totalRecords / itemsPerPage));
  }, [itemOffset, itemsPerPage, productData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    setCurrentPageNumber(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % totalRecords;
    setItemOffset(newOffset);
  };
  /* ----------- pagination stuf end   ------------ */

  /* share product modal state */
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  /* delete product modal state */
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  /* get company details from store */
  const userCompany = useSelector((state: RootState) => state.user.company);

  const [selectedProductDetails, setSelectedProductDetails] = useState<any>();
  const [deleteState, setDeleteState] = useState<number>(1);

  const togglePopupModal = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const toggleDeletePopupModal = () => {
    setIsDeletePopupOpen(!isDeletePopupOpen);
  };

  /* ----------------------------API CALL START---------------------------- */
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelsellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: `${PRODUCTS_ENDPOINT}?per_page=${itemsPerPage}&page=${currentPageNumber}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_ALL_PRODUCTS';
  const customMsg = 'Products Loaded Successfully.';

  useEffect(() => {
    const getProducts = async () => {
      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;

      if (!response.success) {
        setLoading('error');
      }
      if (response.result.data.length === 0) {
        setLoading('null');
      } else {
        if (res.actionType === actionType && response.success === true) {
          const productsResult = response?.result;
          setTotalRecords(productsResult?.total);

          const productsObj = returnArrayOrNull(productsResult);

          if (productsObj?.length > 0) {
            const productsArr: any = [];
            for (let i = 0; i < productsObj?.length; i++) {
              const a: any = Object.values(productsObj[i]);

              /* transforming images */
              let usingImages = [];
              let firstImage = `${staticURL}/small/default.jpg`;

              if (a[0]?.images === 'default.png') {
                usingImages.push('default.jpg');
              } else if (a[0]?.images?.length > 0) {
                /* console.log(convertedArr); */
                usingImages.push(a[0]?.images[0]);
              }

              console.log(usingImages);
              if (usingImages?.length > 0) {
                if (usingImages[0] === '') {
                  usingImages = [];
                } else {
                  firstImage = `${staticURL}/small/${usingImages[0]}`;
                }
              }

              productsArr.push({
                type: a[0]?.type,
                id: a[0]?.id,
                sku: a[0]?.sku,
                name: a[0]?.name,
                image: usingImages,
                // display_price: a[0]?.display_price,
                firstImage,
                productOrigin: a[0]?.product_origin,
              });
            }
            setLoading('success');
            setProductData(productsArr);
          }
        }
      }
    };

    getProducts();
  }, [currentPageNumber, deleteState]);

  /* ----------------------------API CALL END---------------------------- */

  /* ----------------------------DELETE PRODUCT API CALL START---------------------------- */
  const deleteHandler = async (productId: string | number) => {
    const deleteRequestInfo: RequestInfo = {
      url: `${PRODUCTS_ENDPOINT}/${productId}`,
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: accessToken.type + ' ' + sellerToken,
      },
    };

    const actionType = 'DELETE_PRODUCTS';
    const customMsg = t('ProductDeletedSuccessfully-msg');

    const res: any = await asyncHandler(
      deleteRequestInfo,
      actionType,
      customMsg
    );

    const response = res?.data;

    if (res.actionType === actionType && response.success === true) {
      setDeleteState(deleteState + 1);
    }
  };

  /* ----------------------------DELETE PRODUCT API CALL END------------------------------ */

  return (
    <SubPageContentHolder>
      <SubPageInnerContentHolder
        title={t('AllProducts')}
        subTitle={t('ManageProducts')}
        mainBtnIcon={plusSignSVG}
        mainBtnTitle={t('AddProduct')}
        mainBtnLink='/products/add'
      >
        <div className='productsPage'>
          <div className='productsPage__top'>
            <div className='productsPage__top--left'></div>
            <div className='productsPage__top--right'></div>
          </div>
          <div className='productsPage__bottom'>
            {/* dt */}
            <div className='productsPage__bottom--dataGridContainer'>
              {loading === 'null' && (
                <NoData
                  title={t('NoProductsFound-msg')}
                  /* subTitle='Add Products to view them here.' */
                />
              )}
              {loading === 'error' && (
                <NoData
                  title={t('FetchingDataError-msg')}
                  subTitle='Something went wrong :('
                />
              )}
              {loading === 'pending' && <LoadingSpinner />}
              {loading === 'success' && (
                <CustomDataGrid
                  allRows={productData}
                  titleField='name'
                  imageField='firstImage'
                  excludingField={['image', 'id']}
                  borderType={'none'}
                  onEditHandler={(params) => {
                    navigate(`/products/edit/${params?.sku}`);
                  }}
                  onShareHandler={(params) => {
                    setSelectedProductDetails(params);
                    togglePopupModal();
                  }}
                  onViewHandler={(params) => {
                    navigate(`/products/${params?.sku}`);
                  }}
                  onDeleteHandler={(params) => {
                    setSelectedProductDetails(params);
                    toggleDeletePopupModal();
                  }}
                  productOrigin='productOrigin'
                />
              )}
            </div>
            {loading === 'success' && (
              <ReactPaginate
                breakLabel='...'
                nextLabel={t(`${'Next'} >`)}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                initialPage={currentPageNumber - 1}
                pageCount={pageCount}
                previousLabel={t(`< ${'Previous'}`)}
                renderOnZeroPageCount={undefined}
                marginPagesDisplayed={2}
                containerClassName='pagination'
                pageLinkClassName='pagination__page-num'
                previousLinkClassName='pagination__page-num'
                nextLinkClassName='pagination__page-num'
                disabledLinkClassName='pagination__page-num-disabled'
                activeLinkClassName='pagination__active'
              />
            )}
            {/* dt */}
          </div>
        </div>
        {/* ------share popup start-------- */}
        <Popup
          styles={{
            backgroundColor: '#fff',
          }}
          setTrigger={togglePopupModal}
          trigger={isPopupOpen}
        >
          <ShareProduct
            shopSlug={userCompany.shop_slug}
            shopId={userCompany.shop_id}
            sku={selectedProductDetails?.sku}
            productType={selectedProductDetails?.type}
            isMainProduct={true}
          />
        </Popup>
        {/* ------share popup end-------- */}
        {/* ------delete popup start-------- */}
        <Popup
          styles={{
            backgroundColor: '#fff',
          }}
          setTrigger={toggleDeletePopupModal}
          trigger={isDeletePopupOpen}
        >
          <CommanModal
            handler={() => {
              deleteHandler(selectedProductDetails?.id);
            }}
            closeModal={toggleDeletePopupModal}
            mainTitle={`${t('DeleteProductSubTitle1')} (${
              selectedProductDetails?.name
            })`}
            subTitle1={t('DeleteProductSubTitle1')}
            subTitle2={t('DeleteProductSubTitle2')}
          />
        </Popup>
        {/* ------delete popup end-------- */}
      </SubPageInnerContentHolder>
    </SubPageContentHolder>
  );
};

export default AllProducts;
