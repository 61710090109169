import { motion } from 'framer-motion';
import {
  setAvailableCMS,
  setAvailablePSP,
  setPaymentMethods,
  updateOnboardingStage,
} from '../../store/reducers/userSlice';
import store from '../../store/store';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import { useEffect, useState } from 'react';
import '../../styles/pages/signup.scss';
import { useNavigate } from 'react-router-dom';
import {
  setAccessToken,
  setSubscriptionLock,
} from '../../store/reducers/authSlice';
import { updateProfile } from '../../store/reducers/userSlice';
import { stepsAndStagesMap } from '../../lib/constants/steps_stages_map';
import {
  GOOGLE_LOGIN_ENDPOINT,
  MODULES_CMS_ENDPOINT,
  MODULES_PSP_ENDPOINT,
  REGISTRATION_ENDPOINT,
} from '../../lib/constants/urls';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import lottieBanner from '../../assets/json/lottie_animation/cart.json';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import MessageSVG from '../../ui/customSVGs/MessageSVG';
import LockSVG from '../../ui/customSVGs/LockSVG';
import LoginInput from '../../ui/inputs/LoginInput';
import { setPreSelectedCMS_PSP } from '../../store/reducers/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import getPaymentMethods from '../../lib/helper-functions/getPaymentMethods';
import moment from 'moment';
import { RootState } from '../../store/store';
import RenderLoginPageLogo from '../login/RenderLoginPageLogo';

const SignUpPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authToken = store.getState().auth.authorization.accessToken;
  const [googleLoading, setGoogleLoading] = useState<boolean>(false);

  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const merchantName = useSelector(
    (state: RootState) => state.user?.merchant?.config?.merchant_name
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);
  // function changePage(pageName?: string) {
  //   if (pageName && pageName in OnboardingStagesMap) {
  //     dispatch(updateOnboardingStage(pageName));
  //   } else {
  //     dispatch(
  //       updateOnboardingStage(OnboardingStagesMap.SubmittingCompany.name)
  //     );
  //   }
  // }

  /* const [username, setUserName] = useState({
    first: '',
    second: '',
  }); */
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({ email: [], name: [], password: [] });
  const isDisabled =
    /* username.first === '' ||
    username.second === '' || */
    email === '' || password === '' ? true : false;

  //getting cms or psp types from params and saving them in store
  useEffect(() => {
    const params = window.location.search;
    if (params.includes('cms') && params.includes('psp')) {
      const str = params.substring(1).toLowerCase();
      const strArr = str.split('&');
      strArr.forEach((el: string) => {
        if (el.substring(0, 3) === 'cms') {
          const foundCMS = el.substring(4);
          console.log(`found cms: ${foundCMS}`);
          dispatch(setPreSelectedCMS_PSP({ type: 'cms', name: foundCMS }));
        } else if (el.substring(0, 3) === 'psp') {
          const foundPSP = el.substring(4);
          console.log(`found psp: ${foundPSP}`);
          dispatch(setPreSelectedCMS_PSP({ type: 'psp', name: foundPSP }));
        }
      });
    } else if (params.includes('cms')) {
      const str = params.substring(1).toLowerCase();
      const foundCMS = str.substring(4);
      console.log(`found cms: ${foundCMS}`);
      dispatch(setPreSelectedCMS_PSP({ type: 'cms', name: foundCMS }));
    } else if (params.includes('psp')) {
      const str = params.substring(1).toLowerCase();
      const foundPSP = str.substring(4);
      console.log(`found psp: ${foundPSP}`);
      dispatch(setPreSelectedCMS_PSP({ type: 'psp', name: foundPSP }));
    }
  }, []);

  const getAvailableCMS = async () => {
    const requestInfo: RequestInfo = {
      url: `${MODULES_CMS_ENDPOINT}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: authToken.type + ' ' + authToken.sellerToken,
      },
    };

    const actionType = 'GET_AVAILABLE_CMS_DATA';
    const customMsg = 'Available CMS Data Loaded Successfully.';

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res?.data;
    if (!response.success) {
      console.log('No Available CMS');
    }
    if (res.actionType === actionType && response.success === true) {
      if (response.result === null) {
        console.log('No Available CMS');
      } else {
        store.dispatch(setAvailableCMS(response?.result));
      }
    }
  };

  const getAvailablePSP = async () => {
    const requestInfo: RequestInfo = {
      url: `${MODULES_PSP_ENDPOINT}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: authToken.type + ' ' + authToken.sellerToken,
      },
    };

    const actionType = 'GET_AVAILABLE_CMS_DATA';
    const customMsg = 'Available CMS Data Loaded Successfully.';

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res?.data;
    if (!response.success) {
      console.log('No Available PSP');
    }
    if (res.actionType === actionType && response.success === true) {
      if (response.result === null) {
        console.log('No Available PSP');
      } else {
        store.dispatch(setAvailablePSP(response?.result));
        store.dispatch(setPaymentMethods(getPaymentMethods(response?.result)));
      }
    }
  };

  async function handleSignUpClick(e: any) {
    e.preventDefault();
    try {
      const requestInfo: RequestInfo = {
        url: REGISTRATION_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          email_address: email,
          password,
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'REGISTRATION_VERIFICATION';
      const customMsg = 'Registration Successful';

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        // onFormSubmitedSuccessfully('User Registration Successful');
        console.log('done');
        const token_type = 'Bearer';
        const redirect_to = '/onboarding';
        const onboarding_completed = false;
        const onboarding = {};
        const current_step = 'step_1';

        const auth_key = response.result.access_token;

        if (auth_key && auth_key !== '') {
          store.dispatch(
            setAccessToken({
              sellerToken: auth_key,
              merchantToken: undefined,
              adminToken: undefined,
              type: token_type,
              expiresOn: moment().add(21, 'days'),
            })
          );
          store.dispatch(
            updateProfile({
              email,
            })
          );
        }

        //* --- Get Available CMS & PSP --- *//
        getAvailableCMS();
        getAvailablePSP();

        //* --- Set Subscription Lock to false--- *//
        store.dispatch(setSubscriptionLock('false'));

        if (onboarding) {
          /* const { current_step } = onboarding; */

          store.dispatch(
            updateOnboardingStage(stepsAndStagesMap[current_step].name)
          );
        }

        if (onboarding_completed) {
          navigate('/dashboard');
        } else {
          navigate(redirect_to);
        }
      } else {
        setErrors(response.message);
        /*  onFormSubmitedFailed(
          // response.message.name ||
          response.message.email || response.message.password
        ); */

        // setErrors(response.message);
        // onFormSubmitedFailed(generateErrorMessage(response));
      }
    } catch (exception) {
      console.log('Error during authentication request');
    }
  }

  async function googleSignIn(e: any) {
    e.preventDefault();
    setGoogleLoading(true);
    try {
      const requestInfo: RequestInfo = {
        url: GOOGLE_LOGIN_ENDPOINT,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      };

      const actionType = 'GOOGLE_LOGIN_VERIFICATION';
      const customMsg = 'Redirecting to Google Login.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        const { google_url } = response.result;
        window.open(`${google_url}`, '_self');
      } else {
        setGoogleLoading(false);
        /*  onFormSubmitedFailed(response.message);*/

        console.log(response);
        /* onFormSubmitedFailed(generateErrorMessage(response)); */
      }
    } catch (exception) {
      setGoogleLoading(false);
      console.log('Error during authentication request');
    }
  }

  if (!authToken.sellerToken && !authToken.adminToken) {
    return (
      <motion.div {...pageSlideOutForward} className='signup'>
        <form onSubmit={handleSignUpClick} className='signup__left'>
          <div className='signup__logo'>
            <RenderLoginPageLogo
              staticURL={staticURL}
              merchantTheme={merchantTheme}
              isDarkMode={isDarkMode}
            />
          </div>
          <div className='signup__left--top'>
            <h1>
              {merchantTheme.signup_text1
                ? merchantTheme.signup_text1
                : t('SignUp')}
            </h1>
            <p>{t('SignUpSubText')}</p>
          </div>
          <div className='signup__left--center'>
            <LoginInput
              icon={<MessageSVG fill='#cecece' />}
              id='sign-up-form-email'
              value={email}
              type='email'
              changeListeners={[(e) => setEmail(e.target.value)]}
              focusListeners={[]}
              placeholder={t('EmailAddress')}
              required={true}
              pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
              title={t('Email-validation')}
            />
            <LoginInput
              icon={<LockSVG fill='#cecece' />}
              id='sign-up-form-password'
              value={password}
              type='password'
              changeListeners={[(e) => setPassword(e.target.value)]}
              focusListeners={[]}
              placeholder={t('Password')}
              required={true}
              pattern='[^ ]{8,20}'
              title={t('Password-validation')}
            />
            <a>
              {t('Agree')}{' '}
              <span
                onClick={() => {
                  window.open(`${merchantTheme?.terms_url}`, '_blank');
                }}
              >
                {t('Terms')}
              </span>{' '}
              {t('And')}{' '}
              <span
                onClick={() => {
                  window.open(`${merchantTheme?.privacy_url}`, '_blank');
                }}
              >
                {t('Privacy')}
              </span>
            </a>
          </div>
          <div className='signup__left--bottom'>
            <MainSquareButton
              value={t('Continue')}
              type='submit'
              /* disabled={isDisabled} */
              classes='mainSquareButton-extra-1'
            />
            <h2>
              {t('Or')}{' '}
              <span onClick={() => navigate('/login')}>{t('SignIn')} </span>
              {t('YourAccount')}
            </h2>
          </div>
          {/*  <div className='signup__left--other'>
            <img
              src={googleButton}
              alt='googleButton'
              onClick={!googleLoading ? googleSignIn : () => {}}
            />
          </div> */}
        </form>

        <div className='signup__right'>
          {/* <img src={bg} alt='signin-background' /> */}
          <div className='signup__right-lottie'>
            {merchantName !== 'AdUp' ? (
              <div className='signup__right-lottie-img'>
                <img
                  src={`${staticURL}/medium/${merchantTheme?.signup_image}`}
                  alt='login-pic'
                />
              </div>
            ) : (
              <Lottie animationData={lottieBanner} loop={true} />
            )}
          </div>
        </div>
      </motion.div>
    );
  } else {
    window.location.assign('/');
    return null;
  }
};

export default SignUpPage;
