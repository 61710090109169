import { AnimatePresence, motion } from 'framer-motion';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { CMS } from '../../interfaces/CMS';
import { addSelectedCMS } from '../../store/reducers/userSlice';
import { useSelector } from 'react-redux';
import { useAppDispatch, RootState } from '../../store/store';
import '../../styles/components/selection/cmsSelector.scss';
import { useTranslation } from 'react-i18next';
import makeFirstLetterCapital from '../../lib/helper-functions/makeFirstLetterCapital';

interface OptionProps {
  cms: CMS;
  ischecked: boolean;
  index: number;
  setSelectedIndex: Dispatch<SetStateAction<null | number>>;
  showAllCMS: boolean;
}

const CMSOption = ({
  cms,
  ischecked,
  index,
  setSelectedIndex,
  showAllCMS,
}: OptionProps) => {
  const preSelectedCMS = useSelector(
    (state: RootState) => state.user.company.preSelectedCMS_PSP.preSelectedCMS
  );
  // const { name, icon } = cms;
  const { t } = useTranslation();
  let name: string;
  if (typeof cms === 'string') {
    name = cms;
  } else {
    name = cms.name;
  }

  const onboardingObject = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);

  const { available_cms } = onboardingObject;

  const currentOrigin = window.location.origin;

  const updated_available_cms = [
    ...available_cms,
    /*  {
      magento: {
        label: 'magento',
        icon: `${currentOrigin}/cms-psp-logos/magento-logo.png`,
      },
    },
    {
      shopify: {
        label: 'shopify',
        icon: `${currentOrigin}/cms-psp-logos/shopify-logo.png`,
      },
    },
    {
      bigcommerce: {
        label: 'bigcommerce',
        icon: `${currentOrigin}/cms-psp-logos/bigcommerce-logo.png`,
      },
    }, */
    {
      other: {
        label: 'other',
        icon: `${currentOrigin}/cms-psp-logos/other-option.png`,
      },
    },
    {
      nocms: {
        label: 'nocms',
        icon: `${currentOrigin}/cms-psp-logos/do-not-have.png`,
      },
    },
  ];

  /*  console.log(updated_available_cms); */

  const currentCMS: any = updated_available_cms.find((item: any) => {
    const keyArr = Object.keys(item);
    const a = keyArr[0]?.toLowerCase();
    // @ts-ignore:next-line
    return a === name;
  });

  const currentValues: any = Object.values(currentCMS)[0];
  let currentIcon: string;
  if (
    /* currentValues.label === 'magento' || */
    currentValues.label === 'shopify' ||
    currentValues.label === 'bigcommerce' ||
    currentValues.label === 'other' ||
    currentValues.label === 'nocms'
  ) {
    currentIcon = currentValues.icon;
  } else {
    currentIcon = `${staticURL}/small/${currentValues.icon}`;
  }

  /*  if (name === 'bigcommerce') {
    headingName = 'BigCommerce';
  } else if (name === 'woocommerce') {
    headingName = 'WooCommerce';
  } else {
    headingName = makeFirstLetterCapital(name);
  } */

  const renderH1 = () => {
    let headingName;
    if (name === 'nocms') {
      headingName = t('NoCMS');
    } else if (name === 'other') {
      headingName = t('OtherCMS');
    } else if (name === 'bigcommerce') {
      headingName = 'BigCommerce';
    } else if (name === 'woocommerce') {
      headingName = 'WooCommerce';
    } else if (name === 'magento') {
      headingName = 'Magento';
    } else {
      headingName = makeFirstLetterCapital(name);
    }

    return headingName;
  };

  useEffect(() => {
    if (String(cms) === preSelectedCMS || String(cms.name) === preSelectedCMS) {
      setSelectedIndex(index);
    }
  }, []);

  const selectOptionTSX = (
    <div
      onClick={() => {
        setSelectedIndex(index);
      }}
      className={'cmsSelector__option'}
    >
      <div
        className={
          'cmsSelector__option--content border-[#e5e5e5]' +
          (ischecked ? ' border-[rgb(20,184,166)]' : '')
        }
      >
        <div className='cmsSelector__option--content-left'>
          {/* {name !== 'other' && ( */}
          <div className='cmsSelector__option--content-left-img'>
            <img
              src={currentIcon} //! Always make sure the png name is identical to the API value.
              alt={`${name} icon`}
            />
          </div>
          {/*    )} */}
          <div className='cmsSelector__option--content-left-text'>
            {/* h1 */}
            <h1>{renderH1()}</h1>

            {/* h3 */}
            {/* {name === 'magento' && <h3>{t('ComingSoon')}</h3>} */}
            {name === 'shopify' && <h3>{t('ComingSoon')}</h3>}
            {name === 'bigcommerce' && <h3>{t('ComingSoon')}</h3>}
          </div>
        </div>
      </div>
      {ischecked && (
        <motion.div className='cmsSelector__option--check  '>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='1.8rem'
            height='1.8rem'
            viewBox='0 0 24 24'
            fill='none'
            stroke='teal'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='feather feather-check absolute '
          >
            <polyline points='20 6 9 17 4 12'></polyline>
          </svg>
        </motion.div>
      )}
    </div>
  );

  const renderSelectOption = () => {
    if (showAllCMS) {
      return selectOptionTSX;
    } else {
      if (
        preSelectedCMS === String(cms) ||
        preSelectedCMS === String(cms.name)
      ) {
        return selectOptionTSX;
      } else if (!preSelectedCMS) {
        return selectOptionTSX;
      }
    }
  };

  return <>{renderSelectOption()}</>;
};

type props = {
  setSelectedCMS: (cmsName: string) => void;
  options: CMS[];
  showAllCMS: boolean;
};

/* ---------------------------CMSSelector--------------------------- */
const CMSSelector: React.FC<props> = ({
  options = [],
  setSelectedCMS,
  showAllCMS,
}) => {
  const [checkedCMSIdx, setCheckedCMSIdx] = useState<null | number>(null);

  // "Magento" , "Shopify" and "BigCommerce"
  const updatedOptions = [
    ...options,
    /*  {
      name: 'magento',
      icon: '',
    },
    {
      name: 'shopify',
      icon: '',
    },
    {
      name: 'bigcommerce',
      icon: '',
    }, */
    { name: 'other', icon: '' },
    { name: 'nocms', icon: '' },
  ];

  /* console.log(updatedOptions); */

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (checkedCMSIdx || checkedCMSIdx === 0) {
      if (typeof updatedOptions[checkedCMSIdx] === 'string') {
        // @ts-ignore:next-line
        dispatch(addSelectedCMS(updatedOptions[checkedCMSIdx]));
        // @ts-ignore:next-line
        setSelectedCMS(updatedOptions[checkedCMSIdx]);
      } else {
        dispatch(addSelectedCMS(updatedOptions[checkedCMSIdx].name));
        setSelectedCMS(updatedOptions[checkedCMSIdx].name);
      }
    }
  }, [checkedCMSIdx]);

  return (
    <div className='cmsSelector'>
      <div className='multiple-item-selector-inner cmsSelector__wrapper'>
        <AnimatePresence>
          <motion.div className='cmsSelector__content'>
            {updatedOptions.map((option, key) => (
              <CMSOption
                cms={option}
                key={key}
                index={key}
                setSelectedIndex={setCheckedCMSIdx}
                ischecked={key === checkedCMSIdx}
                showAllCMS={showAllCMS}
              />
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default CMSSelector;
