import { height } from '@mui/system';
import { useState } from 'react';
import '../../styles/components/productWizard/productWizard.scss';
import WizardEnd from './WizardEnd';
import WizardNavbar from './WizardNavbar';
import WizardStart from './WizardStart';
import WizardSteps from './WizardSteps';

const ProductWizard = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [addedProductSKU, setAddedProductSKU] = useState(null);

  const increaseStep = () => {
    if (currentStep < 8) {
      setCurrentStep((prev) => prev + 1);
    }
  };
  const decreaseStep = () => {
    if (currentStep > 0) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const renderPages = () => {
    if (currentStep === 0) {
      return (
        <WizardStart
          currentStep={currentStep}
          increaseStep={increaseStep}
          decreaseStep={decreaseStep}
        />
      );
    } else if (currentStep === 8) {
      return (
        <WizardEnd
          currentStep={currentStep}
          increaseStep={increaseStep}
          decreaseStep={decreaseStep}
          addedProductSKU={addedProductSKU}
        />
      );
    } else {
      return (
        <WizardSteps
          increaseStep={increaseStep}
          decreaseStep={decreaseStep}
          currentStep={currentStep}
          setAddedProductSKU={setAddedProductSKU}
        />
      );
    }
  };

  return (
    <div
      style={{ height: `${currentStep === 8 ? '50rem' : '40rem'}` }}
      className='productWizard'
    >
      {currentStep !== 0 && currentStep !== 8 && (
        <WizardNavbar currentStep={currentStep} />
      )}
      {renderPages()}
    </div>
  );
};

export default ProductWizard;
