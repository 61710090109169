const makeFirstLetterCapital = (sentence: string) => {
  let sentenceLowercase, formatedSentence;
  sentenceLowercase = sentence.toLowerCase();

  if (sentenceLowercase.length > 1) {
    const sentenceWithoutFirstLetter = sentenceLowercase.substring(1);
    const firstLetterOfSentence = sentenceLowercase[0].toUpperCase();
    formatedSentence = firstLetterOfSentence + sentenceWithoutFirstLetter;
    return formatedSentence;
  }
};

export default makeFirstLetterCapital;
