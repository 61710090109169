import { useLocation, Route, Routes, useNavigate } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';
import getCurrentRoute from '../../../../lib/helper-functions/getCurrentRoute';
import AddCMS from './AddCMS';
import EditCMS from './EditCMS';

//api call imports
import {
  CMS_ENDPOINT,
  MODULES_CMS_ENDPOINT,
} from '../../../../lib/constants/urls';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../../lib/api/async-handler';
import store from '../../../../store/store';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import { useTranslation } from 'react-i18next';
import { setAvailableCMS } from '../../../../store/reducers/userSlice';

const CMSConnection = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const activeLink = location.pathname;
  const [loading, setLoading] = useState('pending');
  const [cmsData, setCMSData] = useState([]);
  // const shopId = useSelector((state: RootState) => state.user.company.shop_id);

  const currentRoute = getCurrentRoute(location);
  let subTitle = '';

  if (currentRoute === 'add') {
    subTitle = t('AddCMS-Connection');
  } else if (currentRoute === 'connection') {
    subTitle = t('EditCMS-Connection');
  } else {
    subTitle = t('ManageCMS');
  }

  /* ----------------------------API CALL START---------------------------- */
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelsellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const getAvailableCMS = async () => {
    setLoading('pending');
    const requestInfo: RequestInfo = {
      url: `${MODULES_CMS_ENDPOINT}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: accessToken.type + ' ' + sellerToken,
      },
    };

    const actionType = 'GET_AVAILABLE_CMS_DATA';
    const customMsg = 'Available CMS Data Loaded Successfully.';

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res?.data;
    if (!response.success) {
      setLoading('error');
      console.log('No Available CMS');
      throw new Error(response.message);
    }
    if (res.actionType === actionType && response.success === true) {
      if (response.result === null) {
        setLoading('null');
        console.log('No Available CMS');
      } else {
        store.dispatch(setAvailableCMS(response?.result));
        getCMS();
      }
    }
  };

  const getCMS = async () => {
    const requestInfo: RequestInfo = {
      url: `${CMS_ENDPOINT}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: accessToken.type + ' ' + sellerToken,
      },
    };

    const actionType = 'GET_CMS_DATA';
    const customMsg = 'CMS Data Loaded Successfully.';

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res?.data;
    if (!response.success) {
      setLoading('error');
      navigate('/shop/cms/connection/add');
    }
    if (res.actionType === actionType && response.success === true) {
      if (response.result === null) {
        navigate('/shop/cms/connection/add');
        setLoading('null');
      } else {
        setLoading('success');
        setCMSData(response.result);
      }
    }
  };
  useEffect(() => {
    if (currentRoute === 'connection') getAvailableCMS();
  }, [currentRoute]);
  /* ----------------------------API CALL END---------------------------- */
  const getBackButtonRoutes = () => {
    let backButtonLink;
    if (currentRoute === 'add') {
      backButtonLink = '/shop';
    } else if (currentRoute === 'connection') {
      backButtonLink = '/shop';
    } else {
      backButtonLink = '/shop';
    }

    return backButtonLink;
  };

  return (
    <SubPageInnerContentHolder
      title={t('CMS-Connection')}
      subTitle={subTitle}
      backBtnLink={isWhiteLabel ? undefined : getBackButtonRoutes()}
      // mainBtnIcon={plusSignSVG}
      // mainBtnTitle='Add CMS'
      // mainBtnLink='/shop/cms/connection/add'
    >
      {activeLink === '/shop/cms/connection' && (
        <>
          {loading === 'pending' && <LoadingSpinner />}
          {loading === 'error' && (
            <NoData
              title={t('ErrorFetchingData')}
              subTitle={t('SomethingWentWrong-msg')}
            />
          )}
          {cmsData?.length > 0 && <EditCMS cmsData={cmsData} />}
        </>
      )}

      <Routes>
        <Route path='/add' element={<AddCMS />} />
      </Routes>
    </SubPageInnerContentHolder>
  );
};

export default CMSConnection;
