import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import ToggleSwitch from '../../../ui/buttons/ToggleSwitch';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import store from '../../../store/store';
import { SETTINGS_ENDPOINT } from '../../../lib/constants/urls';
import { asyncHandler } from '../../../lib/api/async-handler';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import { useTranslation } from 'react-i18next';
import { setUserCompanySettings } from '../../../store/reducers/userSlice';
import { RootState } from '../../../store/store';
import { useSelector } from 'react-redux';

const SettingsNotifications = () => {
  const location = useLocation();
  const activeLink = location.pathname;
  const [sendNewsLetter, setSendNewsLetter] = useState<boolean>(false);
  const [loading, setLoading] = useState('pending');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const newsLetterText = useSelector(
    (state: RootState) => state.user.merchant.config.monthly_newsletter_text
  );
  const { t } = useTranslation();

  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelsellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  useEffect(() => {
    const response = getNotificationsSettings();
    response
      .then((result) => {
        setSendNewsLetter(
          result?.send_monthly_newsletter === 'true' ? true : false
        );
        setLoading('success');
      })
      .catch((err) => {
        console.error(err);
        setLoading('error');
      });
  }, []);

  /* ----------------------------API CALL START---------------------------- */
  async function getNotificationsSettings() {
    try {
      const requestInfo: RequestInfo = {
        url: SETTINGS_ENDPOINT + '?group=Notifications',
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_NOTIFICATIONS_SETTINGS';
      const customMsg = 'Notifications Settings fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return response.result;
      }
    } catch (exception) {
      console.log('Error during request');
    }
  }

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const sendingData = {
        group: 'Notifications',
        send_monthly_newsletter: sendNewsLetter,
      };

      const requestInfo: RequestInfo = {
        url: SETTINGS_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'UPDATE_NOTIFICATIONS_SETTINGS';
      const customMsg = t('SettingsUpdatedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      setIsSubmitting(false);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        store.dispatch(setUserCompanySettings(response?.result));
        return response.result;
      }
    } catch (exception) {
      console.log('Error during authentication request');
      setIsSubmitting(false);
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  return (
    <SubPageInnerContentHolder
      title={t('NotificationSettings')}
      subTitle={t('ManageNotificationSettings')}
      backBtnLink={isWhiteLabel ? undefined : '/products'}
    >
      <>
        {loading === 'pending' && <LoadingSpinner />}
        {loading === 'error' && (
          <NoData
            title={t('ErrorFetchingData')}
            subTitle={t('SomethingWentWrong-msg')}
          />
        )}
        {loading === 'success' && (
          <form
            id='notification-settings-form'
            onSubmit={onSubmitHandler}
            className='normalForm'
          >
            <div className='normalForm__top'>
              {/* <h1>Add the Following Information.</h1> */}

              <ToggleSwitch
                isOn={sendNewsLetter}
                title={
                  newsLetterText
                    ? newsLetterText
                    : t('SendMonthlyAdUpNewsletter')
                }
                onClick={setSendNewsLetter}
                classes='margin-top-2rem'
              />
            </div>

            <div className='normalForm__bottom flex-start'>
              <MainSquareButton
                value={
                  isSubmitting ? `${t('Updating')}...` : t('UpdateSettings')
                }
                /* icon={<CustomSVGs svg={plusSignSVG} />} */
                disabled={isSubmitting}
                type='submit'
                form='notification-settings-form'
              />
            </div>
          </form>
        )}
      </>
    </SubPageInnerContentHolder>
  );
};

export default SettingsNotifications;
