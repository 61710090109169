import { t } from 'i18next';
import { setIsInitSuccess, setMessage } from '../../store/reducers/appSlice';
import { clearAuthSlice } from '../../store/reducers/authSlice';
import { clearUserSlice } from '../../store/reducers/userSlice';
import store from '../../store/store';
import { INIT_URL } from '../constants/urls';
// import { applicationWWWXRLEncodedBodyBuilder } from '../utils/body-builder';
import { authenticateSeller } from './initial-authentication';
import { tokenRevokeAtLogOutUser } from './logout-user';
import { initResponseStateUpdate } from './state-updates';
import { submitLog } from './log';

let redirectTo = '/';
export async function fetchAppInit() {
  try {
    const authToken = store.getState().auth.authorization.accessToken;

    const requestHeaders: { [idx: string]: any } = {};

    if (authToken?.sellerToken && authToken.sellerToken !== '') {
      requestHeaders['Authorization'] =
        authToken.type + ' ' + authToken.sellerToken;
    } else if (authToken?.merchantToken && authToken.merchantToken !== '') {
      requestHeaders['Authorization'] =
        authToken.type + ' ' + authToken.merchantToken;
    } else if (authToken?.adminToken && authToken.adminToken !== '') {
      requestHeaders['Authorization'] =
        authToken.type + ' ' + authToken.adminToken;
    }

    const response = await fetch(INIT_URL, {
      method: 'GET',
      headers: requestHeaders,
    });

    const res = await response.json();
    const { result: data } = res;

    if (data.user_object) {
      redirectTo = authenticateSeller(data.user_object);
    } else {
      redirectTo = '/login';
    }

    if (res.success) {
      store.dispatch(setIsInitSuccess(true));
      initResponseStateUpdate(data);
    } else {
      store.dispatch(setIsInitSuccess(false));
      logoutHandler();
      store.dispatch(
        setMessage({
          message: t('SessionExpired'),
          messageType: 'error',
        })
      );
      // window.open(`/login`, '_self');
      redirectTo = '/login';
    }
  } catch (exception) {
    store.dispatch(setIsInitSuccess(false));
    logoutHandler();
    store.dispatch(
      setMessage({
        message: t('SessionExpired'),
        messageType: 'error',
      })
    );
    // window.open(`/login`, '_self');
    redirectTo = '/login';
    console.log('Error fetching init response', exception);
    await submitLog('error', 'Error fetching init response');
  }
  return redirectTo;
}

const logoutHandler = () => {
  tokenRevokeAtLogOutUser();
  // @ts-ignore:next-line
  window.Intercom('shutdown'); //shutdown intercom
  store.dispatch(clearUserSlice());
  store.dispatch(clearAuthSlice());
  window.localStorage.clear();
};
