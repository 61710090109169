/* ------api imports------- */
import store from '../../store/store';
import { applicationWWWXRLEncodedBodyBuilder } from '../utils/body-builder';
import { ONBOARDING_ENDPOINT } from '../constants/urls';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from './async-handler';

/* ------api connection------- */
export async function submitCustomOption(
  optionType: 'psp' | 'cms',
  optionValue: string
) {
  const optionTypeInCaps = optionType?.toUpperCase();
  try {
    const accessToken = store.getState().auth.authorization.accessToken;

    /*  */
    const requestInfo: RequestInfo = {
      url: ONBOARDING_ENDPOINT,
      method: 'POST',
      body: applicationWWWXRLEncodedBodyBuilder({
        onboarding_data: JSON.stringify({
          onboard_type: `custom_${optionType}`,
          data: optionValue,
        }),
      }),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: accessToken.type + ' ' + accessToken.sellerToken,
      },
    };

    const actionType = `CREATE_ONBOARDING_${optionTypeInCaps}_OPTION`;
    const customMsg = `Custom ${optionTypeInCaps} option submitted Successfuly.`;

    const res: any = await asyncHandler(requestInfo, actionType, customMsg);

    const response = res.data;

    if (res.actionType === actionType && response.success === true) {
      return true;
    } else {
      return null;
    }
  } catch (exception) {
    console.log('Error during request');
  }
}
