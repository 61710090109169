import { useState } from 'react';
import { plusSignSVG, trashSVG } from '../../../lib/allCustomSVGs';
import '../../../styles/pages/products/addProductAttributes.scss';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import SquareGrayButton from '../../../ui/buttons/SquareGrayButton';
import NormalInput from '../../../ui/inputs/NormalInput';
import { AnimatePresence, motion } from 'framer-motion';
import TagInput from '../../../ui/inputs/TagInput';
import { VariantAttributesInterface } from '../../../lib/initialStates/productPagesInitialState';
import { useTranslation } from 'react-i18next';

interface Props {
  variantAttributes: VariantAttributesInterface[];
  setVariantAttributes: (input: VariantAttributesInterface[]) => void;
}

const AddProductAttributes = ({
  variantAttributes,
  setVariantAttributes,
}: Props) => {
  const { t } = useTranslation();
  const [name, setName] = useState('');
  /*  const [value, setValue] = useState(''); */

  const addBtnHandler = () => {
    if (variantAttributes.length === 0) {
      setVariantAttributes([{ id: 0, name: '', value: [] }]);
    } else {
      const lastItem = variantAttributes[variantAttributes.length - 1];
      const lastItemId = lastItem.id;
      setVariantAttributes([
        ...variantAttributes,
        { id: lastItemId + 1, name: '', value: [] },
      ]);
    }
  };

  const deleteBtnHandler = (id: number) => {
    if (variantAttributes.length === 0) {
      return;
    } else {
      const updatedItemArr = variantAttributes.filter((item) => item.id !== id);
      setVariantAttributes(updatedItemArr);
    }
  };

  const onChangeHandler = (
    e:
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>,
    inputType: 'name' | 'value'
  ) => {
    const changedInputIndex = variantAttributes.findIndex(
      (item) => item.id === +e.target.id
    );
    const variantAttributessCopy = variantAttributes;
    if (typeof changedInputIndex === 'number') {
      if (inputType === 'name') {
        variantAttributessCopy[changedInputIndex].name = e.target.value;
        setVariantAttributes([...variantAttributessCopy]);
      } /* else {
        variantAttributessCopy[changedInputIndex].value = e.target.value;
        setVariantAttributes([...variantAttributessCopy]);
      } */
    }
  };

  const selectedTages = (tags: string[], id?: string) => {
    // console.log(id, tags);
    const unchangedAttributes = variantAttributes.filter(
      (item: VariantAttributesInterface) => {
        return item.id.toString() !== id;
      }
    );
    const changedAttributeArr = variantAttributes.filter(
      (item: VariantAttributesInterface) => {
        return item.id.toString() === id;
      }
    );

    if (changedAttributeArr.length > 0) {
      const changedAttribute = changedAttributeArr[0];
      changedAttribute.value = tags;

      setVariantAttributes([...unchangedAttributes, changedAttribute]);
    }
  };

  return (
    <div className='addProductAttributes'>
      <div className='addProductAttributes__top'>
        <div className='addProductAttributes__top--title'>
          <h1>{t('Attributes')}</h1>
        </div>
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            addBtnHandler();
          }}
          type={'button'}
          value={t('AddAttributes')}
          icon={plusSignSVG}
          normal={true}
        />
      </div>

      <div className='addProductAttributes__bottom'>
        <AnimatePresence>
          {variantAttributes.map(
            (item: VariantAttributesInterface, i: number) => {
              return (
                <motion.div
                  initial={{ scale: 0.95, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  exit={{ scale: 0.95, opacity: 0 }}
                  transition={{ type: 'spring' }}
                  key={i}
                  className='addProductAttributes__bottom--item'
                >
                  <div className='addProductAttributes__bottom--item-inputs'>
                    <div className='addProductAttributes__bottom--item-inputs-name'>
                      {/* ---------NORMAL INPUT START---------- */}
                      <NormalInput
                        changeListeners={[(e) => onChangeHandler(e, 'name')]}
                        id={item.id.toString()}
                        value={name}
                        placeholder={t('AttributeName')}
                        required={true}
                        autoFocus={true}
                      />
                      {/* ---------NORMAL INPUT END------------ */}
                    </div>

                    <div className='addProductAttributes__bottom--item-inputs-value'>
                      {/* ---------NORMAL INPUT START---------- */}
                      {/* <NormalInput
                        changeListeners={[(e) => onChangeHandler(e, 'value')]}
                        id={item.id.toString()}
                        value={value}
                        placeholder='Value'
                        required={true}
                      /> */}
                      <TagInput
                        selectedTags={selectedTages}
                        id={i.toString()}
                      />
                      {/* ---------NORMAL INPUT END------------ */}
                    </div>
                  </div>

                  <div className='addProductAttributes__bottom--item-btn'>
                    <SquareGrayButton
                      onClick={(e) => {
                        e.preventDefault();
                        deleteBtnHandler(item.id);
                      }}
                      type={'button'}
                      icon={trashSVG}
                      normal={false}
                      /* value={(item + 1).toString()} */
                      classes='squareGrayButton-delete'
                    />
                  </div>
                </motion.div>
              );
            }
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default AddProductAttributes;
