import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import './splash-screen.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const SplashScreen = () => {
  const { t } = useTranslation();
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  return (
    <motion.div className='splash-screen'>
      <img
        src={`${staticURL}/medium/${merchantTheme?.merchant_logo_light}`}
        alt={'logo'}
      />
      <h2>{t('SplashScreenSlogan')}</h2>
    </motion.div>
  );
};

export default SplashScreen;
