import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../../../styles/pages/shop/psp/noPSP.scss';
import store from '../../../../store/store';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { ADUP_PAY_ENDPOINT } from '../../../../lib/constants/urls';
import { asyncHandler } from '../../../../lib/api/async-handler';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import Popup from '../../../../ui/popup/Popup';

const NoPSP = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [adUpPayAuthUrl, setAdUpPayAuthUrl] = useState();

  const isSafariBrowser = store.getState().app.browser.includes('Safari');

  const togglePopupModal = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleAdUpPayOnboarding = async () => {
    setIsLoading(true);
    try {
      const accessToken = store.getState().auth.authorization.accessToken;

      const requestInfo: RequestInfo = {
        url: ADUP_PAY_ENDPOINT + '/onboarding',
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + accessToken.sellerToken,
        },
      };

      const actionType = 'GET_ADUP_PAY_AUTH_URL';
      const customMsg = 'AdUp Pay onboarding Successful.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (
        res.actionType === actionType &&
        response.success &&
        response.result?.auth_url
      ) {
        if (isSafariBrowser) {
          setAdUpPayAuthUrl(response.result?.auth_url);
          togglePopupModal();
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        } else {
          window.open(
            response.result?.auth_url,
            '_blank',
            'toolbar=no,location=no,top=80, left=420, status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=700'
          );
          setTimeout(() => {
            setIsLoading(false);
            navigate('/shop');
          }, 5000);
        }
      } else {
        setIsLoading(false);
      }
    } catch (exception) {
      setIsLoading(false);
      console.log('Error during authentication request');
    }
  };

  return (
    <>
      {isLoading && (
        <>
          <LoadingSpinner customLoadingMsg={t('ProcessingAdUpPay')} />
          <br />
        </>
      )}
      {!isLoading && (
        <>
          <div className='nopsp__container1'>
            <h2
              onClick={() => {
                navigate('/shop/psp/connection/add');
              }}
              className='nopsp__container1--link'
            >
              {t('No-PSP-link-text')}
            </h2>
          </div>
          <div className='nopsp__container1'>
            <h2 className='nopsp__container1--text'>{t('Or')}</h2>
          </div>
          <div className='nopsp__container1'>
            <button className='nopsp__btn' onClick={handleAdUpPayOnboarding}>
              <p>{t('No-PSP-btn-text-1')}</p>
              <span>{t('No-PSP-btn-text-2')}</span>
            </button>
          </div>
        </>
      )}

      {/* //* ------popup only for Safari Browser -------- */}
      <Popup setTrigger={togglePopupModal} trigger={isPopupOpen}>
        <div className='nopsp__container1' style={{ paddingTop: '30px' }}>
          <button
            className='nopsp__btn'
            onClick={() => {
              window.open(
                adUpPayAuthUrl,
                '_blank',
                'toolbar=no,location=no,top=80, left=420, status=no,menubar=no,scrollbars=yes,resizable=yes,width=600,height=700'
              );
              setTimeout(() => {
                setIsLoading(false);
                navigate('/shop');
              }, 1000);
            }}
          >
            <span>{t('No-PSP-btn-text-3')}</span>
          </button>
        </div>
      </Popup>
    </>
  );
};

export default NoPSP;
