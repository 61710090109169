import '../../styles/pages/products/shareProduct.scss';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import { copySVG, filledCheckboxSVG } from '../../lib/allCustomSVGs';
import { useState } from 'react';
import { openWindowOnClick } from '../../lib/open-popupWindow-script';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

interface Props {
  shopSlug: string;
  sku: string | undefined;
  shopId: number | string | undefined;
  isMainProduct?: boolean;
  productType?: string;
}

const ShareProduct = ({
  shopSlug,
  shopId,
  sku,
  isMainProduct,
  productType,
}: Props) => {
  const [isCoppyBtnClicked, setIsCoppyBtnClicked] = useState<any>({
    socialCommerce: false,
    fastCheckout: false,
    qr: false,
  });
  const { href } = window.location;
  const { t } = useTranslation();

  const fastcheckout_domain = useSelector(
    (state: RootState) => state.user.merchant.fastcheckout_domain
  );

  //TODO: merchant name should be taken from shop node in the future when the backend is ready.
  const merchant_name = useSelector(
    (state: RootState) => state.user.merchant.config.merchant_name
  );

  const usingFastCheckoutDomain =
    merchant_name === 'XXL Nutrition'
      ? 'https://xxlnutrition.secure-checkout.eu'
      : fastcheckout_domain;

  function copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
  }

  const coppyBtnClickHandler = (
    property: 'socialCommerce' | 'fastCheckout' | 'qr'
  ) => {
    setIsCoppyBtnClicked((prev: any) => {
      return { ...prev, [property]: true };
    });

    const timer = setTimeout(() => {
      setIsCoppyBtnClicked((prev: any) => {
        return { ...prev, [property]: false };
      });
      clearTimeout(timer);
    }, 1000);
  };

  const renderFastcheckoutSection = () => {
    if (isMainProduct && productType === 'variable') {
      return (
        <ul>
          <li>{t('ShareProduct-FC-text-1')}</li>
          <li>{t('ShareProduct-FC-text-2')}</li>
        </ul>
      );
    } else {
      return (
        <>
          <p>{t('ShareProduct-FC-text-3')}</p>
          <span
            className='shareProduct-link'
            onClick={() => {
              openWindowOnClick(
                `${usingFastCheckoutDomain}/fastcheckout/${shopSlug}/${sku}`
              );
            }}
          >{`${usingFastCheckoutDomain}/fastcheckout/${shopSlug}/${sku}`}</span>
        </>
      );
    }
  };

  return (
    <div className='shareProduct'>
      <div className='shareProduct__title'>
        <h1>{t('ShareProduct')}</h1>
      </div>
      <div className='shareProduct__container1'>
        <div className='shareProduct__container1--left'>
          <h1>{t('SocialCommerce')}</h1>
          <p>{t('ShareProduct-SC-text-1')}</p>
          <span
            className='shareProduct-link'
            onClick={() => {
              openWindowOnClick(
                `${usingFastCheckoutDomain}/${shopSlug?.replaceAll(
                  ' ',
                  ''
                )}/${sku}`
              );
            }}
          >{`${usingFastCheckoutDomain}/${shopSlug?.replaceAll(
            ' ',
            ''
          )}/${sku}`}</span>
        </div>

        <div className='shareProduct__container1--right'>
          <MainSquareButton
            onClick={(e) => {
              e.preventDefault();
              copyToClipboard(
                `${usingFastCheckoutDomain}/${shopSlug?.replaceAll(
                  ' ',
                  ''
                )}/${sku}`
              );
              coppyBtnClickHandler('socialCommerce');
            }}
            type={'button'}
            value={isCoppyBtnClicked.socialCommerce ? t('Copied') : t('Copy')}
            icon={
              isCoppyBtnClicked.socialCommerce ? filledCheckboxSVG : copySVG
            }
            normal={true}
          />
        </div>
      </div>
      <div className='shareProduct__container1'>
        <div className='shareProduct__container1--left'>
          <h1>{t('FastCheckout')}</h1>
          {renderFastcheckoutSection()}
        </div>

        <div className='shareProduct__container1--right'>
          {productType !== 'variable' && (
            <MainSquareButton
              onClick={(e) => {
                e.preventDefault();
                copyToClipboard(
                  // `${usingFastCheckoutDomain}/fastcheckout/${shopId}/${sku}`
                  `${usingFastCheckoutDomain}/fastcheckout/${shopSlug}/${sku}`
                );
                coppyBtnClickHandler('fastCheckout');
              }}
              type={'button'}
              value={isCoppyBtnClicked.fastCheckout ? t('Copied') : t('Copy')}
              icon={
                isCoppyBtnClicked.fastCheckout ? filledCheckboxSVG : copySVG
              }
              normal={true}
            />
          )}
        </div>
      </div>
      <div className='shareProduct__container2'>
        <div className='shareProduct__container2--left'>
          <h1>{t('QR-Code')}</h1>
          <p>{t('ShareProduct-QR-text-1')}</p>
          <span>{t('ShareProduct-QR-text-2')}</span>
          <ul>
            <li>
              {t('ShareProduct-QR-text-3')} &#40;{' '}
              <span>{`${usingFastCheckoutDomain}/${shopSlug?.replaceAll(
                ' ',
                ''
              )}/${sku}`}</span>{' '}
              &#41;
            </li>
            <li>
              {t('ShareProduct-QR-text-4')}
              <a target={'_blank'} href='https://www.qrcode-monkey.com'>
                https://www.qrcode-monkey.com/
              </a>{' '}
            </li>
            <li>{t('ShareProduct-QR-text-5')}</li>
            <li>{t('ShareProduct-QR-text-6')}</li>
            <li>{t('ShareProduct-QR-text-7')}</li>
          </ul>
        </div>

        <div className='shareProduct__container2--right'>
          <MainSquareButton
            onClick={(e) => {
              e.preventDefault();
              copyToClipboard(
                `${usingFastCheckoutDomain}/${shopSlug?.replaceAll(
                  ' ',
                  ''
                )}/${sku}`
              );
              coppyBtnClickHandler('qr');
            }}
            type={'button'}
            value={isCoppyBtnClicked.qr ? t('Copied') : t('Copy')}
            icon={isCoppyBtnClicked.qr ? filledCheckboxSVG : copySVG}
            normal={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ShareProduct;
