import { motion } from 'framer-motion';
import store from '../../store/store';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import { useState } from 'react';
import '../../styles/pages/signup.scss';
import { useNavigate } from 'react-router-dom';
import { FORGOT_PASSWORD_ENDPOINT } from '../../lib/constants/urls';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import lottieBanner from '../../assets/json/lottie_animation/stopwatch_1.json';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import MessageSVG from '../../ui/customSVGs/MessageSVG';
import LoginInput from '../../ui/inputs/LoginInput';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import RenderLoginPageLogo from '../login/RenderLoginPageLogo';
/* import { setPasswordResetTime } from '../../store/reducers/userSlice'; */

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authToken = store.getState().auth.authorization.accessToken;
  const [email, setEmail] = useState('');
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);
  const isDisabled = email === '' ? true : false;

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    try {
      const requestInfo: RequestInfo = {
        url: FORGOT_PASSWORD_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          email: email,
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'FORGOT_PASSWORD';
      const customMsg = t('ForgotPasswordSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        //current date
        /*  const currentDate = new Date();
        store.dispatch(setPasswordResetTime(currentDate)); */
      }
    } catch (exception) {
      console.log('Error during authentication request');
    }
  }

  if (!authToken.sellerToken && !authToken.adminToken) {
    return (
      <motion.div {...pageSlideOutForward} className='signup'>
        <form onSubmit={onSubmitHandler} className='signup__left'>
          <div className='signup__logo'>
            <RenderLoginPageLogo
              staticURL={staticURL}
              merchantTheme={merchantTheme}
              isDarkMode={isDarkMode}
            />
          </div>
          <div className='signup__left--top'>
            <h1>{t('ForgotPasswordMainText')}</h1>
            <p>{t('ForgotPasswordSubText')}</p>
          </div>
          <div className='signup__left--center'>
            <LoginInput
              icon={<MessageSVG fill='#cecece' />}
              id='forgot-password-form-email'
              value={email}
              type='email'
              changeListeners={[(e) => setEmail(e.target.value)]}
              focusListeners={[]}
              placeholder={t('EmailAddress')}
              required={true}
              pattern='^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$'
              title={t('Email-validation')}
            />
          </div>
          <div className='signup__left--bottom'>
            <MainSquareButton
              value={t('Continue')}
              type='submit'
              disabled={isDisabled}
              classes='mainSquareButton-extra-1'
            />
            <h2>
              {t('Or')}{' '}
              <span onClick={() => navigate('/login')}>{t('SignIn')} </span>
              {t('YourAccount')}
            </h2>
          </div>
        </form>

        <div className='signup__right'>
          {/* <img src={bg} alt='signin-background' /> */}
          <div className='signup__right-lottie'>
            <Lottie animationData={lottieBanner} loop={true} />
          </div>
        </div>
      </motion.div>
    );
  } else {
    window.location.assign('/');
    return null;
  }
};

export default ForgotPassword;
