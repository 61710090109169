import React from 'react';
import { useSelector } from 'react-redux';
import OnboardingWrapper from '../../components/page-wrappers/OnboardingWrapper';
import { OnboardingStagesMap } from '../../store/reducers/userSlice';
import store, { RootState } from '../../store/store';
import EnterCMSConnectionDetails from './EnterCMSConnectionDetails';
import EnterCompanyDetails from './EnterCompanyDetails';
import EnterPSPConnectionDetails from './EnterPSPConnectionDetails';
import ProfileUpdate from './ProfileUpdate';
import SetShopCMS1 from './SetShopCMS1';
import SetShopPSPs1 from './SetShopPSPs1';

const Onboarding: React.FC = () => {
  let token = window.location?.search.split('?')[1];
  const onboardingState = useSelector(
    (state: RootState) => state.user.onboarding
  );
  const isOnboardingCompleted =
    store.getState().user.onboarding.onboarding_completed;

  if (!isOnboardingCompleted) {
    return (
      <OnboardingWrapper>
        {/* {(onboardingState.currentStage === '' ||
        onboardingState.currentStage === OnboardingStagesMap.SigningUp.name) && (
        <SignUpPage key={1} />
      )}

      {onboardingState.currentStage === OnboardingStagesMap.SigningIn.name && (
        <LoginPage key={2} />
      )} */}

        {onboardingState.currentStage === '' && token && (
          <ProfileUpdate key={3} />
        )}

        {onboardingState.currentStage ===
          OnboardingStagesMap.ProfileUpdate.name && <ProfileUpdate key={3} />}

        {onboardingState.currentStage ===
          OnboardingStagesMap.SubmittingCompany.name && (
          <EnterCompanyDetails key={4} />
        )}

        {/* {onboardingState.currentStage === OnboardingStagesMap.AddingWebShop1.name && (
        <AddWebUrl1 key={4} />
      )} */}

        {onboardingState.currentStage ===
          OnboardingStagesMap.IntegratingCMS1.name && <SetShopCMS1 key={5} />}

        {onboardingState.currentStage ===
          OnboardingStagesMap.SettingCMSConnection.name && (
          <EnterCMSConnectionDetails key={6} />
        )}

        {onboardingState.currentStage ===
          OnboardingStagesMap.IntegratingPSP1.name && <SetShopPSPs1 key={7} />}

        {onboardingState.currentStage ===
          OnboardingStagesMap.SettingPSPConnection.name && (
          <EnterPSPConnectionDetails key={8} />
        )}
      </OnboardingWrapper>
    );
  } else {
    window.location.assign('/');
    return null;
  }
};

export default Onboarding;
