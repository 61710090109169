const getPaymentMethods = (availablePSP: any) => {
  let psp_payment_methods: any = {};

  for (let i = 0; i < availablePSP.length; i++) {
    const currentPSP = Object.keys(availablePSP[i]).toString().toLowerCase();
    const a: any = Object.values(availablePSP[i])[0];
    const currentPSP_PaymentMethods: any = a.payment_methods;

    psp_payment_methods[currentPSP] = currentPSP_PaymentMethods;
  }
  /* console.log(psp_payment_methods); */
  return psp_payment_methods;
};

export default getPaymentMethods;
