import '../../styles/components/subPageContentHolder/subPageContentHolder.scss';

/* interface Props {
  title: string;
  subTitle: string;
} */

const SubPageContentHolder: React.FC = ({ children }) => {
  return <div className='subPageContentHolder'>{children}</div>;
};

export default SubPageContentHolder;
