import React from 'react';
import { useNavigate } from 'react-router-dom';
import { arrowRightThinSVG, filterSVG } from '../../lib/allCustomSVGs';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import SquareGrayButton from '../../ui/buttons/SquareGrayButton';
import '../../styles/components/subPageContentHolder/subPageInnerContentHolder.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  subTitle?: string;
  backBtnLink?: string;
  children: React.ReactNode;
  mainBtnIcon?: any;
  mainBtnTitle?: string;
  mainBtnLink?: string;
  hasFilter?: boolean;
  filterHandler?: () => void;
  isFilterActive?: boolean;
}

const SubPageInnerContentHolder = ({
  children,
  title,
  subTitle,
  backBtnLink,
  mainBtnIcon,
  mainBtnTitle,
  mainBtnLink,
  hasFilter,
  isFilterActive,
  filterHandler,
}: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className='subPageInnerContentHolder'>
      <div className='subPageInnerContentHolder__content'>
        <div className='subPageInnerContentHolder__content--top'>
          <div className='subPageInnerContentHolder__content--top-right'>
            <div className='subPageInnerContentHolder__content--top-right-button'>
              {backBtnLink && (
                <SquareGrayButton
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`${backBtnLink}`);
                  }}
                  type={'button'}
                  icon={arrowRightThinSVG}
                  normal={true}
                  classes='rotate-180-Deg squareGrayButton-mobile-view'
                />
              )}
            </div>
            <div className='subPageInnerContentHolder__content--top-right-title'>
              <h3>{title}</h3>
              {subTitle && <span>{subTitle}</span>}
            </div>
          </div>

          <div className='subPageInnerContentHolder__content--top-buttons'>
            <div className='subPageInnerContentHolder__content--top-buttons-backBtn'>
              {backBtnLink && (
                <SquareGrayButton
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(`${backBtnLink}`);
                  }}
                  type={'button'}
                  value={t('GoBack')}
                  icon={arrowRightThinSVG}
                  normal={true}
                  classes='rotate-180-Deg'
                />
              )}
            </div>

            {mainBtnTitle && (
              <MainSquareButton
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`${mainBtnLink}`);
                }}
                type={'button'}
                value={`${mainBtnTitle}`}
                icon={mainBtnIcon}
                normal={true}
              />
            )}
            {hasFilter && (
              <MainSquareButton
                onClick={(e) => {
                  e.preventDefault();
                  if (filterHandler) {
                    filterHandler();
                  }
                }}
                type={'button'}
                value={t('Filter')}
                icon={filterSVG}
                normal={true}
                buttonType={isFilterActive ? 'filter' : 'light'}
              />
            )}
          </div>
        </div>
        {/* <div className='line'>
          <div />
        </div> */}
        <div className='subPageInnerContentHolder__content--bottom'>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SubPageInnerContentHolder;
