import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CMS } from '../../interfaces/CMS';
import { PSP } from '../../interfaces/PSP';
import { UserState } from '../../interfaces/states/UserState';
import { WebShop } from '../../interfaces/WebShop';

interface OnboardingStagesMapInterface {
  name: string;
  displayTitle: string;
  followedBy: string[];
  stepNumber: number;
}

interface CompanyDetails {
  name: string;
  shop_id: string;
  currency: string;
  shop_slug: string;
}

const defaultCompanySettings = {
  theme_color: '',
  font_color: '',
  background_image: '',
  company_logo: '',
  company_icon: '',
  facebook_pixel_id: '',
  tiktok_pixel_id: '',
  google_analytics_id: '',
  default_shipping_cost: '',
  min_free_shipping: '',
  fixed_shipping: '',
  send_monthly_newsletter: '',
  background_color: '',
  fascheckout_ui: '',
  facebook_access_token: '',
  tiktok_test_event_code: '',
  webshop_url: '',
  tiktok_access_token: '',
  setting: '',
  shop_name: '',
  shop_slug: '',
  shop_initials: '',
  currency: '',
};

export const OnboardingStagesMap: {
  [index: string]: OnboardingStagesMapInterface;
} = Object.freeze({
  SigningUp: {
    name: 'SigningUp',
    displayTitle: 'Register',
    followedBy: [
      'ProfileUpdate',
      'SubmittingCompany',
      'IntegratingCMS1',
      'SettingCMSConnection',
      'IntegratingPSP1',
      'SettingPSPConnection',
    ],
    stepNumber: 1,
  },
  SigningIn: {
    name: 'SigningIn',
    displayTitle: 'Sign In',
    followedBy: [
      'ProfileUpdate',
      'SubmittingCompany',
      'IntegratingCMS1',
      'SettingCMSConnection',
      'IntegratingPSP1',
      'SettingPSPConnection',
    ],
    stepNumber: 2,
  },
  ProfileUpdate: {
    name: 'ProfileUpdate',
    displayTitle: 'Update your profile',
    followedBy: [
      'SubmittingCompany',
      'IntegratingCMS1',
      'SettingCMSConnection',
      'IntegratingPSP1',
      'SettingPSPConnection',
    ],
    stepNumber: 3,
  },
  SubmittingCompany: {
    name: 'SubmittingCompany',
    displayTitle: 'Setup your Company Details',
    followedBy: [
      'IntegratingCMS1',
      'SettingCMSConnection',
      'IntegratingPSP1',
      'SettingPSPConnection',
    ],
    stepNumber: 4,
  },
  IntegratingCMS1: {
    name: 'IntegratingCMS1',
    displayTitle: 'Integrating your CMS',
    followedBy: [
      'SettingCMSConnection',
      'IntegratingPSP1',
      'SettingPSPConnection',
    ],
    stepNumber: 5,
  },
  SettingCMSConnection: {
    name: 'SettingCMSConnection',
    displayTitle: 'Setting up your CMS Connection',
    followedBy: ['IntegratingPSP1', 'SettingPSPConnection'],
    stepNumber: 6,
  },
  IntegratingPSP1: {
    name: 'IntegratingPSP1',
    displayTitle: 'Selecting Payment Providers',
    followedBy: ['SettingPSPConnection'],
    stepNumber: 7,
  },
  SettingPSPConnection: {
    name: 'SettingPSPConnection',
    displayTitle: 'Setting up your PSP Connection',
    followedBy: [],
    stepNumber: 8,
  },
});

const makeInitialStagesCompleted = () => {
  const result: { [key: string]: boolean } = {};

  Object.keys(OnboardingStagesMap).forEach((key) => (result[key] = false));

  return result;
};

const initialStagesCompleted: { [key: string]: boolean } = Object.freeze(
  makeInitialStagesCompleted()
);

const initialState: UserState = {
  company: {
    name: '',
    shop_id: '',
    currency: '',
    webshops: [],
    cms: [],
    psp: [],
    cmsSelected: '',
    pspSelected: '',
    preSelectedCMS_PSP: { preSelectedCMS: '', preSelectedPSP: '' },
    shop_slug: '',
    alreadyAddedPSP: [],
    settings: defaultCompanySettings,
  },

  shop: {
    isProductWizardOpen: false,
    shouldShowProductWizard: false,
    hasInitPSP: false,
  },

  profile: {
    seller_id: '',
    is_merchant: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    country_code: '',
    phone: '',
    email: '',
    country: '',
    language: '',
    webshop: '',
  },

  merchant: {
    id: '',
    merchant_id: '',
    merchant_name: '',
    fastcheckout_domain: '',
    dashboard_domain: '',
    status: '',
    views: [],
    config: {
      main_brand_color: '',
      secondary_brand_color: '',
      main_brand_hover_color: '',
      header_gradient_color_1: '',
      header_gradient_color_2: '',
      main_btn_color: '',
      main_btn_hover_color: '',
      main_btn_text_color: '',
      main_text_color: '',
      merchant_name: '',
      monthly_newsletter_text: '',
      signup_text1: '',
      merchant_favicon: '',
      merchant_icon: '',
      merchant_logo_light: '',
      merchant_logo_dark: '',
      terms_url: '',
      privacy_url: '',
      faq_url: '',
      how_fastcheckout_works_url: '',
      login_image: '',
      signup_image: '',
    },
  },

  onboarding: {
    onboarding_object: { available_psp: [], available_cms: [] },
    stagesCompleted: initialStagesCompleted,
    currentStage: '',
    availableCMS: [],
    availablePSP: [],
    paymentMethods: {},
    onboarding_completed: false,
  },
  autofillInregrations: null,
  pspConnections: null,
  intercom: {
    app_id: 'ixig6rth',
    isFirstBootComplete: false,
    cms: 'null',
    cmsdatafilled: 'false',
    psp: 'null',
    pspdatafilled: 'false',
  },
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserCompanyDetails: (state, action: PayloadAction<CompanyDetails>) => {
      state.company.name = action.payload.name;
      state.company.shop_id = action.payload.shop_id;
      state.company.currency = action.payload.currency;
      state.company.shop_slug = action.payload.shop_slug;
    },
    setUserCompanySettings: (state, action: PayloadAction<any>) => {
      state.company.settings = { ...state.company.settings, ...action.payload };
      if (action.payload.shop_name)
        state.company.name = action.payload.shop_name;
      if (action.payload.currency)
        state.company.currency = action.payload.currency;
      if (action.payload.shop_slug)
        state.company.shop_slug = action.payload.shop_slug;
    },
    updateProfile: (state, action: PayloadAction<any>) => {
      const {
        country,
        first_name,
        last_name,
        country_code,
        phone,
        email,
        webshop,
        language,
        seller_id,
      } = action.payload;

      if (seller_id) state.profile.seller_id = seller_id;
      if (country) state.profile.country = country;
      if (first_name) state.profile.first_name = first_name;
      if (last_name) state.profile.last_name = last_name;
      if (country_code) state.profile.country_code = country_code;
      if (phone) state.profile.phone = phone;
      if (email) state.profile.email = email;
      if (language) state.profile.language = language;
      if (webshop) state.profile.webshop = webshop;
    },

    updateMerchant: (state, action: PayloadAction<any>) => {
      const {
        id,
        merchant_id,
        merchant_name,
        fastcheckout_domain,
        dashboard_domain,
        status,
        config,
        views,
      } = action.payload;

      if (id) state.merchant.id = id;
      if (merchant_id) state.merchant.merchant_id = merchant_id;
      if (merchant_name) state.merchant.merchant_name = merchant_name;
      if (fastcheckout_domain)
        state.merchant.fastcheckout_domain = fastcheckout_domain;
      if (dashboard_domain) state.merchant.dashboard_domain = dashboard_domain;
      if (status) state.merchant.status = status;
      if (views) state.merchant.views = views;
      if (config) state.merchant.config = config;
    },

    updateIntercom: (state, action: PayloadAction<any>) => {
      const { isFirstBootComplete, cms, cmsdatafilled, psp, pspdatafilled } =
        action.payload;
      if (isFirstBootComplete)
        state.intercom.isFirstBootComplete = isFirstBootComplete;
      if (cms) state.intercom.cms = cms;
      if (cmsdatafilled) state.intercom.cmsdatafilled = cmsdatafilled;
      if (psp) state.intercom.psp = psp;
      if (pspdatafilled) state.intercom.pspdatafilled = pspdatafilled;
    },

    addWebshop: (state, action: PayloadAction<WebShop>) => {
      state.company.webshops = state.company.webshops.concat(action.payload);
    },

    updateOnboardingStage: (state, action: PayloadAction<string>) => {
      if (action.payload in OnboardingStagesMap) {
        state.onboarding.currentStage = action.payload;

        // if we go back, erase the data for the stages before
        const followingStages = OnboardingStagesMap[action.payload].followedBy;

        for (const followingStage of followingStages) {
          state.onboarding.stagesCompleted[followingStage] = false;
        }
      }
    },

    getOnboardingObject: (state, action: PayloadAction<any>) => {
      state.onboarding.onboarding_object = action.payload;

      const reuslt = action.payload;

      const psp = reuslt.available_psp?.map((el: object, i: number) => {
        return Object.keys(el)[0].toLowerCase();
      });
      const cms = reuslt.available_cms?.map((el: object, i: number) => {
        return Object.keys(el)[0].toLowerCase();
      });

      state.onboarding.availablePSP = psp;
      state.onboarding.availableCMS = cms;
    },

    setAvailableCMS: (state, action: PayloadAction<any>) => {
      const result = action.payload;
      const cms = result?.map((el: object, i: number) => {
        return Object.keys(el)[0].toLowerCase();
      });

      state.onboarding.onboarding_object.available_cms = result;
      state.onboarding.availableCMS = cms;
    },

    setAvailablePSP: (state, action: PayloadAction<any>) => {
      const result = action.payload;
      const psp = result?.map((el: object, i: number) => {
        return Object.keys(el)[0].toLowerCase();
      });

      state.onboarding.onboarding_object.available_psp = result;
      state.onboarding.availablePSP = psp;
    },

    // setAvailableCMS: (state, action: PayloadAction<CMS[]>) => {
    //   state.onboarding.availableCMS = action.payload;
    // },

    // setAvailablePSP: (state, action: PayloadAction<PSP[]>) => {
    //   state.onboarding.availablePSP = action.payload;
    // },

    addCMS: (state, action: PayloadAction<CMS>) => {
      state.company.cms = state.company.cms.concat(action.payload);
    },
    addSelectedCMS: (state, action: PayloadAction<string>) => {
      state.company.cmsSelected = action.payload;
    },

    addPSP: (state, action: PayloadAction<PSP>) => {
      state.company.psp = state.company.psp.concat(action.payload);
    },

    addSelectedPSP: (state, action: PayloadAction<string>) => {
      state.company.pspSelected = action.payload;
    },
    setIsAdmin: (state, action: PayloadAction<boolean>) => {
      state.profile.is_admin = action.payload;
    },
    setIsMerchant: (state, action: PayloadAction<boolean>) => {
      state.profile.is_merchant = action.payload;
    },

    removePSP: (state, action: PayloadAction<{ id: string }>) => {
      state.company.psp = state.company.psp.filter(
        (provider) => provider.id !== action.payload.id
      );
    },

    setStageComplete: (
      state,
      action: PayloadAction<{ stage: string; isCompleted: boolean }>
    ) => {
      if (action.payload.stage in OnboardingStagesMap) {
        state.onboarding.stagesCompleted[action.payload.stage] =
          action.payload.isCompleted;
      }
    },

    setPreSelectedCMS_PSP: (
      state,
      action: PayloadAction<{ type: 'cms' | 'psp'; name: string }>
    ) => {
      if (action.payload.type === 'cms') {
        state.company.preSelectedCMS_PSP.preSelectedCMS = action.payload.name;
      } else if (action.payload.type === 'psp') {
        state.company.preSelectedCMS_PSP.preSelectedPSP = action.payload.name;
      }
    },

    completeOnboarding: (state, action: PayloadAction<boolean>) => {
      state.onboarding.onboarding_completed = action.payload;
    },

    setPaymentMethods: (
      state,
      action: PayloadAction<{
        [key: string]: string[];
      }>
    ) => {
      state.onboarding.paymentMethods = action.payload;
    },

    setAutofillInregrations: (state, action: PayloadAction<any>) => {
      state.autofillInregrations = action.payload;
    },
    setPSPConnections: (state, action: PayloadAction<any>) => {
      state.pspConnections = action.payload;
    },
    setAlreadyAddedPSP: (state, action: PayloadAction<any>) => {
      state.company.alreadyAddedPSP = action.payload;
    },

    setIsProductWizardOpen: (state, action: PayloadAction<any>) => {
      state.shop.isProductWizardOpen = action.payload;
    },

    setShouldShowProductWizard: (state, action: PayloadAction<any>) => {
      state.shop.shouldShowProductWizard = action.payload;
    },
    setHasInitPSP: (state, action: PayloadAction<boolean>) => {
      state.shop.hasInitPSP = action.payload;
    },

    clearUserSlice: (state) => {
      state.company = initialState.company;
      state.shop = initialState.shop;
      state.onboarding = initialState.onboarding;
      state.profile = initialState.profile;
      // state.merchant = initialState.merchant;
      state.intercom = initialState.intercom;
      state.autofillInregrations = initialState.autofillInregrations;
      state.pspConnections = initialState.pspConnections;
    },
  },
});

export const {
  updateProfile,
  addWebshop,
  updateOnboardingStage,
  addCMS,
  addPSP,
  removePSP,
  setStageComplete,
  setAvailableCMS,
  setAvailablePSP,
  setUserCompanyDetails,
  setUserCompanySettings,
  completeOnboarding,
  getOnboardingObject,
  setPaymentMethods,
  setAutofillInregrations,
  setPSPConnections,
  addSelectedCMS,
  addSelectedPSP,
  updateIntercom,
  clearUserSlice,
  setAlreadyAddedPSP,
  setIsProductWizardOpen,
  setShouldShowProductWizard,
  setPreSelectedCMS_PSP,
  setHasInitPSP,
  setIsAdmin,
  setIsMerchant,
  updateMerchant,
} = userSlice.actions;
export const userStateReducer = userSlice.reducer;
