import React, { useState, useEffect, useRef } from 'react';
import '../../styles/inputs/textAreaAI.scss';
import { CHAT_GPT_ENDPOINT } from '../../lib/constants/urls';
import { asyncHandler } from '../../lib/api/async-handler';
import { RequestInfo } from '../../interfaces/RequestInfo';
import store from '../../store/store';

type Props = {
  id: string;
  classes?: string;
  required?: boolean;
  placeholder?: string;
  readonly?: boolean;
  title?: string;
  autoFocus?: boolean;
  labelClasses?: string;
  textForAI?: string;
  productDescription: string;
  setProductDescription: (description: string) => void;
};

const TextAreaAI = React.forwardRef<HTMLTextAreaElement, Props>(
  (
    {
      id,
      classes = '',
      placeholder = '',
      readonly,
      required,
      title,
      autoFocus,
      labelClasses,
      textForAI,
      productDescription,
      setProductDescription,
    }: Props,
    ref
  ) => {
    const [innerValue, setInnerValue] = useState<string>(productDescription);
    const [focused, setFocused] = useState<boolean>(false);
    const [onlyOnFocus, setOnlyOnFocus] = useState<boolean>(false);
    const [isAITextState, setIsAITextState] = useState<string>('initial');
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const accessToken = store.getState().auth.authorization.accessToken;
    const isWhiteLabel = store.getState().UI?.isWhiteLabel;
    let sellerToken: string;
    if (isWhiteLabel) {
      sellerToken = accessToken?.currentWhiteLabelsellerToken;
    } else {
      sellerToken = accessToken?.sellerToken;
    }

    useEffect(() => {
      return () => {
        // Clear the timeout when the component unmounts
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
        }
      };
    }, []);

    useEffect(() => {
      setInnerValue(productDescription);
    }, [productDescription]);

    function handleChangeValue(event: React.ChangeEvent<HTMLTextAreaElement>) {
      setInnerValue(event.target.value);
      setProductDescription(event.target.value);
    }

    const handleFocusElement = (
      event: React.FocusEvent<HTMLTextAreaElement>
    ) => {
      setOnlyOnFocus(true);
    };

    const handleBlur = (event: React.FocusEvent<HTMLTextAreaElement>) => {
      setFocused(true);
      setOnlyOnFocus(false);
    };

    const onAIBtnClick = async () => {
      setIsAITextState('loading');
      const prompt = `Im giving you a name of a product which is "${textForAI}", give a description for this product which is suitable for a product description in a e-commerce website which gives a good overview for the user about this product and add detailed facts if needed, And make sure that the description is always less than 100 characters. if you cannot find a meaningful description for the given product name then your response should be "null"`;

      try {
        const requestInfo: RequestInfo = {
          url: `${CHAT_GPT_ENDPOINT}?question=${prompt}`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            Authorization: accessToken.type + ' ' + sellerToken,
          },
        };

        const actionType = 'GET_CHAT_GPT_ANSWER';
        const customMsg = 'AI Text Fetched Success';

        const res: any = await asyncHandler(
          requestInfo,
          actionType,
          customMsg,
          false
        );

        const response = res.data;

        if (res.actionType === actionType && response.success) {
          const description = response?.messages?.Answer;

          if (description === 'null') {
            setIsAITextState('null');
            setProductDescription(''); // Update the productDescription state
            timeoutRef.current = setTimeout(() => {
              setIsAITextState('initial');
            }, 5000);
          } else if (description) {
            setIsAITextState('initial');
            setProductDescription(description); // Update the productDescription state
          }
        } else {
          setIsAITextState('error');
          timeoutRef.current = setTimeout(() => {
            setIsAITextState('initial');
          }, 5000);
        }
      } catch (exception) {
        console.log('Error during authentication request');
        setIsAITextState('error');
        timeoutRef.current = setTimeout(() => {
          setIsAITextState('initial');
        }, 5000);
      }
    };

    const renderAIBtnText = () => {
      if (isAITextState === 'initial') {
        return 'Generate description with AI';
      }
      if (isAITextState === 'loading') {
        return 'Generating...';
      }
      if (isAITextState === 'null') {
        return 'Cannot find a description. Enter a meaningful product name';
      }
      if (isAITextState === 'error') {
        return 'Something went wrong';
      }
    };

    return (
      <div className='textAreaAI-outer'>
        <div className='textAreaAI'>
          <textarea
            ref={ref}
            id={id}
            className={classes}
            value={innerValue}
            onChange={handleChangeValue}
            onFocus={handleFocusElement}
            readOnly={readonly}
            onBlur={handleBlur}
            data-focused={focused.toString()}
            title={title}
            autoFocus={autoFocus}
          />
          <div className='textAreaAI__labelContainer'>
            <label className={labelClasses} htmlFor={id}>
              {placeholder}
            </label>
            {textForAI ? (
              <div
                className={
                  isAITextState === 'error'
                    ? 'textAreaAI-error'
                    : isAITextState === 'null'
                    ? 'textAreaAI-warning'
                    : ''
                }
                onClick={onAIBtnClick}
              >
                {renderAIBtnText()}
              </div>
            ) : null}
          </div>
        </div>
        <div className='textAreaAI-validation-text'>{onlyOnFocus && title}</div>
      </div>
    );
  }
);

export default TextAreaAI;
