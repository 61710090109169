import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { onFormSubmitedFailed } from '../../components/generic/common-toasts';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
// import { sendSelectedPSP } from '../../lib/api/post-onboarding-data';
import {
  completeOnboarding,
  OnboardingStagesMap,
  setStageComplete,
  updateIntercom,
  updateOnboardingStage,
} from '../../store/reducers/userSlice';
import { RootState } from '../../store/store';
import './onboarding-page.css';
import '../../styles/components/onboarding/enterCMSConnectionDetails.scss';
/* import { arrowRightThickSVG, skipArrowSVG } from '../../lib/allCustomSVGs';
import MainSquareButton from '../../ui/buttons/MainSquareButton'; */
import NormalInput from '../../ui/inputs/NormalInput';
import { useNavigate } from 'react-router-dom';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import { ONBOARDING_ENDPOINT } from '../../lib/constants/urls';
import { useDispatch } from 'react-redux';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import store from '../../store/store';
import MainPurpleButton from '../../ui/buttons/MainPurpleButton';
// import ShowInfoBlock from '../../components/showInfoBlock/ShowInfoBlock';
import { submitCustomOption } from '../../lib/api/psp-cms-otherOptions-call';
import { useTranslation } from 'react-i18next';
import { submitOnboardingStatus } from '../../lib/api/post-onboarding-status';
import { setIsOnboardingCompleteBannerOpen } from '../../store/reducers/uiSlice';
import makeFirstLetterCapital from '../../lib/helper-functions/makeFirstLetterCapital';
import { setRunInitOnFirstLoad } from '../../store/reducers/appSlice';
import hostedMollieButton from '../../assets/HostedMollieButton.png';

const EnterPSPConnectionDetails = () => {
  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const { t } = useTranslation();

  const [pspInputs, setPSPInputs] = useState([]);
  const [pspInputValues, setPSPInputValues] = useState<any>();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const selectedPSPArr = useSelector(
    (state: RootState) => state.user.company.psp
  );
  const selectedPSP = selectedPSPArr[0]?.toString();
  const paymentMethods = useSelector(
    (state: RootState) => state.user.onboarding.paymentMethods
  );
  const shopId = useSelector((state: RootState) => state.user.company.shop_id);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const mollieOauthRedirectURL = useSelector(
    (state: RootState) => state.auth?.authorization?.mollieOauthRedirectURL
  );

  /* ------other option state ----------*/
  const optionType = 'psp';
  const [customOption, setCustomOption] = useState('');
  const pspName = 'add_psp';

  /* ================================================================================= */
  useEffect(() => {
    const currentPSP = onboarding_object?.available_psp?.filter((el: any) => {
      const currentPSP_Obj: any = Object.values(el)[0];
      const currentPSP_Obj_label = currentPSP_Obj?.label?.toLowerCase();
      return currentPSP_Obj_label === selectedPSP ? currentPSP_Obj : null;
    });

    if (currentPSP?.length > 0) {
      const temp: any = Object.values(currentPSP)[0];
      const found_PSP: any = Object.values(temp)[0];
      const currentPSP_inputs = found_PSP?.connection_method?.manual;
      if (currentPSP_inputs?.length > 0) {
        let temp_inputs: any = [];
        let temp_input_values: any = {};
        for (let i = 0; i < currentPSP_inputs[0].length; i++) {
          const temp = currentPSP_inputs[0][i].label;
          temp_inputs.push(temp);
          temp_input_values[temp] = '';
        }

        setPSPInputs(temp_inputs);
        setPSPInputValues(temp_input_values);
      }
    }
  }, [selectedPSP]);
  /* ================================================================================= */

  async function onContinue(e: any) {
    e.preventDefault();
    if (selectedPSP === 'other') {
      const res: any = await submitCustomOption('psp', customOption);
      if (res) {
        const statusRes = await submitOnboardingStatus('psp', customOption);
        if (statusRes) {
          dispatch(setIsOnboardingCompleteBannerOpen(true));
          const statusRes2 = await submitOnboardingStatus(
            'onboarding_completed',
            'true'
          );
          if (statusRes2) {
            dispatch(
              updateIntercom({
                psp: customOption,
              })
            );
            dispatch(
              setStageComplete({
                stage: OnboardingStagesMap.SettingPSPConnection.name,
                isCompleted: true,
              })
            );
            dispatch(completeOnboarding(true));
            navigate('/shop');
          } else {
            dispatch(setIsOnboardingCompleteBannerOpen(false));
          }
        }
      }
    } else {
      try {
        const accessToken = store.getState().auth.authorization.accessToken;

        const sendingData = {
          onboard_type: pspName,
          psp_module: selectedPSP,
          shop_id: shopId,
          payment_details: pspInputValues,
        };

        const requestInfo: RequestInfo = {
          url: ONBOARDING_ENDPOINT,
          method: 'POST',
          body: applicationWWWXRLEncodedBodyBuilder({
            onboarding_data: JSON.stringify(sendingData),
          }),
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/x-www-form-urlencoded',
            Authorization: accessToken.type + ' ' + accessToken.sellerToken,
          },
        };

        const actionType = 'ONBOARDING_PSP_CONNECTION';
        const customMsg = 'PSP Connection Successful.';

        const res: any = await asyncHandler(requestInfo, actionType, customMsg);

        const response = res.data;

        if (res.actionType === actionType && response.success === true) {
          const statusRes = await submitOnboardingStatus(
            'onboarding_completed',
            'true'
          );

          if (statusRes) {
            dispatch(
              updateIntercom({
                pspdatafilled: 'true',
              })
            );
            dispatch(
              setStageComplete({
                stage: OnboardingStagesMap.SettingPSPConnection.name,
                isCompleted: true,
              })
            );
            dispatch(completeOnboarding(true));
            // navigate('/dashboard');
            navigate('/shop');
          }
        }
      } catch (exception) {
        console.log('Error during authentication request');
      }
    }
  }

  async function skip() {
    // navigate('/dashboard');
    const statusRes = await submitOnboardingStatus(
      'onboarding_completed',
      'true'
    );
    if (statusRes) {
      dispatch(setRunInitOnFirstLoad(true));
      navigate('/shop');
      dispatch(
        setStageComplete({
          stage: OnboardingStagesMap.SettingPSPConnection.name,
          isCompleted: true,
        })
      );
    }
  }

  function goBack() {
    dispatch(updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name));
    dispatch(
      setStageComplete({
        stage: OnboardingStagesMap.IntegratingPSP1.name,
        isCompleted: false,
      })
    );
  }

  let isDisabled;

  if (pspInputValues) {
    isDisabled = Object.values(pspInputValues)?.some((el: any) => el === '');
  }

  const onInputChange = (e: any, value: string) => {
    setPSPInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  return (
    <motion.div
      {...pageSlideOutForward}
      className='enterCMSConnectionDetails  animate-fade_in_from_right'
    >
      <div className='enterCMSConnectionDetails__container '>
        <div className='enterCMSConnectionDetails__container--top'>
          <h1>
            {selectedPSP === 'other'
              ? t('OnboardingCustomPSP-H1')
              : t('OnboardingPSP-ConnectionH1')}
          </h1>
          <h2 className={`onboarding-psp-${selectedPSP}-connection-h2`}>
            {selectedPSP === 'other'
              ? t('OnboardingCustomPSP-H2')
              : t('OnboardingPSP-ConnectionH2')}
          </h2>
        </div>

        <form
          onSubmit={onContinue}
          id='onboarding-PSPConnection'
          className='enterCMSConnectionDetails__container--center'
        >
          {selectedPSP === 'other' && (
            <>
              {/* ---------------- */}

              <NormalInput
                id={`other-option-${optionType}`}
                value={customOption}
                changeListeners={[(e) => setCustomOption(e.target.value)]}
                focusListeners={[]}
                placeholder={t('CustomPSP-Option')}
                required={true}
                autoFocus={true}
                /* pattern='^[A-Za-z ]{2,30}$' */
                /* title='Name cannot exceed 30 characters' */
              />

              {/* ---------------- */}
            </>
          )}
          {selectedPSP !== 'other' && (
            <>
              {pspInputs.map((el: string, index: number) => {
                return (
                  <div key={index}>
                    <NormalInput
                      changeListeners={[(e) => onInputChange(e, el)]}
                      id={`psp-integration-${el}`}
                      value={pspInputValues ? pspInputValues[el] : ''}
                      placeholder={makeFirstLetterCapital(
                        el.replaceAll('_', ' ')
                      )}
                      required={true}
                      autoFocus={index === 0 ? true : false}
                      classes={`onboarding-psp-${selectedPSP}-${el}-input`}
                      labelClasses={`onboarding-psp-${selectedPSP}-${el}-label`}
                    />
                  </div>
                );
              })}
            </>
          )}
        </form>
        {selectedPSP === 'mollie' && (
          // Mollie oAuth
          <>
            <p>{t('MollieOAuthText1')} : </p>
            <button
              style={{ width: '160px' }}
              onClick={() => {
                navigate('/shop');
                window.open(`${mollieOauthRedirectURL}`, '_blank');
              }}
            >
              <a rel='mollie.com'>
                <img src={hostedMollieButton} alt='Connect via Mollie' />
              </a>
            </button>
          </>
        )}
        <div className='enterCMSConnectionDetails__container--bottom '>
          <MainPurpleButton
            value={t('Continue')}
            disabled={isDisabled}
            type='submit'
            form={'onboarding-PSPConnection'}
          />
        </div>

        <div className='pt-2'>
          <a className='skip-link margin-left-right-1rem' onClick={goBack}>
            &#8592; {t('GoBack')}
          </a>
          <a
            className='skip-link margin-left-right-1rem'
            onClick={async () => {
              await skip();
            }}
          >
            {t('SkipStep')} &#8594;
          </a>
        </div>

        <div className='enterCMSConnectionDetails__container--footer'>
          <a target={'_blank'} href={`${merchantTheme?.terms_url}`}>
            {t('Terms')}
          </a>
          {` & `}
          <a target={'_blank'} href={`${merchantTheme?.privacy_url}`}>
            {t('Privacy')}
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default EnterPSPConnectionDetails;
