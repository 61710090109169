import { AnimatePresence, motion } from 'framer-motion';
import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PSP } from '../../interfaces/PSP';
import {
  addPSP,
  removePSP,
  addSelectedPSP,
} from '../../store/reducers/userSlice';
import { RootState, useAppDispatch } from '../../store/store';
import '../../styles/components/selection/pspSelector.scss';
import { useTranslation } from 'react-i18next';
import makeFirstLetterCapital from '../../lib/helper-functions/makeFirstLetterCapital';
import convertKeysToLowerCase from '../../lib/helper-functions/convertKeysToLowerCase';

interface OptionProps {
  psp: PSP;
  ischecked: boolean;
  index: number;
  showAllPSP: boolean;
}

const PSPOption = ({ psp, ischecked, index, showAllPSP }: OptionProps) => {
  const preSelectedPSP = useSelector(
    (state: RootState) => state.user.company.preSelectedCMS_PSP.preSelectedPSP
  );
  // this should be handled in the page component, (the page) this component should not affect the global state
  const dispatch = useAppDispatch();
  let name: string;
  if (typeof psp === 'string') {
    name = psp;
  } else {
    name = psp?.name;
  }
  const { t } = useTranslation();

  const onboardingObject = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);

  const { available_psp } = onboardingObject;

  const currentOrigin = window.location.origin;

  const updated_available_psp = [
    ...available_psp,
    {
      other: {
        label: 'other',
        icon: `${currentOrigin}/cms-psp-logos/other-option.png`,
      },
    },
    {
      nopsp: {
        label: 'nopsp',
        icon: `${currentOrigin}/cms-psp-logos/do-not-have.png`,
      },
    },
  ];
  const currentPSP: any = convertKeysToLowerCase(updated_available_psp);

  /* const currentValues: any = Object.values(currentPSP)[0];
  const currentIcon = `${staticURL}/small/${currentValues.icon}`;

 */

  const currentPSPObject = currentPSP?.[index];
  const currentValues =
    currentPSPObject?.[Object.keys(currentPSPObject)[0]] ?? {};
  // console.log(currentValues);
  let currentIcon: string;
  if (currentValues.label === 'other' || currentValues.label === 'nopsp') {
    currentIcon = currentValues?.icon;
  } else {
    currentIcon = `${staticURL}/small/${currentValues?.icon}`;
  }

  const renderH1 = () => {
    let headingName;
    if (name === 'nopsp') {
      headingName = t('NoPSP');
    } else if (name === 'other') {
      headingName = t('OtherPSP');
    } else {
      headingName = makeFirstLetterCapital(name);
    }

    return headingName;
  };

  useEffect(() => {
    if (String(psp) === preSelectedPSP) {
      dispatch(removePSP({ id: psp.id }));
      dispatch(addPSP(psp));
    }
  }, []);

  const selectOptionTSX = (
    <div
      onClick={() => {
        dispatch(removePSP({ id: psp.id }));
        dispatch(addPSP(psp));
        /* if (ischecked) dispatch(removePSP({ id: psp.id }));
        else dispatch(addPSP(psp)); */
      }}
      className={'pspSelector__option'}
    >
      <div
        className={
          'pspSelector__option--content border-[#e5e5e5]' +
          (ischecked ? ' border-[rgb(20,184,166)]' : '')
        }
      >
        <div className='pspSelector__option--content-left'>
          <div className='pspSelector__option--content-left-img'>
            <img
              /* src={require(`../../assets/${name}.png`)} //! Always make sure the png name is identical to the API value. */
              src={currentIcon} //! Always make sure the png name is identical to the API value.
              alt={`${name} icon`}
            />
          </div>
          <div className='pspSelector__option--content-left-text'>
            <h1>{renderH1()}</h1>
          </div>
        </div>
      </div>
      {ischecked && (
        <motion.div className='pspSelector__option--check'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='14'
            height='14'
            viewBox='0 0 24 24'
            fill='none'
            stroke='teal'
            strokeWidth='3'
            strokeLinecap='round'
            strokeLinejoin='round'
            className='feather feather-check absolute'
          >
            <polyline points='20 6 9 17 4 12'></polyline>
          </svg>
        </motion.div>
      )}
    </div>
  );
  const renderSelectOption = () => {
    if (showAllPSP) {
      return selectOptionTSX;
    } else {
      if (preSelectedPSP === String(psp)) {
        return selectOptionTSX;
      } else if (!preSelectedPSP) {
        return selectOptionTSX;
      }
    }
  };

  return <>{renderSelectOption()}</>;
};

/* ---------------------------PSPSelector--------------------------- */
type props = {
  setSelectedPSP: (cmsName: string) => void;
  setIsOnePSPAvailable: (isOnePSPAvailable: boolean) => void;
  options: PSP[];
  showAllPSP: boolean;
};

const PSPSelector: React.FC<props> = ({
  options = [],
  setSelectedPSP,
  setIsOnePSPAvailable,
  showAllPSP,
}) => {
  const pspSelectedOptions = useSelector(
    (state: RootState) => state.user.company.psp
  );
  const dispatch = useAppDispatch();

  const updatedOptions = [...options, 'other', 'nopsp'];

  const optionsIdSet = useMemo(
    () => new Set(pspSelectedOptions?.map((option) => option?.id)),
    [pspSelectedOptions]
  );

  useEffect(() => {
    //this happens only is one psp is available in the PSP list
    // console.log(options);
    if (options.length === 1) {
      dispatch(removePSP({ id: options[0].id }));
      dispatch(addPSP(options[0]));
      setSelectedPSP(pspSelectedOptions?.toString());
      dispatch(addSelectedPSP(pspSelectedOptions?.toString()));
      setIsOnePSPAvailable(true);
    }
  }, []);

  useEffect(() => {
    if (pspSelectedOptions.length > 0) {
      setSelectedPSP(pspSelectedOptions?.toString());
      dispatch(addSelectedPSP(pspSelectedOptions?.toString()));
    }
  }, [pspSelectedOptions]);

  return (
    <div className='pspSelector'>
      <div className='multiple-item-selector-inner pspSelector__wrapper'>
        <AnimatePresence>
          <motion.div className='pspSelector__content'>
            {updatedOptions?.map((option, key) => {
              /*  console.log(pspSelectedOptions);
              console.log(option); */
              return (
                <PSPOption
                  // @ts-ignore:next-line
                  psp={option}
                  key={key}
                  index={key}
                  ischecked={pspSelectedOptions[0] === option}
                  showAllPSP={showAllPSP}
                  /* ischecked={optionsIdSet.has(option.id)} */
                />
              );
            })}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default PSPSelector;
