import '../../styles/buttons/mainPurpleButton.scss';

type props = {
  onClick?: (event: React.MouseEvent) => void;
  value: string;
  classes?: string;
  loading?: boolean;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  form?: string;
};

const MainPurpleButton: React.FC<props> = ({
  onClick,
  children,
  value,
  type,
  disabled,
  classes = '',
  form,
}) => {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      form={form}
      className={'main__button--purple' + (' ' + classes)}
    >
      {value}
      {children}
    </button>
  );
};

export default MainPurpleButton;
