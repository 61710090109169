import { Route, Routes } from 'react-router-dom';
import AllSellers from './AllSellers';

const MerchantSellersPage = () => {
  return (
    <Routes>
      <Route path='/*' element={<AllSellers />} />
    </Routes>
  );
};

export default MerchantSellersPage;
