type Props = {
  merchantTheme: any;
  isDarkMode: boolean;
  staticURL: string;
};

const RenderLoginPageLogo = ({
  merchantTheme,
  isDarkMode,
  staticURL,
}: Props) => {
  if (merchantTheme.merchant_logo_light && isDarkMode) {
    return (
      <img
        className='login__right--top-logo'
        src={`${staticURL}/medium/${merchantTheme.merchant_logo_light}`}
        alt='logo'
      />
    );
  } else if (merchantTheme.merchant_logo_dark && !isDarkMode) {
    return (
      <img
        className='login__right--top-logo'
        src={`${staticURL}/medium/${merchantTheme.merchant_logo_dark}`}
        alt='logo'
      />
    );
  } else {
    return <img className='login__right--top-logo' src={''} alt='logo' />;
  }
};

export default RenderLoginPageLogo;
