import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import CMSConnection from './cmsConnection/CMSConnection';
import CMSSync from './cmsSync/CMSSync';
import CMSOptions from './cmsOptions/CMSOptions';
import CMSImport from './cmsImport/CMSImport';

const CMS = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/shop/cms/connection');
  }, []);

  return (
    <Routes>
      <Route path='connection/*' element={<CMSConnection />} />
      <Route path='import/*' element={<CMSImport />} />
      <Route path='sync/*' element={<CMSSync />} />
      <Route path='options/*' element={<CMSOptions />} />
    </Routes>
  );
};

export default CMS;
