import { useEffect, useState } from 'react';
import '../../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../../ui/inputs/NormalInput';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../../lib/utils/body-builder';
import { CMS_ENDPOINT } from '../../../../lib/constants/urls';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../../lib/api/async-handler';
import store from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import NormalSelectInput from '../../../../ui/inputs/NormalSelectInput';
import { useNavigate } from 'react-router-dom';
import { updateIntercom } from '../../../../store/reducers/userSlice';
import { useTranslation } from 'react-i18next';
import makeFirstLetterCapital from '../../../../lib/helper-functions/makeFirstLetterCapital';
import createSelectOptionValueObject from '../../../../lib/helper-functions/createSelectOptionValueObject';

const AddCMS = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const [cmsInputs, setCMSInputs] = useState([]);
  const [cmsInputValues, setCMSInputValues] = useState<any>();

  const shopId = useSelector((state: RootState) => state.user.company.shop_id);
  const options: any = useSelector(
    (state: RootState) => state.user.onboarding.availableCMS
  );

  const cmsOptions: any = createSelectOptionValueObject(options);

  const [selectedCMS, setSelectedCMS] = useState(options[0]);

  /* ================================================================================= */
  useEffect(() => {
    const currentCMS = onboarding_object?.available_cms?.filter((el: any) => {
      const currentCMS_Obj: any = Object.values(el)[0];
      const currentCMS_Obj_label = currentCMS_Obj?.label?.toLowerCase();
      return currentCMS_Obj_label === selectedCMS ? currentCMS_Obj : null;
    });

    if (currentCMS?.length > 0) {
      const temp: any = Object.values(currentCMS)[0];
      const found_CMS: any = Object.values(temp)[0];
      const currentCMS_inputs = found_CMS?.connection_method?.manual;
      if (currentCMS_inputs?.length > 0) {
        let temp_inputs: any = [];
        let temp_input_values: any = {};
        for (let i = 0; i < currentCMS_inputs[0].length; i++) {
          const temp = currentCMS_inputs[0][i].label;
          const formattedTemp = temp?.toLowerCase()?.replaceAll(' ', '_');
          temp_inputs.push(formattedTemp);
          temp_input_values[formattedTemp] = '';
        }

        setCMSInputs(temp_inputs);
        setCMSInputValues(temp_input_values);
      }
    }
  }, [selectedCMS]);
  /* ================================================================================= */

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    try {
      const accessToken = store.getState().auth.authorization.accessToken;
      const isWhiteLabel = store.getState().UI?.isWhiteLabel;
      let sellerToken: string;
      if (isWhiteLabel) {
        sellerToken = accessToken?.currentWhiteLabelsellerToken;
      } else {
        sellerToken = accessToken?.sellerToken;
      }

      const requestInfo: RequestInfo = {
        url: CMS_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          cms_type: selectedCMS,
          connection_data: JSON.stringify(cmsInputValues),
          shop_id: shopId,
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'CMS_CONNECTION';
      const customMsg = t('CMS-ConnectionSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        dispatch(
          updateIntercom({
            cms: selectedCMS,
            cmsdatafilled: 'true',
          })
        );
        navigate('/shop/cms/connection');
      }
    } catch (exception) {
      console.log('Error during authentication request');
    }
  }

  let isDisabled;
  if (cmsInputValues) {
    isDisabled = Object.values(cmsInputValues)?.some((el: any) => el === '');
  }

  const onInputChange = (e: any, value: string) => {
    setCMSInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  return (
    <form id='CMSAdd-form' onSubmit={onSubmitHandler} className='normalForm'>
      <div className='normalForm__top'>
        <div className='normalForm__container3'>
          <div className='normalForm__container3--item'>
            <div className='normalForm__container3--item-content'>
              <NormalSelectInput
                defaultValue={selectedCMS}
                setSelect={setSelectedCMS}
                selectOptionArr={cmsOptions}
                placeholder={t('APIType')}
                id={'selected-cms-add'}
              />
            </div>
          </div>
          {cmsInputs.map((el: string, index: number) => {
            return (
              <div className='normalForm__container3--item' key={index}>
                <div className='normalForm__container3--item-content'>
                  <NormalInput
                    changeListeners={[(e) => onInputChange(e, el)]}
                    id={`add-cms-integration-${el}`}
                    value={cmsInputValues ? cmsInputValues[el] : ''}
                    placeholder={makeFirstLetterCapital(
                      el.replaceAll('_', ' ')
                    )}
                    required={true}
                    autoFocus={index === 0 ? true : false}
                    classes={`add-cms-${selectedCMS}-${el}-input`}
                    labelClasses={`add-cms-${selectedCMS}-${el}-label`}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className='normalForm__bottom'>
        <MainSquareButton
          value={t('Add')}
          /* icon={<CustomSVGs svg={plusSignSVG} />} */
          disabled={isDisabled}
          type='submit'
          form='CMSAdd-form'
        />
      </div>
    </form>
  );
};

export default AddCMS;
