import { useState } from 'react';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import store from '../../../store/store';
import '../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../ui/inputs/NormalInput';
import NormalSelectInput from '../../../ui/inputs/NormalSelectInput';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { WEBHOOK_ENDPOINT } from '../../../lib/constants/urls';
import { asyncHandler } from '../../../lib/api/async-handler';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const methodOptions: any = [
  { OptionValue: 'GET', OptionName: 'GET' },
  { OptionValue: 'POST', OptionName: 'POST' },
  { OptionValue: 'PATCH', OptionName: 'PATCH' },
  { OptionValue: 'DELETE', OptionName: 'DELETE' },
];
const AddWebhook = () => {
  const navigate = useNavigate();
  const [endpoint, setEndpoint] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [method, setMethod] = useState<string>(methodOptions[0]?.OptionValue);
  const { t } = useTranslation();

  const isDisabled =
    endpoint === '' || name === '' || method === '' ? true : false;

  /* ----------------------------API CALL START---------------------------- */
  async function onSubmitHandler(e: any) {
    e.preventDefault();
    try {
      const accessToken = store.getState().auth.authorization.accessToken;
      const isWhiteLabel = store.getState().UI?.isWhiteLabel;
      let sellerToken: string;
      if (isWhiteLabel) {
        sellerToken = accessToken?.currentWhiteLabelsellerToken;
      } else {
        sellerToken = accessToken?.sellerToken;
      }

      const sendingData = {
        name,
        method,
        endpoint,
        status: 1,
      };

      const requestInfo: RequestInfo = {
        url: WEBHOOK_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'ADD_WEBHOOK';
      const customMsg = t('WebhookAddedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        navigate('/settings/webhooks');
      }
    } catch (exception) {
      console.log('Error during authentication request');
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  return (
    <form
      id='webhookAdd-form'
      onSubmit={onSubmitHandler}
      className='normalForm'
    >
      <div className='normalForm__top'>
        {/* <h1>Add the Following Information.</h1> */}

        <NormalInput
          changeListeners={[(e) => setName(e.target.value)]}
          id='add-webhook-name'
          value={name}
          placeholder={t('Name')}
          required={true}
          pattern='^[A-Za-z0-9 ]{1,100}$'
          title={t('WebhookName-validation')}
        />

        <NormalSelectInput
          defaultValue={method}
          setSelect={setMethod}
          selectOptionArr={methodOptions}
          placeholder={t('Method')}
          id='add-webhook-method'
        />

        <NormalInput
          changeListeners={[(e) => setEndpoint(e.target.value)]}
          id='add-webhook-endpoint'
          value={endpoint}
          placeholder={t('Endpoint')}
          innerPlaceholder='https://www.example.com'
          required={true}
          // pattern='^(https?:\/\/)?[0-9a-zA-Z]+\.[-_0-9a-zA-Z]+\.[0-9a-zA-Z]+$'
          // title='Expected URL format: https://www.example.com'
        />
      </div>

      <div className='normalForm__bottom'>
        <MainSquareButton
          value={t('Add')}
          /* icon={<CustomSVGs svg={plusSignSVG} />} */
          disabled={isDisabled}
          type='submit'
          form='webhookAdd-form'
        />
      </div>
    </form>
  );
};

export default AddWebhook;
