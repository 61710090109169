//mui imports--
import { DataGrid, GridColDef } from '@mui/x-data-grid';

//api call imports
import {
  CHAT_GPT_ENDPOINT,
  PRODUCTS_ENDPOINT,
} from '../../../lib/constants/urls';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../lib/api/async-handler';
// import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import store from '../../../store/store';

//other imports
import { useEffect, useRef, useState } from 'react';
import SubPageContentHolder from '../../../components/subPageContentHolder/SubPageContentHolder';
import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../ui/inputs/NormalInput';
import NormalSelectInput from '../../../ui/inputs/NormalSelectInput';
import { trashSVG, editSVG } from '../../../lib/allCustomSVGs';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import Popup from '../../../ui/popup/Popup';
import EditVariant from '../editProduct/EditVariant';
import { useNavigate } from 'react-router-dom';
import MultipleImagesInput from '../../../ui/inputs/MultipleImagesInput';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import MaskedCurrencyInput from '../../../ui/inputs/CurrencyInput';
import { AnimatePresence, motion } from 'framer-motion';
import AddProductAttributes from '../addProduct/AddProductAttributes';
import ToggleSwitch from '../../../ui/buttons/ToggleSwitch';
import { VariantAttributesInterface } from '../../../lib/initialStates/productPagesInitialState';
import convertAttributesArrayToKeyValues from '../../../lib/helper-functions/convertAttributesArrayToKeyValues';
import convertStringAttritubtesToObject from '../../../lib/helper-functions/convertStringAttritubtesToObject';
import generateVariants from '../../../lib/helper-functions/generateVariants';
import { useTranslation } from 'react-i18next';
import generateSKU from '../../../lib/helper-functions/generateSKU';
import { trimFromStartToLimitedNumber } from '../../../lib/helper-functions/trimSKU';
import TextAreaAI from '../../../ui/inputs/TextAreaAI';

interface Props {
  productId: any;
  productRes: any;
  variantRes: any;
}

const EditProduct = ({ productId, productRes, variantRes }: Props) => {
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const { t } = useTranslation();
  /* product state */
  const [product, setProduct] = useState<any>(productRes);
  const [productVariantsArr, setProductVariantsArr] =
    useState<any[]>(variantRes);
  const [generatedSKU, setGeneratedSKU] = useState<string>('');

  useEffect(() => {
    setGeneratedSKU(
      trimFromStartToLimitedNumber(generateSKU(product.productName), 20)
    );
  }, [product.productName]);

  /* const initialProductType = product?.productType; */
  const [productType, setProductType] = useState(product?.productType);

  /* Product images state */
  const [selectedProductImages, setSelectedProductImages] = useState([]);
  const [previewProductImages, setPreviewProductImages] = useState([]);

  /* organized variants state for variant table */
  const [variantsForTable, setVariantsForTable] = useState<any[]>([]);

  /* add variant popup modal state */
  const [isEditVariantPopupOpen, setIsEditVariantPopupOpen] = useState(false);

  //Variant Attributes state
  const [variantAttributes, setVariantAttributes] = useState<
    VariantAttributesInterface[]
  >([]);

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelsellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  useEffect(() => {
    return () => {
      // Clear the timeout when the component unmounts
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  /* generate Title & Description using AI state */
  const [isAvailableAI, setIsAvailableAI] = useState(false);
  const [isAITextState, setIsAITextState] = useState<string>('initial');

  const [copyDataFromProduct, setCopyDataFromProduct] =
    useState<boolean>(false);

  let isPrice;
  if (product?.productPrice === '' && productType === 'simple') {
    isPrice = true;
  } else if (product?.productPrice === '' && productType === 'variable') {
    isPrice = false;
  }

  let isDisabled =
    product?.productName === '' ||
    product?.productDescription === '' ||
    isPrice ||
    product?.productStock === ''
      ? true
      : false;

  /* product types select options array */
  const productTypesArr = [
    { OptionValue: 'variable', OptionName: 'Variable' },
    { OptionValue: 'simple', OptionName: 'Simple' },
  ];

  const [editingVariantId, setEditingVariantId] = useState<number>();

  const navigate = useNavigate();

  /* COLUMNS FOR DATA TABLE START */
  const columns: GridColDef[] = [
    {
      field: 'image',
      headerName: t('Image'),
      width: 70,
      renderCell: (params) => {
        /* console.log(params); */
        return (
          <div className='dataGrid__imageCell'>
            {params.row.image && (
              <img src={params.row.image} alt={`${params.row.type} pic`} />
            )}
            {!params.row.image && (
              <img
                src={`${staticURL}/small/default.jpg`}
                alt={`${params.row.type} pic`}
              />
            )}
          </div>
        );
      },
    },
    { field: 'name', headerName: t('Name'), width: 170 },
    { field: 'sku', headerName: 'sku', width: 150 },
    { field: 'price', headerName: t('Price'), width: 100 },
    { field: 'stock', headerName: t('Stock'), width: 100 },
    { field: 'attributes', headerName: t('Attributes'), width: 100 },
  ];
  /* COLUMNS FOR DATA TABLE END */

  /* ----------------------------API CALL ADD VARIANTS START---------------------------- */
  async function addVarinatsAsync(
    accessToken: any,
    sellerToken: any,
    productId: string,
    variant: any
  ) {
    const sendindAttributes =
      convertAttributesArrayToKeyValues(variant?.variantAttributes) || 'null';
    const formData = new FormData();
    formData.append('name', variant?.productVariants?.productVariantName);
    formData.append(
      'description',
      variant?.productVariants?.productVariantDescription
    );
    formData.append('status', variant?.productVariantStatus);
    formData.append('sku', variant?.productVariants?.productVariantSKU);
    formData.append('price', variant?.productVariants?.productVariantPrice);
    formData.append('stock', variant?.productVariants?.productVariantStock);
    formData.append('attributes', sendindAttributes);
    formData.append('type', 'variable');
    if (
      variant?.productVariantImages?.selectedProductVariantImages?.length > 0
    ) {
      variant?.productVariantImages?.selectedProductVariantImages?.forEach(
        (item: any) => {
          formData.append('media[]', item);
        }
      );
    }
    // console.log(variant);
    console.log(variant?.productVariantId);
    const urlToAddVariant = `${PRODUCTS_ENDPOINT}/${productId}/variants`;
    const urlToEditVariant = `${PRODUCTS_ENDPOINT}/${productId}/variants/${variant?.productVariantId}`;
    try {
      const requestInfo: RequestInfo = {
        url: variant?.productVariantId ? urlToEditVariant : urlToAddVariant,
        method: 'POST',
        body: formData,
        headers: {
          /* Accept: 'application/json', */
          /* 'Content-type': 'application/x-www-form-urlencoded', */
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'ADD_PRODUCT_VARIANT';
      const customMsg = t('ProductVariantAddedSuccessfully-msg');

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return 1;
      }
    } catch (exception) {
      console.log('Error during request');
    }
  }
  /* ----------------------------API CALL ADD VARIANTS END---------------------------- */

  /* ----------------------------API CALL EDIT PRODUCT START---------------------------- */
  async function onSubmitHandler(e: any) {
    e.preventDefault();
    try {
      const accessToken = store.getState().auth.authorization.accessToken;
      const isWhiteLabel = store.getState().UI?.isWhiteLabel;
      let sellerToken: string;
      if (isWhiteLabel) {
        sellerToken = accessToken?.currentWhiteLabelsellerToken;
      } else {
        sellerToken = accessToken?.sellerToken;
      }

      const formData = new FormData();
      formData.append('name', product?.productName);
      formData.append('description', product?.productDescription);
      formData.append(
        'sku',
        product?.productSKU === '' ? generatedSKU : product?.productSKU
      );
      formData.append('price', product?.productPrice);
      formData.append('stock', product?.productStock);
      formData.append('type', productType);
      if (selectedProductImages?.length > 0) {
        selectedProductImages?.forEach((item: any) => {
          formData.append('media[]', item);
        });
      }

      const requestInfo: RequestInfo = {
        url: `${PRODUCTS_ENDPOINT}/${productId}`,
        method: 'POST',
        body: formData,
        headers: {
          /* Accept: 'application/json', */
          /* 'Content-type': 'application/x-www-form-urlencoded', */
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'EDIT_PRODUCT';
      const customMsg = t('ProductUpdatedSuccessfully-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;
      if (productType === 'variable') {
        if (res.actionType === actionType && response.success) {
          let variantSuccessCount = 0;
          productVariantsArr.forEach(async (el: any, index: number) => {
            const result: any = response.result;
            const productId = result.id;
            // console.log(productId);
            const variantResult = await addVarinatsAsync(
              accessToken,
              sellerToken,
              productId,
              el
            );
            console.log(productVariantsArr?.length);
            console.log(variantSuccessCount);
            if (variantResult) {
              variantSuccessCount++;
            }
            if (productVariantsArr?.length === variantSuccessCount) {
              navigate('/products');
            }
            if (
              index + 1 === productVariantsArr?.length &&
              productVariantsArr?.length !== variantSuccessCount
            ) {
              console.log(
                'Something went wrong all the variants were not created!'
              );
            }
          });
        }
      } else {
        navigate('/products');
      }
    } catch (exception) {
      console.log('Error during request');
    }
  }
  /* ----------------------------API CALL EDIT PRODUCT END---------------------------- */

  /* ----------------------------API CALL DELETE PRODUCT VARIANT START-------------------------- */
  const variantDeleteHandler = async (deletingVariantIndex: any) => {
    try {
      const requestInfo: RequestInfo = {
        url: `${PRODUCTS_ENDPOINT}/${productId}/variants/${productVariantsArr[deletingVariantIndex].productVariantId}`,
        method: 'DELETE',
        headers: {
          /* Accept: 'application/json', */
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'DELETE_PRODUCT_VARIANT';
      const customMsg = t('ProductVariantDeletedSuccessfully-msg');
      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        if (productVariantsArr.length === 1) {
          setProductVariantsArr([]);
          setVariantsForTable([]);
        } else if (productVariantsArr.length > 1) {
          const keepingItems = productVariantsArr.filter(
            (_, index) => index !== deletingVariantIndex
          );
          setProductVariantsArr(keepingItems);
        }
      }
    } catch (exception) {
      console.log('Error during request');
    }
  };
  /* ----------------------------API CALL DELETE PRODUCT VARIANT END---------------------------- */

  /* creating an array of variants for table*/
  useEffect(() => {
    if (productVariantsArr.length > 0) {
      const allVariants = productVariantsArr.map((el: any, i: number) => {
        let arrtibutesArr: string[] = [];
        if (el?.variantAttributes?.length > 0) {
          arrtibutesArr = el?.variantAttributes?.map((att: any) => {
            return `${att?.name}: ${att?.value}`;
          });
        }
        let prevImage = null;
        if (
          el?.productVariantImages?.selectedProductVariantImages?.length > 0 &&
          el?.productVariantImages?.previewProductVariantImages?.length > 0
        ) {
          prevImage = el?.productVariantImages?.previewProductVariantImages[0];
        } else if (
          el?.productVariantImages?.previewProductVariantImages?.length > 0
        ) {
          prevImage = `${staticURL}/small/${el?.productVariantImages?.previewProductVariantImages[0]}`;
        } else {
          prevImage = null;
        }
        const singleVariant = {
          id: i,
          /* image:
            el?.productVariantImages?.previewProductVariantImages?.length > 0
              ? `${staticURL}/small/${el?.productVariantImages?.previewProductVariantImages[0]}`
              : null, */
          image: prevImage,
          name: el?.productVariants?.productVariantName,
          sku: el?.productVariants?.productVariantSKU,
          price: el?.productVariants?.productVariantPrice,
          stock: el?.productVariants?.productVariantStock,
          attributes: arrtibutesArr?.toString(),
        };
        return singleVariant;
      });

      setVariantsForTable(allVariants);
    }
  }, [productVariantsArr]);

  // console.log(productVariantsArr);

  /* ACTION COLUMN FOR DATA TABLE START */
  const actionColumn = [
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      renderCell: (params: any) => {
        return (
          <div className='cellAction'>
            <div
              onClick={() => {
                toggleEditVariantPopupModal();
                setEditingVariantId(params?.row?.id);
              }}
              className='datatableActionBtn press editButton'
            >
              <CustomSVGs svg={editSVG} />
              {/* <span>View</span> */}
            </div>
            {/* -------------------------- */}
            <div
              onClick={() => {
                const deletingVariantIndex = params?.row?.id;
                /* navigate(`/products/${params.row.id}`); */
                // console.log(productVariantsArr[deletingVariantIndex]);
                variantDeleteHandler(deletingVariantIndex);
              }}
              className='datatableActionBtn press deleteButton'
            >
              <CustomSVGs svg={trashSVG} />
              {/* <span>View</span> */}
            </div>
          </div>
        );
      },
    },
  ];
  /* -ACTION COLUMN FOR DATA TABLE END- */

  const dataGridHeightCalc = () => {
    if (variantsForTable?.length === 1) {
      return '18rem';
    } else if (variantsForTable?.length > 1) {
      const a = variantsForTable?.length - 1;
      const b = a * 10 + 18;
      return `${b}rem`;
    }
  };

  const toggleEditVariantPopupModal = () => {
    setIsEditVariantPopupOpen(!isEditVariantPopupOpen);
  };

  const createVariantObject = (possibleVariantsList: string[][]) => {
    if (possibleVariantsList.length > 0) {
      const output = possibleVariantsList[0].map(
        (item: string, index: number) => {
          if (copyDataFromProduct) {
            const filledVariantObj = {
              productVariants: {
                productVariantName: product.productName + '-' + item,
                productVariantDescription:
                  product.productDescription + '-' + item,
                productVariantSKU: product.productSKU + '-' + item,
                productVariantPrice: product.productPrice,
                productVariantStock: product.productStock,
              },
              productVariantStatus: 'active',
              productVariantImages: {
                selectedProductVariantImages: selectedProductImages,
                previewProductVariantImages: previewProductImages,
              },
              variantAttributes: convertStringAttritubtesToObject(
                possibleVariantsList[1][index]
              ),
            };

            return filledVariantObj;
          } else {
            const unfilledVariantObj = {
              productVariants: {
                productVariantName: '',
                productVariantDescription: '',
                productVariantSKU: '',
                productVariantPrice: '',
                productVariantStock: '',
              },
              productVariantStatus: '',
              productVariantImages: {
                selectedProductVariantImages: [],
                previewProductVariantImages: '',
              },
              variantAttributes: [
                {
                  id: 0,
                  name: '',
                  value: item,
                },
              ],
            };

            return unfilledVariantObj;
          }
        }
      );

      setProductVariantsArr(output);
    }
  };

  const getAllPossibleVariants = () => {
    createVariantObject(generateVariants(variantAttributes));
  };

  useEffect(() => {
    setProduct((prev: any) => {
      return { ...prev, productType: productType };
    });
  }, [productType]);

  /* handle AI button availability based on product Image selection */
  useEffect(() => {
    if (selectedProductImages.length > 0) {
      setIsAvailableAI(true);
    } else {
      setIsAvailableAI(false);
    }
  }, [selectedProductImages]);

  const onAIBtnClick = async () => {
    setIsAITextState('loading');
    const prompt = `Using given data can you generate an attractive product title & a product description which is suitable for an e-commerce website which gives a good overview for the user about the product and add detailed facts if needed, And make sure that the title is always less than 20 characters & the description is always less than 100 characters. Give the result in following format : {"title" : "", "description : ""}. if you cannot find a meaningful title or description for the given product then your response should be "null"`;

    try {
      const formData = new FormData();
      formData.append('prompt', prompt);
      if (selectedProductImages?.length > 0) {
        formData.append('file', selectedProductImages[0]);
      }
      const requestInfo: RequestInfo = {
        url: `${CHAT_GPT_ENDPOINT}/image`,
        method: 'POST',
        body: formData,
        headers: {
          Accept: 'application/json',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_CHAT_GPT_ANSWER_FOR_IMAGE';
      const customMsg = 'AI Text Fetched For Image Success';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        const result = response?.messages?.generated_description;

        if (result === 'null') {
          setIsAITextState('null');
          // Update the productTitle & the productDescription state
          setProduct((prev: any) => {
            return { ...prev, productName: '', productDescription: '' };
          });
          timeoutRef.current = setTimeout(() => {
            setIsAITextState('initial');
          }, 5000);
        } else if (result) {
          setIsAITextState('initial');
          const data = JSON.parse(result);
          let title = data?.title;
          let description = data?.description;
          // Update the productTitle & the productDescription state
          setProduct((prev: any) => {
            return {
              ...prev,
              productName: title,
              productDescription: description,
            };
          });
        }
      } else {
        setIsAITextState('error');
        timeoutRef.current = setTimeout(() => {
          setIsAITextState('initial');
        }, 5000);
      }
    } catch (exception) {
      console.log('Error during authentication request', exception);
      setIsAITextState('error');
      timeoutRef.current = setTimeout(() => {
        setIsAITextState('initial');
      }, 5000);
    }
  };

  const renderAIBtnText = () => {
    if (isAITextState === 'initial') {
      return 'Generate an attractive product title & description with AI';
    }
    if (isAITextState === 'loading') {
      return 'Generating...';
    }
    if (isAITextState === 'null') {
      return 'Cannot identify the product. Enter a clear product image';
    }
    if (isAITextState === 'error') {
      return 'Something went wrong';
    }
  };

  return (
    <SubPageContentHolder>
      <SubPageInnerContentHolder
        title={t('EditProduct')}
        subTitle={t('EditProductsAndVariants')}
        backBtnLink='/products'
      >
        <div
          id='edit-product-form'
          /* onSubmit={onSubmitHandler} */
          className='normalForm'
        >
          <div className='normalForm__top'>
            <MultipleImagesInput
              setSendingFiles={setSelectedProductImages}
              setSendingFilesPreviews={setPreviewProductImages}
              labelName={t('ProductMedia')}
              acceptedFileTypes={'.png, .jpeg, .webp, .mp4, .gif'}
              id={'edit-product-images'}
              manyImages={true}
              uneditableImageArray={
                product?.productImages?.map((url: string) => {
                  return `${staticURL}/small/${url}`;
                }) || []
              }
            />
            {/* ------------------------------------------------------- */}
            {isAvailableAI && (
              <div className='normalForm__AI__button-initial'>
                <div
                  className={
                    isAITextState === 'error'
                      ? 'normalForm__AI__button-error'
                      : isAITextState === 'null'
                      ? 'normalForm__AI__button-warning'
                      : ''
                  }
                  onClick={onAIBtnClick}
                >
                  {renderAIBtnText()}
                </div>
              </div>
            )}
            {/* ------------------------------------------------------- */}
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <NormalInput
                  changeListeners={[
                    (e) =>
                      setProduct((prev: any) => ({
                        ...prev,
                        productName: e.target.value,
                      })),
                  ]}
                  id='edit-product-name'
                  value={product.productName}
                  placeholder={t('ProductName')}
                  required={true}
                  title={t('ProductName-validation')}
                />
              </div>
              <div className='normalForm__container1--right'>
                <TextAreaAI
                  id='edit-product-description'
                  productDescription={product.productDescription}
                  setProductDescription={(description) =>
                    setProduct((prevProduct: any) => ({
                      ...prevProduct,
                      productDescription: description,
                    }))
                  }
                  placeholder={t('ProductDescription')}
                  required={true}
                  title={t('ProductDescription-validation')}
                  // textForAI={product.productName} //? commented out since Image AI detection is enabled
                />
              </div>
            </div>
            {/* ------------------------------------------------------- */}

            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <NormalSelectInput
                  defaultValue={productType}
                  setSelect={setProductType}
                  selectOptionArr={productTypesArr}
                  placeholder={t('Type')}
                  id={'edit-product-type'}
                />
              </div>
              <div className='normalForm__container1--right'>
                {' '}
                <NormalInput
                  changeListeners={[
                    (e) =>
                      setProduct((prev: any) => {
                        return { ...prev, productSKU: e.target.value };
                      }),
                  ]}
                  id='edit-product-sku'
                  value={product.productSKU}
                  placeholder='SKU'
                  pattern='[^ ]{1,20}$'
                  innerPlaceholder={generatedSKU}
                  /* required={true} */
                  title={t('ProductSKU-validation')}
                  maxlength={20}
                />
              </div>
            </div>
            <div className='normalForm__container1'>
              <div className='normalForm__container1--left'>
                <NormalInput
                  changeListeners={[
                    (e) =>
                      setProduct((prev: any) => {
                        return { ...prev, productStock: e.target.value };
                      }),
                  ]}
                  id='edit-product-stock'
                  value={product.productStock}
                  placeholder={t('Stock')}
                  required={true}
                  pattern='^[0-9]{1,10}$'
                  title={t('ProductStock-validation')}
                />
              </div>
              <div className='normalForm__container1--right'>
                {productType === 'simple' && (
                  <MaskedCurrencyInput
                    changeListeners={[
                      (value) =>
                        setProduct((prev: any) => {
                          return { ...prev, productPrice: value };
                        }),
                    ]}
                    id='edit-product-price'
                    value={
                      product.productPrice &&
                      !Number.isNaN(product.productPrice)
                        ? product.productPrice
                        : '0'
                    }
                    placeholder={t('Price')}
                    required={true}
                    title={t('OnlyNumbers-validation')}
                    type='text'
                    pattern='^[\d|\.|\,]+/'
                    defaultValue={
                      product.productPrice &&
                      !Number.isNaN(product.productPrice)
                        ? product.productPrice
                        : '0'
                    }
                  />
                )}
              </div>
            </div>

            {/* ------------------------------------------------------- */}

            <div
              style={{
                display: `${productType === 'variable' ? 'block' : 'none'}`,
              }}
            >
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className='settingsShippingOptions__bottom'
                >
                  <AddProductAttributes
                    variantAttributes={variantAttributes}
                    setVariantAttributes={setVariantAttributes}
                  />
                </motion.div>
              </AnimatePresence>
              {/* ------------------------------------ */}
              {variantAttributes[0]?.name &&
                variantAttributes[0]?.value?.length > 0 && (
                  <>
                    <ToggleSwitch
                      isOn={copyDataFromProduct}
                      title={t('CopyDataFromProduct')}
                      onClick={setCopyDataFromProduct}
                    />
                    {/* ----------------------------------- */}
                    <MainSquareButton
                      value={t('GenerateVariants')}
                      onClick={getAllPossibleVariants}
                      classes={'margin-top-2rem'}
                      disabled={isDisabled}
                    />
                    {isDisabled && (
                      <span className='quick__message'>
                        {t('FillAllProductFields')}
                      </span>
                    )}
                  </>
                )}
            </div>
            {/* ------------------------------------------------------- */}

            {/* ------------------Variants Table Start----------------- */}
            {variantsForTable.length > 0 && (
              <>
                <div className='productDetailPage--varients-text'>
                  {t('Varients')}
                </div>
                <div
                  style={{ height: `${dataGridHeightCalc()}` }}
                  className='normalForm-dataGridContainer'
                >
                  <DataGrid
                    sx={{
                      /* boxShadow: '0px 3px 10px -6px rgba(0, 0, 0, 0.84);', */
                      border: 'none',
                      '& .MuiDataGrid-cell:hover': {
                        color: 'primary.main',
                      },
                    }}
                    // components={{ Toolbar: GridToolbar }}
                    rows={variantsForTable}
                    getRowHeight={() => 'auto'}
                    disableSelectionOnClick
                    columns={columns.concat(actionColumn)}
                    pageSize={100}
                    rowsPerPageOptions={[100]}
                    checkboxSelection
                    style={{ fontSize: '1.5rem' }}
                  />
                </div>
              </>
            )}
            {/* ------------------Variants Table End------------------- */}
          </div>

          <div className='normalForm__bottom flex-start'>
            <MainSquareButton
              value={t('EditProduct')}
              onClick={onSubmitHandler}
              disabled={isDisabled}
              /* icon={<CustomSVGs svg={plusSignSVG} />} */
              /* type='submit'
              form='edit-product-form' */
            />
          </div>
        </div>
        {/* <Popup
            styles={{ backgroundColor: '#fff' }}
            setTrigger={togglePopupModal}
            trigger={isPopupOpen}
          >
            <AddProductVariant
              productId={addProductResponse?.id}
              closeWindow={togglePopupModal}
              productVariantsArr={productVariantsArr}
              setProductVariantsArr={setProductVariantsArr}
            />
          </Popup> */}
        <Popup
          styles={{ backgroundColor: '#fff' }}
          setTrigger={toggleEditVariantPopupModal}
          trigger={isEditVariantPopupOpen}
        >
          <EditVariant
            setProductVariantsArr={setProductVariantsArr}
            productVariantsArr={productVariantsArr}
            currentId={editingVariantId}
            closeWindow={toggleEditVariantPopupModal}
            productId={productId}
          />
        </Popup>
      </SubPageInnerContentHolder>
    </SubPageContentHolder>
  );
};

export default EditProduct;
