import { motion } from 'framer-motion';
import store from '../../store/store';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { onFormSubmitedFailed } from '../../components/generic/common-toasts';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import {
  OnboardingStagesMap,
  setStageComplete,
  updateOnboardingStage,
  updateProfile,
} from '../../store/reducers/userSlice';
import { RootState } from '../../store/store';
import './onboarding-page.css';
import '../../styles/components/onboarding/profileUpdate.scss';
import NormalInput from '../../ui/inputs/NormalInput';
import MainPurpleButton from '../../ui/buttons/MainPurpleButton';
import PhoneInput from '../../ui/inputs/PhoneInput';
import NormalSelectInput from '../../ui/inputs/NormalSelectInput';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import { ONBOARDING_ENDPOINT } from '../../lib/constants/urls';
import { useDispatch } from 'react-redux';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import { useTranslation } from 'react-i18next';
import generateErrorMessage from '../../lib/helper-functions/generateErrorMessage';

const ProfileUpdate = () => {
  const { t } = useTranslation();
  const IPCountry = useSelector(
    (state: RootState) => state.app.countries.countryOfIP
  );
  const countries = useSelector(
    (state: RootState) => state.app.countries.available
  );
  const applanguage = useSelector((state: RootState) => state.app.language);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  const languagesArr = [
    { OptionValue: 'en', OptionName: 'English' },
    { OptionValue: 'nl', OptionName: 'Dutch' },
    { OptionValue: 'fr', OptionName: 'French' },
    { OptionValue: 'de', OptionName: 'German' },
    { OptionValue: 'it', OptionName: 'Italian' },
    { OptionValue: 'pt', OptionName: 'Portugese' },
    { OptionValue: 'es', OptionName: 'Spanish' },
  ];
  const [language, setLanguage] = useState(applanguage);

  const [username, setUserName] = useState({
    first: '',
    second: '',
  });

  /* const [companyName, setCompanyName] = useState(''); */
  /* const [sellerName, setSellerName] = useState(''); */

  const [phoneNumber, setPhoneNumber] = useState({
    country: IPCountry,
    number: '',
  });
  /* const [country, setCountry] = useState(IPCountry); */

  const dispatch = useDispatch();
  const [errors, setErrors] = useState<any>();

  async function submitProfile(e: any) {
    e.preventDefault();
    try {
      const accessToken = store.getState().auth.authorization.accessToken;
      console.log(accessToken);
      /*  */
      const requestInfo: RequestInfo = {
        url: ONBOARDING_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          onboarding_data: JSON.stringify({
            onboard_type: 'profile_updates',
            first_name: username.first,
            last_name: username.second,
            country_code: countries[phoneNumber.country].dialCode,
            phone: phoneNumber.number,
            language: language,
          }),
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + accessToken.sellerToken,
        },
      };

      const actionType = 'ONBOARDING_PROFILE_UPDATE';
      const customMsg = 'Profile Update Successfull.';

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        dispatch(
          updateOnboardingStage(OnboardingStagesMap.SubmittingCompany.name)
        );
        dispatch(
          updateProfile({
            first_name: username.first,
            last_name: username.second,
            country_code: countries[phoneNumber.country].dialCode,
            phone: phoneNumber.number,
            language: language,
          })
        );
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.ProfileUpdate.name,
            isCompleted: true,
          })
        );

        // dispatch(setUserCompanyName(companyName));
      } else {
        /* setErrors(response.message);
        onFormSubmitedFailed(response.message); */

        setErrors(generateErrorMessage(response));
        onFormSubmitedFailed(generateErrorMessage(response));
      }
    } catch (exception) {
      console.log('Error during authentication request');
    }
  }

  const isDisabled =
    username.first === '' || username.second === '' || phoneNumber.number === ''
      ? true
      : false;

  return (
    <motion.div
      {...pageSlideOutForward}
      key='onboarding-company-details-page'
      className='profileUpdate animate-fade_in_from_right'
    >
      <div className='profileUpdate__top'></div>
      <div className='profileUpdate__container'>
        <div className='profileUpdate__container--top'>
          <h1>{t('OnboardingProfileUpdateH1')}</h1>
          <h2>{t('OnboardingProfileUpdateH2')}</h2>
        </div>

        <form
          id='onboarding-profileUpdate'
          className='profileUpdate__container--center'
          onSubmit={submitProfile}
        >
          <NormalInput
            id='user-update-username-1'
            value={username.first}
            changeListeners={[
              (e) =>
                setUserName({
                  first: e.target.value,
                  second: username.second,
                }),
            ]}
            focusListeners={[]}
            placeholder={t('FirstName')}
            classes='lg:w-[13rem]'
            required={true}
            pattern='^[A-Za-z ]{2,30}$'
            title={t('Name-validation')}
            autoFocus={true}
          />
          <NormalInput
            id='user-update-username-2'
            value={username.second}
            changeListeners={[
              (e) =>
                setUserName({
                  first: username.first,
                  second: e.target.value,
                }),
            ]}
            focusListeners={[]}
            placeholder={t('LastName')}
            classes='lg:w-[13rem]'
            required={true}
            pattern='^[A-Za-z ]{2,30}$'
            title={t('Name-validation')}
          />

          <NormalSelectInput
            defaultValue={language}
            setSelect={setLanguage}
            selectOptionArr={languagesArr}
            placeholder={t('Language')}
            id={'user-update-language'}
          />

          <PhoneInput
            updateCountry={(countryCode: string) =>
              setPhoneNumber({ ...phoneNumber, country: countryCode })
            }
            changeListeners={[
              (e) => {
                setPhoneNumber({ ...phoneNumber, number: e.target.value });
              },
            ]}
            id='user-update-phone'
            value={phoneNumber.number}
            placeholder={t('Phone')}
            countryCode={phoneNumber.country}
            title={t('Phone-validation')}
            required={true}
            pattern='^[0-9]{4,}$'
          />
        </form>
        <div className='profileUpdate__container--bottom'>
          <MainPurpleButton
            value={t('Continue')}
            disabled={isDisabled}
            type='submit'
            form='onboarding-profileUpdate'
          />
        </div>

        <div className='profileUpdate__container--footer'>
          <a target={'_blank'} href={`${merchantTheme?.terms_url}`}>
            {t('Terms')}
          </a>
          {` & `}
          <a target={'_blank'} href={`${merchantTheme?.privacy_url}`}>
            {t('Privacy')}
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default ProfileUpdate;
