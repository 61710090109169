import { useLocation, Route, Routes, useNavigate } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { plusSignSVG } from '../../../../lib/allCustomSVGs';
import AddPSP from './AddPSP';
import EditPSP from './EditPSP';
import ListPSP from './ListPSP';
import DeletePSP from './DeletePSP';

import store, { RootState, useAppDispatch } from '../../../../store/store';
import {
  MODULES_PSP_ENDPOINT,
  PSP_ENDPOINT,
} from '../../../../lib/constants/urls';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../../lib/api/async-handler';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import { useEffect, useState } from 'react';
import {
  setAlreadyAddedPSP,
  setAvailablePSP,
  setPaymentMethods,
} from '../../../../store/reducers/userSlice';
import NoData from '../../../../ui/noData/NoData';
import NoPSP from './NoPSP';
import { useTranslation } from 'react-i18next';
import getPaymentMethods from '../../../../lib/helper-functions/getPaymentMethods';
import { useSelector } from 'react-redux';
import getOnlyVisiblePSPs from '../../../../lib/helper-functions/getOnlyVisiblePSPs';

const PSPConnection = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  // const [pspArray, setPSPArray] = useState([]);
  const activeLink = location.pathname;
  const [loading, setLoading] = useState('pending');
  const dispatch = useAppDispatch();
  const merchantName = useSelector(
    (state: RootState) => state.user?.merchant?.config?.merchant_name
  );
  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const alreadyAddedPSP = useSelector(
    (state: RootState) => state.user.company.alreadyAddedPSP
  );
  const usablePSPs = getOnlyVisiblePSPs(onboarding_object);
  const redirectNumber = 1;
  /* const shopId = useSelector((state: RootState) => state.user.company.shop_id); */

  // console.log(window.location.origin);

  let subTitle = '';

  if (activeLink.includes('add')) {
    subTitle = t('Create-PSP-Connection');
  } else if (activeLink.includes('delete')) {
    subTitle = t('Delete-PSP-Connection');
  } else if (activeLink.includes('edit')) {
    subTitle = t('Edit-PSP-Connection');
  } else {
    subTitle = loading === 'success' ? t('ManagePSP') : '';
  }

  const getBackButtonRoutes = () => {
    let backButtonLink;
    if (
      usablePSPs?.length === redirectNumber &&
      alreadyAddedPSP?.length === 0
    ) {
      backButtonLink = '/shop';
    } else if (
      activeLink.includes('add') ||
      activeLink.includes('edit') ||
      activeLink.includes('delete')
    ) {
      backButtonLink = '/shop/psp/connection';
    } else {
      backButtonLink = '/shop';
    }

    return backButtonLink;
  };

  /* ----------------------------API CALL START---------------------------- */
  const accessToken = store.getState().auth.authorization.accessToken;

  const getAvailablePSP = async () => {
    setLoading('pending');
    const requestInfo: RequestInfo = {
      url: `${MODULES_PSP_ENDPOINT}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: accessToken.type + ' ' + accessToken.sellerToken,
      },
    };

    const actionType = 'GET_AVAILABLE_PSP_DATA';
    const customMsg = 'Available PSP Data Loaded Successfully.';

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res?.data;
    if (!response.success) {
      setLoading('error');
      console.log('No Available PSP');
      throw new Error(response.message);
    }
    if (res.actionType === actionType && response.success === true) {
      if (response.result?.length > 0) {
        store.dispatch(setAvailablePSP(response?.result));
        store.dispatch(setPaymentMethods(getPaymentMethods(response?.result)));
        getPSP(response?.result);
      } else {
        setLoading('null');
        console.log('No Available PSP');
      }
    }
  };

  const getPSP = async (availablePSPs: any) => {
    const requestInfo: RequestInfo = {
      url: `${PSP_ENDPOINT}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: accessToken.type + ' ' + accessToken.sellerToken,
      },
    };

    const actionType = 'GET_PSP_DATA';
    const customMsg = 'PSP Data Loaded Successfully.';

    setLoading('pending');
    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res?.data;
    if (!response.success) {
      setLoading('error');
    }
    if (res.actionType === actionType && response.success === true) {
      if (response.result?.length > 0) {
        setLoading('success');
        dispatch(setAlreadyAddedPSP(response.result));
        console.log(response.result);
        navigate('/shop/psp/connection');
      } else {
        setLoading('null');
        console.log(response.result);
        console.log(usablePSPs);
        if (availablePSPs?.length === redirectNumber) {
          console.log(onboarding_object?.available_psp);
          navigate('/shop/psp/connection/add');
        }
      }
    }
  };
  useEffect(() => {
    if (activeLink === '/shop/psp/connection') {
      getAvailablePSP();
    }
  }, [activeLink]);

  useEffect(() => {
    navigate('/shop/psp/connection');
  }, []);

  /* ----------------------------API CALL END---------------------------- */

  const renderConnectionPage = () => {
    if (loading === 'pending') {
      return <LoadingSpinner />;
    } else if (loading === 'null') {
      return <NoPSP />;
    } else if (loading === 'error') {
      return (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      );
    } else {
      return <ListPSP />;
    }
  };

  const mainBtnTitle = () => {
    if (activeLink === '/shop/psp/connection' && loading === 'success') {
      return 'Add PSP';
    } else {
      return undefined;
    }
  };

  return (
    <SubPageInnerContentHolder
      title={t('Payments')}
      subTitle={subTitle}
      backBtnLink={getBackButtonRoutes()}
      mainBtnIcon={plusSignSVG}
      mainBtnTitle={mainBtnTitle()}
      mainBtnLink='/shop/psp/connection/add'
    >
      {activeLink === '/shop/psp/connection' && renderConnectionPage()}
      {/* {activeLink === '/shop/cms/connection' && (
        <>
          {loading === 'pending' && <LoadingSpinner />}
          {loading === 'error' && (
            <NoData
              title='Error Fetching Data'
              subTitle='Something went wrong :('
            />
          )}
          {pspData?.length > 0 && <EditPSP pspData={pspData} />}
        </>
      )} */}

      <Routes>
        <Route path='/add' element={<AddPSP />} />
        <Route path='/delete/:pspId' element={<DeletePSP />} />
        <Route path='/edit/:pspId' element={<EditPSP />} />
      </Routes>
    </SubPageInnerContentHolder>
  );
};

export default PSPConnection;
