import { useState } from 'react';
import { infoSVG } from '../../lib/allCustomSVGs';
import '../../styles/components/showInfoBlock/showInfoBlock.scss';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';

interface Props {
  infoText: string;
  hoverTitle?: string;
}

const ShowInfoBlock = ({ infoText, hoverTitle }: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      title={hoverTitle ? hoverTitle : t('ClickToViewInstructions-tag')}
      data-info_block={isOpen.toString()}
      className='showInfoBlock'
    >
      <CustomSVGs svg={infoSVG} />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            className='showInfoBlock__infoHolder'
          >
            <p>{infoText}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ShowInfoBlock;
