import '../../styles/pages/shop/shopPage.scss';
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from 'react-router-dom';
import PageWrapper from '../../components/page-wrappers/PageWrapper';
import CategoryCard from '../../ui/cards/CategoryCard';
import { pspSvg, cmsSVG } from '../../lib/allCustomSVGs';
import CMS from './cms/CMS';
import PSP from './psp/PSP';
import { RootState } from '../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import Popup from '../../ui/popup/Popup';
import { setIsProductWizardOpen } from '../../store/reducers/userSlice';
import ProductWizard from '../../components/productWizard/ProductWizard';
import { setIsOnboardingCompleteBannerOpen } from '../../store/reducers/uiSlice';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import { fetchAppInit } from '../../lib/api/app-init';
import { setRunInitOnFirstLoad } from '../../store/reducers/appSlice';
const ShopPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const activeLink = location.pathname.substring(1);
  const subscriptionLock = useSelector(
    (state: RootState) => state.auth.authorization.subscriptionLock
  );
  const viewsArray = useSelector(
    (state: RootState) => state.user.merchant.views
  );
  const runInitOnFirstLoad = useSelector(
    (state: RootState) => state.app.runInitOnFirstLoad
  );
  const isProductWizardOpen = useSelector((state: RootState) =>
    state.user.shop?.isProductWizardOpen
      ? state.user.shop?.isProductWizardOpen
      : false
  );
  const currentPath = window.location.pathname;

  /* const [isWizardPopupOpen, setIsWizardPopupOpen] = useState(true); */
  const toggleWizardModal = () => {
    dispatch(setRunInitOnFirstLoad(false));
    dispatch(setIsProductWizardOpen(false));
    // dispatch(setIsProductWizardOpen(!isProductWizardOpen));
  };

  useEffect(() => {
    if (runInitOnFirstLoad) {
      fetchAppInit();
    }
  }, [runInitOnFirstLoad]);

  useEffect(() => {
    if (subscriptionLock && subscriptionLock === 'true') {
      navigate('/settings/subscription');
    }
  }, [currentPath, subscriptionLock]);

  useEffect(() => {
    const timmer = setTimeout(() => {
      dispatch(setIsOnboardingCompleteBannerOpen(false));
      clearTimeout(timmer);
    }, 2000);
  }, []);

  const shopPageContent = () => {
    return (
      <div className='shopPage'>
        {subscriptionLock && subscriptionLock === 'pending' && (
          <div className='subPageInnerContentHolder__content'>
            <LoadingSpinner />
          </div>
        )}

        {!subscriptionLock ||
          (subscriptionLock !== 'pending' && (
            <>
              {/* <ProfileView
                pic={
                  userCompany?.settings?.company_logo &&
                  userCompany?.settings?.company_logo !== '' &&
                  userCompany?.settings?.company_logo !== null
                    ? `${staticURL}/medium/${userCompany?.settings?.company_logo}`
                    : dummyImage
                }
                title={isInitSuccess ? userCompany?.name : ''}
                description={''}
              /> */}
              <div className='shopPage__container1'>
                {!viewsArray.includes('CMS') && (
                  <CategoryCard
                    icon={cmsSVG}
                    title={'CMS'}
                    subTitle={t('ManageCMS')}
                    link='cms/connection'
                  />
                )}
                {!viewsArray.includes('PSP') && (
                  <CategoryCard
                    icon={pspSvg}
                    title={t('Payments')}
                    subTitle={t('ManagePSP')}
                    link='psp/connection'
                  />
                )}

                {/* <InfoCard heading='Connection' link='/shop/webshop' />
                  <InfoCard heading='Sync Status' link='/shop' /> */}
              </div>
            </>
          ))}
        <Popup setTrigger={toggleWizardModal} trigger={isProductWizardOpen}>
          <ProductWizard />
        </Popup>
      </div>
    );
  };
  return (
    <PageWrapper>
      {activeLink === 'shop' && shopPageContent()}
      <div className='subPage'>
        <SubPageContentHolder>
          <Routes>
            <Route
              path='cms/*'
              element={
                !viewsArray.includes('CMS') ? <CMS /> : <Navigate to={'/'} />
              }
            />
            <Route
              path='psp/*'
              element={
                !viewsArray.includes('PSP') ? <PSP /> : <Navigate to={'/'} />
              }
            />
            {/*  <Route path='webshop/*' element={<Webshop />} /> */}
          </Routes>
        </SubPageContentHolder>
      </div>
    </PageWrapper>
  );
};

export default ShopPage;
