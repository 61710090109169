import TopFloatLogo from '../generic/top-float-logo';
import OnboardingNavProgressBar from './OnboardingNavProgressBar';
import '../../styles/components/onboarding/onboardingNavbar.scss';
import OnboardingNavbarDropdown from './OnboardingNavbarDropdown';
const OnboardingNavbar = () => {
  return (
    <div className='header onboardingNavbar'>
      <div className='onboardingNavbar__top'>
        <TopFloatLogo />
        <OnboardingNavbarDropdown />
      </div>
      <OnboardingNavProgressBar />
    </div>
  );
};

export default OnboardingNavbar;
