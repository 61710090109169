import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
  authorization: {
    accessToken: {
      sellerToken: '',
      merchantToken: '',
      adminToken: '',
      type: 'Bearer',
      expiresOn: null as any,
      whiteLabelSellerTokens: {} as any,
      currentWhiteLabelsellerToken: '',
    },
    subscriptionLock: 'pending',
    mollieOauthRedirectURL: '',
  },
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccessToken: (
      state,
      action: PayloadAction<{
        sellerToken: any;
        merchantToken: any;
        adminToken?: any;
        type: string;
        expiresOn: any;
        whiteLabelSellerTokens?: any;
        currentWhiteLabelsellerToken?: any;
      }>
    ) => {
      state.authorization.accessToken.sellerToken =
        action.payload.sellerToken ?? undefined;
      state.authorization.accessToken.adminToken =
        action.payload.adminToken ?? undefined;
      state.authorization.accessToken.merchantToken =
        action.payload.merchantToken ?? undefined;
      state.authorization.accessToken.type = action.payload.type;
      state.authorization.accessToken.expiresOn = action.payload.expiresOn;
      state.authorization.accessToken.whiteLabelSellerTokens =
        action.payload?.whiteLabelSellerTokens;
      state.authorization.accessToken.currentWhiteLabelsellerToken =
        action.payload?.currentWhiteLabelsellerToken;
    },

    clearAuthSlice: (state) => {
      state.authorization = {
        accessToken: {
          sellerToken: '',
          merchantToken: '',
          adminToken: '',
          type: 'Bearer',
          expiresOn: null,
          whiteLabelSellerTokens: {},
          currentWhiteLabelsellerToken: '',
        },
        subscriptionLock: 'pending',
        mollieOauthRedirectURL: '',
      };
    },

    setSubscriptionLock: (state, action) => {
      state.authorization.subscriptionLock = action.payload;
    },

    setMollieOauthRedirectURL: (state, action) => {
      state.authorization.mollieOauthRedirectURL = action.payload;
    },
  },
});

export const authReducer = authSlice.reducer;

export const {
  setAccessToken,
  clearAuthSlice,
  setSubscriptionLock,
  setMollieOauthRedirectURL,
} = authSlice.actions;
