import { Route, Routes } from 'react-router-dom';
import AllShops from './AllShops';

const AdminShopsPage = () => {
  return (
    <Routes>
      <Route path='/*' element={<AllShops />} />
    </Routes>
  );
};

export default AdminShopsPage;
