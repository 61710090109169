import {
  setStageComplete,
  updateOnboardingStage,
} from '../../store/reducers/userSlice';
import store from '../../store/store';
import { stepsAndStagesMap } from '../constants/steps_stages_map';

export function authenticateSeller(seller: any) {
  const authToken = store.getState().auth.authorization.accessToken;

  if (authToken.sellerToken) {
    // if an auth token exists, the user can be logged in/ taken to the last screen they used
    // if (seller?.onboarding_completed) {
    if (true) {
      // return '/dashboard';
      return '/shop';
    } else {
      //go to seller's last onboarding step
      if (seller?.onboarding) {
        const { current_step, steps, data, completed_steps } =
          seller.onboarding;

        store.dispatch(
          setStageComplete({
            stage: stepsAndStagesMap[current_step].name,
            isCompleted: false,
          })
        );
        store.dispatch(
          updateOnboardingStage(stepsAndStagesMap[current_step].name)
        );
        return '/onboarding';
      }
    }
  }
  return '/login';
}
