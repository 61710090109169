import { AnimatePresence } from 'framer-motion';
import React from 'react';
import TopFloatLogo from '../generic/top-float-logo';
import { motion } from 'framer-motion';
import OnboardingNavbar from '../onboarding-header/OnboardingNavbar';

const OnboardingWrapper: React.FC = ({ children }) => {
  return (
    <div className='onboarding-wrapper-container relative '>
      <OnboardingNavbar />
      <AnimatePresence
        key='onboarding-flow-animate-presence'
        exitBeforeEnter
        initial={false}
      >
        {children}
      </AnimatePresence>
    </div>
  );
};

export default OnboardingWrapper;
