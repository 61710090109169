import { useEffect, useState } from 'react';
import '../../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../../ui/inputs/NormalInput';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../../lib/utils/body-builder';
import { PSP_ENDPOINT } from '../../../../lib/constants/urls';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../../lib/api/async-handler';
import store from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import NormalSelectInput from '../../../../ui/inputs/NormalSelectInput';
import { useNavigate } from 'react-router-dom';
import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';
import { updateIntercom } from '../../../../store/reducers/userSlice';
import { useTranslation } from 'react-i18next';
import createSelectOptionValueObject from '../../../../lib/helper-functions/createSelectOptionValueObject';
import getOnlyVisiblePSPs from '../../../../lib/helper-functions/getOnlyVisiblePSPs';
import makeFirstLetterCapital from '../../../../lib/helper-functions/makeFirstLetterCapital';
import '../../../../styles/generic/normalForm.scss';

export interface PaymentMethodsInterface {
  value: string;
  label: string;
  isSelected: boolean | undefined;
}

const AddPSP = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shopId = useSelector((state: RootState) => state.user.company.shop_id);
  const { t } = useTranslation();
  const paymentMethods = useSelector(
    (state: RootState) => state.user.onboarding.paymentMethods
  );
  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const options = getOnlyVisiblePSPs(onboarding_object);

  const pspOptions: any = createSelectOptionValueObject(options);

  const [pspInputs, setPSPInputs] = useState([]);
  const [pspInputValues, setPSPInputValues] = useState<any>();
  const [selectedPSP, setSelectedPSP] = useState(options[0]);
  const [selectedPaymentMethods, setSelectedPaymentMethods] =
    useState<PaymentMethodsInterface[]>();
  const [visiblePaymentMethods, setVisiblePaymentMethods] =
    useState<JSX.Element>();
  const [paymentMethodClickChanges, setPaymentMethodClickChanges] = useState(0);
  const tempItems: JSX.Element[] = [];

  useEffect(() => {
    let finalArray: PaymentMethodsInterface[] = [];
    if (selectedPSP) {
      let data: any = paymentMethods?.[selectedPSP];
      for (const key in data) {
        let method = {
          value: key,
          label: data?.[key]?.label,
          isSelected: false,
        };
        finalArray.push(method);
      }
    }
    setSelectedPaymentMethods(finalArray);
  }, [selectedPSP]);

  if (selectedPaymentMethods) {
    for (let method of selectedPaymentMethods) {
      let updatedValues = selectedPaymentMethods;
      let isSelected =
        selectedPaymentMethods[selectedPaymentMethods.indexOf(method)]
          .isSelected ?? false;
      const onClickHandler = (e: any) => {
        updatedValues[updatedValues.indexOf(method)] = {
          ...method,
          isSelected: e,
        };
        setSelectedPaymentMethods(updatedValues);
        isSelected = e;
        setPaymentMethodClickChanges(paymentMethodClickChanges + 1);
      };
      tempItems.push(
        <div className='normalForm__switch'>
          <ToggleSwitch
            id={selectedPaymentMethods.indexOf(method)}
            isOn={isSelected}
            title={method?.label}
            onClick={onClickHandler}
            classes='margin-top-2rem'
          />
        </div>
      );
    }
  }

  useEffect(() => {
    if (tempItems) {
      setVisiblePaymentMethods(tempItems as any);
    }
  }, [paymentMethodClickChanges, selectedPaymentMethods]);

  /* ================================================================================= */
  useEffect(() => {
    const currentPSP = onboarding_object?.available_psp?.filter((el: any) => {
      const currentPSP_Obj: any = Object.values(el)[0];
      const currentPSP_Obj_label = currentPSP_Obj?.label?.toLowerCase();
      return currentPSP_Obj_label === selectedPSP ? currentPSP_Obj : null;
    });

    if (currentPSP?.length > 0) {
      const temp: any = Object.values(currentPSP)[0];
      const found_PSP: any = Object.values(temp)[0];
      const currentPSP_inputs = found_PSP?.connection_method?.manual;
      if (currentPSP_inputs?.length > 0) {
        let temp_inputs: any = [];
        let temp_input_values: any = {};
        for (let i = 0; i < currentPSP_inputs[0]?.length; i++) {
          const temp = currentPSP_inputs[0][i]?.label;
          temp_inputs.push(temp);
          temp_input_values[temp] = '';
        }

        setPSPInputs(temp_inputs);
        setPSPInputValues(temp_input_values);
      }
    }
  }, [selectedPSP]);
  /* ================================================================================= */

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    try {
      const accessToken = store.getState().auth.authorization.accessToken;

      const sendingData = {
        psp_module: selectedPSP,
        shop_id: shopId,
        payment_details: JSON.stringify(pspInputValues),
        payment_method: selectedPaymentMethods
          ?.filter((el) => {
            return el.isSelected === true;
          })
          .map(({ value }) => value)
          .join(','),
      };

      const requestInfo: RequestInfo = {
        url: PSP_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + accessToken.sellerToken,
        },
      };

      const actionType = 'PSP_CONNECTION';
      const customMsg = t('PSP-ConnectionSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        dispatch(
          updateIntercom({
            psp: selectedPSP,
            pspdatafilled: 'true',
          })
        );
        navigate('/shop/psp/connection');
      }
    } catch (exception) {
      console.log('Error during authentication request');
    }
  }

  let isDisabled;

  if (pspInputValues) {
    isDisabled = Object.values(pspInputValues)?.some((el: any) => el === '');
  }

  const onInputChange = (e: any, value: string) => {
    setPSPInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  return (
    <form id='PSPAdd-form' onSubmit={onSubmitHandler} className='normalForm'>
      <div className='normalForm__top'>
        <div className='normalForm__container3'>
          <div className='normalForm__container3--item'>
            <div className='normalForm__container3--item-content'>
              <NormalSelectInput
                defaultValue={selectedPSP}
                setSelect={setSelectedPSP}
                selectOptionArr={pspOptions}
                placeholder={t('PSP-Type')}
                id={'selected-psp-add'}
              />
            </div>
          </div>
          {pspInputs.map((el: string, index: number) => {
            return (
              <div className='normalForm__container3--item' key={index}>
                <div className='normalForm__container3--item-content'>
                  <NormalInput
                    changeListeners={[(e) => onInputChange(e, el)]}
                    id={`psp-integration-${el}-add`}
                    value={pspInputValues ? pspInputValues[el] : ''}
                    placeholder={makeFirstLetterCapital(
                      el.replaceAll('_', ' ')
                    )}
                    required={true}
                    autoFocus={index === 0 ? true : false}
                    classes={`add-psp-${selectedPSP}-${el}-input`}
                    labelClasses={`add-psp-${selectedPSP}-${el}-label`}
                  />
                </div>
              </div>
            );
          })}
          <div className='normalForm__switches'>{visiblePaymentMethods}</div>
        </div>
      </div>

      <div className='normalForm__bottom'>
        <MainSquareButton
          value={t('Add')}
          /* icon={<CustomSVGs svg={plusSignSVG} />} */
          disabled={isDisabled}
          type='submit'
          form='PSPAdd-form'
        />
      </div>
    </form>
  );
};

export default AddPSP;
