import { motion } from 'framer-motion';
import store from '../../store/store';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import { useEffect, useState } from 'react';
import '../../styles/pages/signup.scss';
import { useNavigate } from 'react-router-dom';
import { FORGOT_PASSWORD_ENDPOINT } from '../../lib/constants/urls';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import lottieBanner from '../../assets/json/lottie_animation/stopwatch_1.json';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import LockSVG from '../../ui/customSVGs/LockSVG';
import LoginInput from '../../ui/inputs/LoginInput';
import { errorSVG } from '../../lib/allCustomSVGs';
import MessageBanner from '../../components/messageBanner/MessageBanner';
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import { fetchAppInit } from '../../lib/api/app-init';
import { setMessage } from '../../store/reducers/appSlice';
import { setAccessToken } from '../../store/reducers/authSlice';
import moment from 'moment';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import RenderLoginPageLogo from '../login/RenderLoginPageLogo';

const resetId = window.location?.search?.slice(4);

/* const lessThanOneHourAgo = (date: any) => {
  const HOUR = 1000 * 60 * 60;
  const anHourAgo = Date.now() - HOUR;

  return date > anHourAgo;
}; */

const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const authToken = store.getState().auth.authorization.accessToken;
  const [password, setPassword] = useState('');
  const [verifyPassword, setVerifyPassword] = useState('');
  const [isResetValid, setIsResetValid] = useState('pending');
  const [email, setEmail] = useState('');

  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);

  const isDisabled =
    email === '' ||
    password === '' ||
    verifyPassword === '' ||
    verifyPassword !== password
      ? true
      : false;

  useEffect(() => {
    async function validatingRequest() {
      try {
        const requestInfo: RequestInfo = {
          url: `${FORGOT_PASSWORD_ENDPOINT}/${resetId}`,
          method: 'GET',
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/x-www-form-urlencoded',
          },
        };

        const actionType = 'RESET_PASSWORD_GET';
        const customMsg = t('PasswordResetSuccess-msg');

        const res: any = await asyncHandler(
          requestInfo,
          actionType,
          customMsg,
          false
        );

        const response = res.data;
        if (res.actionType === actionType && response.success) {
          setEmail(response?.result?.email);
          setIsResetValid('success');
        } else {
          setIsResetValid('error');
        }
      } catch (exception) {
        console.log('Error during authentication request');
      }
    }

    validatingRequest();
  }, []);
  console.log(email);
  async function onSubmitHandler(e: any) {
    e.preventDefault();
    try {
      const requestInfo: RequestInfo = {
        url: `${FORGOT_PASSWORD_ENDPOINT}/${resetId}`,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          email: email,
          password: password,
          password_confirmation: verifyPassword,
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
        },
      };

      const actionType = 'RESET_PASSWORD_POST';
      const customMsg = t('PasswordResetSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        /* ========LOGIN IF RESET IS SUCCESSFUL========== */
        const token_type = 'Bearer';

        const { auth_key, user } = response?.result;

        if (user?.assignedRole === 'seller') {
          if (auth_key && auth_key !== '') {
            store.dispatch(
              setAccessToken({
                sellerToken: auth_key,
                merchantToken: undefined,
                adminToken: undefined,
                type: token_type,
                expiresOn: moment().add(1, 'days'),
              })
            );
          }

          store.dispatch(
            setMessage({
              message: 'Seller Login Successful',
              messageType: 'success',
            })
          );
          navigate('/shop');
        } else if (user?.assignedRole === 'admin') {
          if (auth_key && auth_key !== '') {
            store.dispatch(
              setAccessToken({
                sellerToken: undefined,
                merchantToken: undefined,
                adminToken: auth_key,
                type: token_type,
                expiresOn: moment().add(1, 'days'),
              })
            );
          }
          store.dispatch(
            setMessage({
              message: 'Admin Login Successful',
              messageType: 'success',
            })
          );
          navigate('/admin');
        }

        //make init call to get all info about logged-In user
        fetchAppInit();

        /* ========================= */
      }
    } catch (exception) {
      console.log('Error during authentication request');
    }
  }

  if (!authToken.sellerToken && !authToken.adminToken) {
    return (
      <>
        {isResetValid === 'pending' && (
          <div className='flex-row' style={{ width: '100%', height: '100vh' }}>
            <LoadingSpinner />
          </div>
        )}
        {isResetValid === 'error' && (
          <MessageBanner
            title='Reset Token Expired'
            subTitle='Reset Token Expired! Go back and get a new reset token.'
            icon={errorSVG}
            btnTitle='Go back'
            onSelect={() => {
              navigate('/forgot-password');
            }}
            success={false}
          />
        )}
        {isResetValid === 'success' && (
          <motion.div {...pageSlideOutForward} className='signup'>
            <form onSubmit={onSubmitHandler} className='signup__left'>
              <div className='signup__logo'>
                <RenderLoginPageLogo
                  staticURL={staticURL}
                  merchantTheme={merchantTheme}
                  isDarkMode={isDarkMode}
                />
              </div>
              <div className='signup__left--top'>
                <h1>{t('ResetPasswordMainText')}</h1>
                <p>{t('ResetPasswordSubText')}</p>
              </div>
              <div className='signup__left--center'>
                <LoginInput
                  icon={<LockSVG fill='#cecece' />}
                  id='reset-password-form-password'
                  value={password}
                  type='password'
                  changeListeners={[(e) => setPassword(e.target.value)]}
                  focusListeners={[]}
                  placeholder={t('Password')}
                  required={true}
                  pattern='[^ ]{8,20}'
                  title={t('Password-validation')}
                />
                <LoginInput
                  icon={<LockSVG fill='#cecece' />}
                  id='reset-password-form-verify-password'
                  value={verifyPassword}
                  type='password'
                  changeListeners={[(e) => setVerifyPassword(e.target.value)]}
                  focusListeners={[]}
                  placeholder={t('Verify Password')}
                  required={true}
                  pattern='[^ ]{8,20}'
                  title={t('Password-validation')}
                />
              </div>
              <div className='signup__left--bottom'>
                <MainSquareButton
                  value={t('Continue')}
                  type='submit'
                  disabled={isDisabled}
                  classes='mainSquareButton-extra-1'
                />
                <h2>
                  <span onClick={() => navigate('/forgot-password')}>
                    {t('ForgotPassword')}
                  </span>
                </h2>
              </div>
            </form>

            <div className='signup__right'>
              {/* <img src={bg} alt='signin-background' /> */}
              <div className='signup__right-lottie'>
                <Lottie animationData={lottieBanner} loop={true} />
              </div>
            </div>
          </motion.div>
        )}
      </>
    );
  } else {
    window.location.assign('/');
    return null;
  }
};

export default ResetPassword;
