import { useNavigate } from 'react-router-dom';
import { warningWithCircleSVG } from '../../../../lib/allCustomSVGs';
import '../../../../styles/components/subPageList/subPageListDelete.scss';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import store, { RootState } from '../../../../store/store';
import { useEffect, useState } from 'react';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { PSP_ENDPOINT } from '../../../../lib/constants/urls';
import { asyncHandler } from '../../../../lib/api/async-handler';
import { useTranslation } from 'react-i18next';

const DeletePSP = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pspId } = useParams();
  const alreadyAddedPSP = useSelector(
    (state: RootState) => state.user.company.alreadyAddedPSP
  ) as any;
  const [deletingPSP, setDeletingPSP] = useState<any>([]);
  useEffect(() => {
    if (pspId) {
      setDeletingPSP(alreadyAddedPSP[pspId]);
    }
  }, [pspId]);

  const deleteHandler = async () => {
    try {
      const accessToken = store.getState().auth.authorization.accessToken;
      const params = `?psp_name=${deletingPSP?.gateway_name}`;
      const requestInfo: RequestInfo = {
        url: PSP_ENDPOINT + params,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + accessToken.sellerToken,
        },
      };

      const actionType = 'PSP_CONNECTION';
      const customMsg = `PSP : ${deletingPSP?.gateway_name} ${t(
        'PSP-ConnectionDeleteSuccess-msg'
      )}.`;

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        navigate('/shop/psp/connection');
      }
    } catch (exception) {
      console.log('Error during authentication request');
    }
  };

  return (
    <div className='subPageListDelete'>
      <CustomSVGs svg={warningWithCircleSVG} />
      <h1>{t('AskToDelete-msg')}</h1>
      <h1>{`${deletingPSP?.label} PSP ?`}</h1>
      <div className='subPageListDelete__buttons'>
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            deleteHandler();
          }}
          type={'button'}
          value={t('Yes')}
          normal={true}
          buttonType={'danger'}
        />
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            navigate('/shop/psp/connection');
          }}
          type={'button'}
          value={t('No')}
          normal={true}
          buttonType={'light'}
        />
      </div>
    </div>
  );
};

export default DeletePSP;
