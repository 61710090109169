import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import WooCommerce from './WooCommerce/WooCommerce';
import Magento from './Magento/Magento';
// import Lightspeed from './Lightspeed/Lightspeed';
import CustomFastcheckout from './custom/CustomFastcheckout';
import { useEffect } from 'react';
import Opencart from './Opencart/Opencart';
import Prestashop from './Prestashop/Prestashop';

const Fastcheckout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/integrations/fastcheckout/woocommerce');
  }, []);

  return (
    <Routes>
      <Route path='woocommerce/*' element={<WooCommerce />} />
      <Route path='magento/*' element={<Magento />} />
      <Route path='opencart/*' element={<Opencart />} />
      <Route path='prestashop/*' element={<Prestashop />} />
      {/* <Route path='lightspeed/*' element={<Lightspeed />} /> */}
      <Route path='custom/*' element={<CustomFastcheckout />} />
    </Routes>
  );
};

export default Fastcheckout;
