import '../../styles/pages/shop/shopPage.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import PageWrapper from '../../components/page-wrappers/PageWrapper';
import Fastcheckout from './Fastcheckout/Fastcheckout';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import {
  autofillSVG,
  fastcheckoutSVG,
  socialCommerceSVG,
} from '../../lib/allCustomSVGs';
import CategoryCard from '../../ui/cards/CategoryCard';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
const IntegrationsPage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const activeLink = location.pathname.substring(1);
  const merchantName = useSelector(
    (state: RootState) => state.user?.merchant?.config?.merchant_name
  );

  const integrationsPageContent = () => {
    return (
      <div className='shopPage'>
        <div className='shopPage__container1'>
          {/* //? disabled until pay is integrated */}
          {merchantName !== 'Pay.' && (
            <CategoryCard
              icon={fastcheckoutSVG}
              title={t('FastCheckout')}
              subTitle={t('ManageFastCheckout')}
              link='/integrations/fastcheckout'
            />
          )}
          {/* <CategoryCard
            icon={socialCommerceSVG}
            title={t('SocialCommerce')}
            subTitle={t('ManageSocialCommerce')}
            link='#'
            onSelect={() => {}}
            //  link='/shop?product_tour_id=396720'
            // onSelect={() => {
            //   window.open(
            //     `${window.location.origin}/shop?product_tour_id=396720`,
            //     '_self'
            //   );
            // }}
          /> */}
          {/* {merchantName !== 'Pay.' && (
            <CategoryCard
              icon={autofillSVG}
              title={t('Autofill')}
              subTitle={t('ManageAutofill')}
              link='/integrations/autofill'
            />
          )} */}
        </div>
      </div>
    );
  };

  return (
    <PageWrapper>
      {activeLink === 'integrations' && integrationsPageContent()}
      <div className='subPage'>
        <SubPageContentHolder>
          <Routes>
            <Route path='fastcheckout/*' element={<Fastcheckout />} />
            {/* <Route path='autofill/*' element={<Autofill />} /> */}
          </Routes>
        </SubPageContentHolder>
      </div>
    </PageWrapper>
  );
};

export default IntegrationsPage;
