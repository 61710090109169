import '../src/styles/root/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store, { persistor } from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { HistoryRouter } from './components/routers/HistoryRouter';
import history from './history';
import './internationalization/i18n';

// * disabling console.logs & debuggers in LIVE instance
if (
  !(
    process.env.NODE_ENV === 'development' ||
    window.location.href.split('/')[2].includes('dev')
  )
) {
  console.log = () => {};
  console.debug = () => {};
}

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <HistoryRouter history={history}>
        <App />
      </HistoryRouter>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
