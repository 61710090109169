export default function getOnlyVisiblePSPs(onboarding_object: any) {
  const visiblePSPs = [];
  const pspArr = onboarding_object?.available_psp;
  for (let i = 0; i < pspArr?.length; i++) {
    const pspValues: any = Object.values(pspArr[i]);
    if (pspValues[0]?.visible) {
      visiblePSPs.push(pspValues[0]?.label?.toLowerCase());
    }
  }
  return visiblePSPs;
}
