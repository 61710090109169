import { motion } from 'framer-motion';
import { useState } from 'react';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import {
  OnboardingStagesMap,
  setStageComplete,
  setUserCompanyDetails,
  updateOnboardingStage,
} from '../../store/reducers/userSlice';
import './onboarding-page.css';
import '../../styles/components/onboarding/enterCompanyDetails.scss';
import NormalInput from '../../ui/inputs/NormalInput';
import MainPurpleButton from '../../ui/buttons/MainPurpleButton';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import {
  MOLLIE_OAUTH_ENDPOINT,
  ONBOARDING_ENDPOINT,
} from '../../lib/constants/urls';
import { useDispatch, useSelector } from 'react-redux';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import store, { RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { setMollieOauthRedirectURL } from '../../store/reducers/authSlice';

const EnterCompanyDetails = () => {
  /* const IPCountry = useSelector(
    (state: RootState) => state.app.countries.countryOfIP
  );
  const countries = useSelector(
    (state: RootState) => state.app.countries.available
  ); */
  const { t } = useTranslation();
  const currencyArr = [
    { OptionValue: 'EUR', OptionName: 'Euro' },
    { OptionValue: 'USD', OptionName: 'US Dollar' },
  ];
  const [currency, setCurrency] = useState<string>('EUR');

  const [shopName, setShopName] = useState<string>('');

  const dispatch = useDispatch();
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  /* const [errors, setErrors] = useState<any>(); */

  async function submitCompany(e: any) {
    e.preventDefault();
    try {
      /* const response = await sendCompanyDetails(shopName, currency, 'add_shop'); */
      const accessToken = store.getState().auth.authorization.accessToken;

      /*  */
      const requestInfo: RequestInfo = {
        url: ONBOARDING_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder({
          onboarding_data: JSON.stringify({
            onboard_type: 'add_shop',
            name: shopName,
            currency: currency,
          }),
        }),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + accessToken.sellerToken,
        },
      };

      const actionType = 'ONBOARDING_ADD_COMPANY';
      const customMsg = 'Company Added Successfully.';

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        const shop_id = response.result[0].id.toString();
        const shop_slug = response.result[0].shop_slug;

        dispatch(
          updateOnboardingStage(OnboardingStagesMap.IntegratingCMS1.name)
        );
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.SubmittingCompany.name,
            isCompleted: true,
          })
        );

        dispatch(
          setUserCompanyDetails({
            name: shopName,
            currency,
            shop_id,
            shop_slug,
          })
        );

        getMollieOauthURL(accessToken.sellerToken);
      } else {
        /* setErrors(response.message);
        onFormSubmitedFailed(response.message); */
        /*  setErrors(generateErrorMessage(response));
        onFormSubmitedFailed(generateErrorMessage(response)); */
      }
    } catch (exception) {
      console.log('Error during authentication request');
    }
  }

  const isDisabled = shopName === '' ? true : false;

  async function getMollieOauthURL(auth_key: string) {
    try {
      const requestInfo: RequestInfo = {
        url: MOLLIE_OAUTH_ENDPOINT,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: 'Bearer' + ' ' + auth_key,
        },
      };

      const res: any = await fetch(requestInfo.url, {
        method: requestInfo.method,
        headers: requestInfo.headers,
      });

      const response = await res.json();
      if (response.success && response.result?.auth_url) {
        store.dispatch(setMollieOauthRedirectURL(response.result?.auth_url));
      }
    } catch (exception) {
      console.log('Error during getMollieOauthURL request');
    }
  }

  return (
    <>
      <motion.div
        {...pageSlideOutForward}
        key='onboarding-company-details-page'
        className='enterCompanyDetails animate-fade_in_from_right'
      >
        <div className='enterCompanyDetails__top'></div>
        <div className='enterCompanyDetails__container'>
          <div className='enterCompanyDetails__container--top'>
            <h1>{t('OnboardingCompanyDetailsH1')}</h1>
            <h2>{t('OnboardingCompanyDetailsH2')}</h2>
          </div>

          <form
            onSubmit={submitCompany}
            id='onboarding-companyDetails'
            className='enterCompanyDetails__container--center'
          >
            {/* <NormalInput
            changeListeners={[(e) => setCompanyName(e.target.value)]}
            id='company-details-company-name'
            value={companyName}
            placeholder='Company Name'
          /> */}

            <NormalInput
              id='company-details-shopname'
              value={shopName}
              changeListeners={[(e) => setShopName(e.target.value)]}
              focusListeners={[]}
              placeholder={t('ShopName')}
              classes='lg:w-[13rem]'
              required={true}
              /* pattern='^[A-Za-z0-9 ]{1,100}$' */
              title={t('ShopName-validation')}
              autoFocus={true}
            />

            {/* <NormalSelectInput
              defaultValue={currency}
              setSelect={setCurrency}
              selectOptionArr={currencyArr}
              placeholder={t('Currency')}
              id={'company-details-currency'}
            /> */}

            {/* <p className='onboarding-error'>{errors}</p> */}
          </form>
          <div className='enterCompanyDetails__container--bottom'>
            <MainPurpleButton
              value={t('Continue')}
              disabled={isDisabled}
              type='submit'
              form='onboarding-companyDetails'
              /* classes=' w-[120px] rounded-full h-12 text-white text-[12.6px]' */
            />
          </div>

          <div className='enterCompanyDetails__container--footer'>
            <a target={'_blank'} href={`${merchantTheme?.terms_url}`}>
              {t('Terms')}
            </a>
            {` & `}
            <a target={'_blank'} href={`${merchantTheme?.privacy_url}`}>
              {t('Privacy')}
            </a>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default EnterCompanyDetails;
