import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import lottieBanner from '../../assets/json/lottie_animation/preparing.json';
import '../../styles/banners/onboardingCompleteBanner.scss';

interface Props {
  styles?: any;
}

const OnboardingCompleteBanner = ({ styles }: Props) => {
  const { t } = useTranslation();
  return (
    <motion.div
      exit={{ opacity: 0.5 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      style={styles}
      className='onboardingCompleteBanner'
    >
      <div className='onboardingCompleteBanner__content '>
        <div className='onboardingCompleteBanner__content--text'>
          <h1>{t('PreparingDashboard')}</h1>
          <h2>{t('PleaseWait')}</h2>
        </div>
        <div className='onboardingCompleteBanner__content--animation'>
          <Lottie animationData={lottieBanner} loop={true} />
        </div>
      </div>
    </motion.div>
  );
};

export default OnboardingCompleteBanner;
