import React from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/components/pageWrappers/pageWrapper.scss';
import Footer from '../footer/Footer';
import Sidebar2 from '../sidebar/Sidebar2';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import {
  sellerAllSidebarLinks,
  merchantAllSidebarLinks,
  adminAllSidebarLinks,
} from '../../lib/constants/sidebarLinks';

const PageWrapper: React.FC = ({ children }) => {
  const { t } = useTranslation();
  const isSidebarDrawerOpen = useSelector(
    (state: RootState) => state.UI.isSidebarDrawerOpen
  );
  const { is_admin, is_merchant } = useSelector(
    (state: RootState) => state.user.profile
  );

  // console.log(sellerAllSidebarLinks);

  return (
    <div className='pageWrapper--outer'>
      <div className='pageWrapper'>
        <div className='pageWrapper__content'>
          <div
            data-is-sidebar-open={isSidebarDrawerOpen?.toString()}
            className='pageWrapper__content--sidebar'
          >
            <Sidebar2
              mainSubTitle={t('Autofill')}
              items={
                is_admin
                  ? adminAllSidebarLinks
                  : is_merchant
                  ? merchantAllSidebarLinks
                  : sellerAllSidebarLinks
              }
            />
          </div>
          <div className='pageWrapper__content--page'>{children}</div>
        </div>
      </div>
      <div className='pageWrapper--footer'>
        <Footer />
      </div>
    </div>
  );
};

export default PageWrapper;
