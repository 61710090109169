import store from '../../store/store';
import { LOGOUT_ENDPOINT } from '../constants/urls';

export async function tokenRevokeAtLogOutUser() {
  try {
    const authToken = store.getState().auth.authorization.accessToken;

    if (authToken?.sellerToken && authToken.sellerToken !== '') {
      const response = await fetch(LOGOUT_ENDPOINT, {
        method: 'GET',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: authToken.type + ' ' + authToken?.sellerToken,
        },
      });
      const res = await response.json();
      console.log('sellerToken revoked', res);
    }
    if (authToken?.adminToken && authToken.adminToken !== '') {
      const response = await fetch(LOGOUT_ENDPOINT, {
        method: 'GET',
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: authToken.type + ' ' + authToken?.adminToken,
        },
      });
      const res = await response.json();
      console.log('adminToken revoked', res);
    }
  } catch (exception) {
    console.log('Error while revoking token', exception);
  }
}
