import '../../styles/pages/thankYou/stripeSubscriptionFailed.scss';
import { errorSVG } from '../../lib/allCustomSVGs';
import MessageBanner from '../../components/messageBanner/MessageBanner';
import { useTranslation } from 'react-i18next';

const StripeSubscriptionFailed = () => {
  const { t } = useTranslation();
  return (
    <>
      <MessageBanner
        title={t('StripeSubscriptionFailMainText')}
        subTitle={t('StripeSubscriptionFailSubText')}
        icon={errorSVG}
        btnTitle={t('GoBack')}
        onSelect={() => {
          window.opener.location.reload();
          window.close();
        }}
        success={false}
      />
    </>
  );
};

export default StripeSubscriptionFailed;
