import { useSelector } from 'react-redux';
import '../../styles/components/footer/footer.scss';
import { RootState } from '../../store/store';

const Footer = () => {
  const merchantName = useSelector(
    (state: RootState) => state.user.merchant.config.merchant_name
  );
  return (
    <div className='footer1'>
      <div className='footer1__top'></div>
      <div className='footer1__bottom'>
        <div className='footer1__bottom--links'>
          {/* <a href='#'>Help</a>
          <h2>|</h2>
          <a href='#'>Privacy policy</a> */}
        </div>
        <h2>{`${merchantName} 2023`} &#169;</h2>
      </div>
    </div>
  );
};

export default Footer;
