/**
 * Recursively converts keys of an object and nested objects to lowercase.
 *
 * @param obj - The input object to process.
 * @returns A new object with all keys converted to lowercase.
 */
export default function convertKeysToLowerCase(obj: any): any {
  // Check if the input is not an object or is null, return it as is.
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  // If the input is an array, recursively call the function on its items.
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToLowerCase(item));
  }

  // Initialize a new object to store the converted keys.
  const newObj: any = {};

  // Iterate over the keys of the input object.
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      // Convert the key to lowercase.
      const newKey = key.toLowerCase();

      // Recursively call the function on the value associated with the key.
      newObj[newKey] = convertKeysToLowerCase(obj[key]);
    }
  }

  // Return the new object with lowercase keys.
  return newObj;
}
