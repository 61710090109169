import { downloadSVG, userManualSVG } from '../../../../lib/allCustomSVGs';
import '../../../../styles/pages/shop/autofill/autofill.scss';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';
import CategoryCard from '../../../../ui/cards/CategoryCard';
import { useTranslation } from 'react-i18next';
import store from '../../../../store/store';

const Opencart = () => {
  let subTitle = '';
  const { t } = useTranslation();
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;

  return (
    <SubPageInnerContentHolder
      title='OpenCart'
      subTitle={subTitle}
      backBtnLink={isWhiteLabel ? undefined : '/integrations'}
      mainBtnIcon={undefined}
      mainBtnTitle={undefined}
      mainBtnLink={undefined}
    >
      <div className='shopPage__container1'>
        <CategoryCard
          icon={downloadSVG}
          title={''}
          subTitle={t('DownloadPlugin')}
          link=''
          onSelect={() => {
            window.open(
              `https://downloads.adup.io/ai-fastcheckout-opencart/latest.zip`,
              '_self'
            );
          }}
        />
      </div>
    </SubPageInnerContentHolder>
  );
};

export default Opencart;
