import '../../styles/pages/orders/ordersPage.scss';
import { useEffect, useState } from 'react';

//api call imports
import { ORDERS_ENDPOINT } from '../../lib/constants/urls';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import store from '../../store/store';
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../ui/noData/NoData';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
import { useNavigate } from 'react-router-dom';
import CustomDataGrid from '../../ui/customDataGrid.tsx/CustomDataGrid';
import { useTranslation } from 'react-i18next';
import Filter from '../../components/filter/Filter';
import { AnimatePresence } from 'framer-motion';
import ReactPaginate from 'react-paginate';
import getFilterString from '../../lib/helper-functions/getFilterString';
import returnArrayOrNull from '../../lib/helper-functions/returnArrayOrNull';

interface Props {
  currentPageNumber: number;
  setCurrentPageNumber: (input: any) => void;
  filterItems: any[];
  setFilterItems: (input: any) => void;
}

const AllOrders = ({
  currentPageNumber,
  setCurrentPageNumber,
  filterItems,
  setFilterItems,
}: Props) => {
  // const [orders, setOrders] = useState<any>();
  /* const shopId = useSelector((state: RootState) => state.user.company.shop_id); */
  const [loading, setLoading] = useState('pending');
  const navigate = useNavigate();
  const [t] = useTranslation();
  /*------------------------- FILTER STATE START ------------------------- */
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const toggleFilterPanel = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  /*------------------------- FILTER STATE END --------------------------- */

  /*------------------------- PAGINATION STATE START ------------------------- */
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  // const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalOrderRecords, setTotalOrderRecords] = useState(0);
  const [orderData, setOrderData] = useState([]);
  const itemsPerPage = 8;
  /*------------------------- PAGINATION STATE END ------------------------- */

  /* ----------- pagination stuf start ------------ */
  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPageCount(Math.ceil(totalOrderRecords / itemsPerPage));
  }, [itemOffset, itemsPerPage, orderData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    setCurrentPageNumber(event.selected + 1);
    const newOffset = (event.selected * itemsPerPage) % totalOrderRecords;
    setItemOffset(newOffset);
  };
  /* ----------- pagination stuf end   ------------ */

  /* ----------------------------API CALL START---------------------------- */
  useEffect(() => {
    const filterString = getFilterString(filterItems);
    const accessToken = store.getState().auth.authorization.accessToken;
    const isWhiteLabel = store.getState().UI?.isWhiteLabel;
    let sellerToken: string;
    if (isWhiteLabel) {
      sellerToken = accessToken?.currentWhiteLabelsellerToken;
    } else {
      sellerToken = accessToken?.sellerToken;
    }

    const requestInfo: RequestInfo = {
      url: `${ORDERS_ENDPOINT}?per_page=${itemsPerPage}&page=${currentPageNumber}${filterString}`,
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: accessToken.type + ' ' + sellerToken,
      },
    };

    const actionType = 'GET_ALL_ORDERS';
    const customMsg = 'Orders Loaded Successfully.';

    const getOrders = async () => {
      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;

      if (!response.success) {
        setLoading('error');
      }
      if (response.result.data.length === 0) {
        setLoading('null');
      } else {
        if (res.actionType === actionType && response.success === true) {
          const ordersResult = response?.result;
          setTotalOrderRecords(ordersResult?.total);

          const ordersObj = returnArrayOrNull(ordersResult);

          if (ordersObj?.length > 0) {
            const ordersArr: any = [];
            for (let i = 0; i < ordersObj?.length; i++) {
              ordersArr.push({
                status: ordersObj[i]?.cart_data?.status,
                id: ordersObj[i]?.cart_data?.order_id,
                shopper_id: ordersObj[i]?.cart_data?.shopper_id,
                created_at: ordersObj[i]?.cart_data?.created_at,
                cart_total: ordersObj[i]?.cart_data?.cart_total,
                method: ordersObj[i]?.cart_data?.method,
                gateway: ordersObj[i]?.cart_data?.gateway,
              });
            }
            setLoading('success');
            setOrderData(ordersArr);
          }
        }
      }
    };

    getOrders();
  }, [currentPageNumber, filterItems]);
  /* ----------------------------API CALL END---------------------------- */

  return (
    <SubPageContentHolder>
      <SubPageInnerContentHolder
        title={t('AllOrders')}
        subTitle={t('ManageOrders')}
        /* mainBtnIcon={plusSignSVG}
        mainBtnTitle='Create Order' */
        mainBtnLink='/orders'
        hasFilter={true}
        filterHandler={toggleFilterPanel}
        isFilterActive={isFilterOpen}
      >
        <AnimatePresence>
          {isFilterOpen && (
            <Filter filterItems={filterItems} setFilterItems={setFilterItems} />
          )}
        </AnimatePresence>
        <div className='ordersPage'>
          <div className='ordersPage__top'>
            <div className='ordersPage__top--left'></div>
            <div className='ordersPage__top--right'></div>
          </div>
          <div className='ordersPage__bottom'>
            {/* dt */}
            <div className='ordersPage__bottom--dataGridContainer'>
              {loading === 'null' && (
                <NoData
                  title={t('NoOrdersFound-msg')}
                  // subTitle='Something went wrong.'
                />
              )}
              {loading === 'error' && (
                <NoData
                  title={t('FetchingDataError-msg')}
                  subTitle={t('SomethingWentWrong-msg')}
                />
              )}
              {loading === 'pending' && <LoadingSpinner />}
              {loading === 'success' && (
                <CustomDataGrid
                  allRows={orderData}
                  titleField='id'
                  dateField='created_at'
                  excludingField={['method', 'gateway', 'shopper_id']}
                  borderType={'none'}
                  onViewHandler={(params) => {
                    navigate(`/orders/${params?.id}`);
                  }}
                />
              )}
            </div>
            {loading === 'success' && (
              <ReactPaginate
                breakLabel='...'
                nextLabel={t(`${'Next'} >`)}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                initialPage={currentPageNumber - 1}
                pageCount={pageCount}
                previousLabel={t(`< ${'Previous'}`)}
                renderOnZeroPageCount={undefined}
                marginPagesDisplayed={2}
                containerClassName='pagination'
                pageLinkClassName='pagination__page-num'
                previousLinkClassName='pagination__page-num'
                nextLinkClassName='pagination__page-num'
                disabledLinkClassName='pagination__page-num-disabled'
                activeLinkClassName='pagination__active'
              />
            )}
            {/* dt */}
          </div>
        </div>
      </SubPageInnerContentHolder>
    </SubPageContentHolder>
  );
};

export default AllOrders;
