import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SettingsGeneral from './settingsGeneral/SettingsGeneral';
// import SettingsSecurity from './settingsSecurity/SettingsSecurity';
import SettingsNotifications from './settingsNotifications/SettingsNotifications';
import SettingsShipping from './settingsShipping/SettingsShipping';
import ShopSettingsDesign from './settingsDesign/SettingsDesign';
import ShopSettingsWebhooks from '../settings/settingsWebhooks/SettingsWebhooks';
import SettingsMarketing from './settingsMarketing/SettingsMarketing';
import store from '../../store/store';
import PageWrapper from '../../components/page-wrappers/PageWrapper';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import {
  settingSVG,
  designSVG,
  shipSVG,
  notificationSVG,
  webhookSVG,
  marketingSVG,
  pspSvg,
} from '../../lib/allCustomSVGs';
import SubPageLinkHolder from '../../components/subPageLinkHolder/SubPageLinkHolder';
import SettingsConvenienceFee from './settingsConvenienceFee/SettingsConvenienceFee';

const SettingsPage = () => {
  const navigate = useNavigate();
  // const subscriptionLock = useSelector(
  //   (state: RootState) => state.auth.authorization.subscriptionLock
  // );

  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  const currentPath = window.location.pathname;

  /* useEffect(() => {
    if (subscriptionLock && subscriptionLock === 'true') {
      navigate('/settings/subscription');
    }
  }, [currentPath, subscriptionLock]); */

  useEffect(() => {
    if (currentPath === '/settings') {
      navigate('/settings/general');
    }
  }, [currentPath, navigate]);

  /* <SubPageLinkHolder
          mainSubTitle={t('Settings')}
          items={
            subscriptionLock && subscriptionLock === 'true'
              ? subPageLinkHolderLockedItems
              : subPageLinkHolderItems
          }
        /> */

  const subPageLinkHolderItems = [
    {
      icon: settingSVG,
      title: 'General',
      link: '/settings/general',
      urlName: 'general',
    },
    {
      icon: designSVG,
      title: 'Design',
      link: '/settings/design',
      urlName: 'design',
    },
    {
      icon: shipSVG,
      title: 'Shipping',
      link: '/settings/shipping',
      urlName: 'shipping',
    },
    {
      icon: pspSvg,
      title: 'ConvenienceFee',
      link: '/settings/convenienceFee',
      urlName: 'convenienceFee',
    },
    {
      icon: notificationSVG,
      title: 'Notifications',
      link: '/settings/notifications',
      urlName: 'notifications',
    },
    // { //? commented out since it is not needed in White Label
    //   icon: subscribeSVG,
    //   title: 'Subscription',
    //   link: '/settings/subscription',
    //   urlName: 'subscription',
    // },
    {
      icon: webhookSVG,
      title: 'Webhooks',
      link: '/settings/webhooks',
      urlName: 'webhooks',
    },
    {
      icon: marketingSVG,
      title: 'Marketing',
      link: '/settings/marketing',
      urlName: 'marketing',
    },
    /*  {
      icon: securitySVG,
      title: 'Security',
      link: '/settings/security',
      urlName: 'security',
    }, */
  ];

  return (
    <PageWrapper>
      {/* {activeLink === 'settings' && settingsPageContent()} */}
      <div className='subPage'>
        {isWhiteLabel && (
          <SubPageLinkHolder
            mainSubTitle={'Settings'}
            items={subPageLinkHolderItems}
          />
        )}

        <SubPageContentHolder>
          <Routes>
            <Route path='general/*' element={<SettingsGeneral />} />
            <Route path='design/*' element={<ShopSettingsDesign />} />
            <Route path='shipping/*' element={<SettingsShipping />} />
            <Route
              path='convenienceFee/*'
              element={<SettingsConvenienceFee />}
            />
            <Route path='notifications/*' element={<SettingsNotifications />} />
            {/* //? commented out since it is not needed in White Label
            <Route path='subscription/*' element={<SettingsSubscription />} /> 
            */}
            <Route path='webhooks/*' element={<ShopSettingsWebhooks />} />
            <Route path='marketing/*' element={<SettingsMarketing />} />
            {/* <Route path='security/*' element={<SettingsSecurity />} /> */}
          </Routes>
        </SubPageContentHolder>
      </div>
    </PageWrapper>
  );

  /*   return (
    <div className='subPage'>
     
      <SubPageContentHolder>
        <Routes>
          <Route path='general/*' element={<SettingsGeneral />} />
          <Route path='design/*' element={<ShopSettingsDesign />} />
          <Route path='shipping/*' element={<SettingsShipping />} />
          <Route path='notifications/*' element={<SettingsNotifications />} />
          <Route path='subscription/*' element={<SettingsSubscription />} />
          <Route path='webhooks/*' element={<ShopSettingsWebhooks />} />
          <Route path='marketing/*' element={<SettingsMarketing />} />
          <Route path='security/*' element={<SettingsSecurity />} />
        </Routes>
      </SubPageContentHolder>
    </div>
  ); */
};

export default SettingsPage;
