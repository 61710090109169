import '../../styles/pages/products/productDetailPage.scss';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

//api call imports
import {
  PRODUCTS_ENDPOINT,
  SYNC_HISTORY_ENDPOINT,
} from '../../lib/constants/urls';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import store from '../../store/store';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../ui/noData/NoData';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
import useTitle from '../../customHooks/useTitle';
import CustomDataGrid from '../../ui/customDataGrid.tsx/CustomDataGrid';
import Popup from '../../ui/popup/Popup';
import ShareProduct from './ShareProduct';
import { useTranslation } from 'react-i18next';
import getCurrencySymbol from '../../lib/helper-functions/getCurrencySymbol';
import makeFirstLetterCapital from '../../lib/helper-functions/makeFirstLetterCapital';
import truncate from '../../lib/helper-functions/truncate';
import formatDate from '../../lib/helper-functions/formatDate';

const ProductDetailPage = () => {
  useTitle('Product Detail');

  /* add share product modal state */
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  /* get company details from store */
  const userCompany = useSelector((state: RootState) => state.user.company);
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);
  const [selectedProductSKU, setSelectedProductSKU] = useState<any>();

  const togglePopupModal = () => {
    setIsPopupOpen(!isPopupOpen);
  };
  const { t } = useTranslation();
  const { productId } = useParams();
  const [loading, setLoading] = useState('pending');
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const [currentProduct, setCurrentProduct] = useState<any>();
  const [currentProductSyncStatus, setCurrentProductSyncStatus] =
    useState<any>();
  const [simpleProductExtra, setSimpleProductExtra] = useState<any>();
  const [productVariantsForDataGrid, setProductVariantsForDataGrid] = useState(
    []
  );
  const [isMainImageLoading, setIsMainImageLoading] = useState(true);

  /* ----------------------------API CALL START---------------------------- */
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelsellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: `${PRODUCTS_ENDPOINT}/${productId}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_SINGLE_PRODUCT';
  const customMsg = 'Product Loaded Successfully.';

  useEffect(() => {
    const getProducts = async () => {
      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;

      if (res.actionType === actionType && response.success === true) {
        const productObj = response?.result[0];
        if (productObj) {
          /* const productVariantsArr: any = []; */
          const mainProductObj: any = Object.values(productObj);
          const mainProductVariantObj: any = Object.values(
            mainProductObj[0]?.variant
          );

          if (mainProductObj[0]?.type === 'simple') {
            const mainProductObjKey: any = Object.keys(productObj);
            const mainProduct: any =
              mainProductObj[0]?.variant[mainProductObjKey[0]];
            const simpleProductExtraDetails = {
              total_sales: mainProduct?.total_sales
                ? Math.ceil(+mainProduct?.total_sales)
                : '',
              status: mainProduct?.status || '',
              stock: mainProduct?.stock || '',
            };
            setSimpleProductExtra(simpleProductExtraDetails);
          }
          /* setProductVariants(mainProductVariantObj); */
          createVariantsForDataGrid(mainProductVariantObj);
          setCurrentProduct(mainProductObj[0]);

          if (mainProductObj[0]?.id) {
            const syncStatusRequestInfo: RequestInfo = {
              url: `${SYNC_HISTORY_ENDPOINT}?product_id=${mainProductObj[0]?.id}`,
              method: 'GET',
              headers: {
                Accept: 'application/json',
                'Content-type': 'application/x-www-form-urlencoded',
                Authorization: accessToken.type + ' ' + sellerToken,
              },
            };

            const syncStatusActionType = 'GET_SYNC_HISTORY';
            const syncStatusCustomMsg = 'Sync History Loaded Successfully.';
            const syncStatusRes: any = await asyncHandler(
              syncStatusRequestInfo,
              syncStatusActionType,
              syncStatusCustomMsg,
              false
            );

            const syncStatusResponse = syncStatusRes?.data?.result;
            const selectedProductSyncStatus = Object.values(
              syncStatusResponse?.data
            )[0];
            setCurrentProductSyncStatus(selectedProductSyncStatus);
            setLoading('success');
          } else {
            setLoading('success');
          }
        } else {
          setLoading('null');
        }
      } else {
        setLoading('error');
      }
    };

    getProducts();
  }, []);
  /* ----------------------------API CALL END---------------------------- */

  const getStatusInSimpleProducts = () => {
    if (
      simpleProductExtra?.status === 1 ||
      simpleProductExtra?.status === '1' ||
      simpleProductExtra?.status === 'true' ||
      simpleProductExtra?.status === true
    ) {
      return t('Active');
    } else {
      return t('Inactive');
    }
  };

  const getStatusInVariableProducts = (status: any) => {
    if (
      status === 1 ||
      status === '1' ||
      status === 'true' ||
      status === true
    ) {
      return t('Active');
    } else {
      return t('Inactive');
    }
  };

  const a: any = 'kk';
  /* const num = parseInt(a) / 2; */
  if (!isNaN(parseInt(a))) {
    console.log('yes');
  } else {
    console.log('no');
  }

  function createVariantsForDataGrid(productVariants: any) {
    if (productVariants?.length > 0) {
      const variantsForGridArr: any = [];
      productVariants?.forEach((el: any) => {
        let firstImage;
        if (el?.images?.length > 0) {
          if (el?.images === 'default.png') {
            firstImage = `${staticURL}/small/default.jpg`;
          } else {
            firstImage = `${staticURL}/small/${el?.images[0]}`;
          }
        }

        variantsForGridArr.push({
          id: el?.id,
          name: el?.name,
          sku: el?.sku,
          price: el?.price,
          stock: el?.stock,
          sales: el?.total_sales,
          status: getStatusInVariableProducts(el?.status),
          firstImage: firstImage,
        });
      });

      setProductVariantsForDataGrid(variantsForGridArr);
    }
  }

  const handleMainImageLoad = () => {
    setIsMainImageLoading(false);
  };

  const renderImage = (imageURL: string) => {
    if (imageURL?.includes('.mp4')) {
      const newURL = imageURL.replace('large', 'medium');
      return <video onLoad={handleMainImageLoad} src={newURL} controls />;
    } else if (imageURL?.includes('.gif')) {
      const newURL = imageURL.replace('large', 'medium');
      return <img onLoad={handleMainImageLoad} src={newURL} alt='pic' />;
    } else {
      return <img onLoad={handleMainImageLoad} src={imageURL} alt='pic' />;
    }
  };
  const finalImageRender = () => {
    if (
      currentProduct?.images?.length < 0 ||
      currentProduct?.images[0] === 'default.png'
    ) {
      return (
        <img
          src={`${staticURL}/small/default.jpg`}
          alt='single-product'
          onLoad={handleMainImageLoad}
        />
      );
    } else {
      return renderImage(`${staticURL}/large/${currentProduct?.images[0]}`);
    }
  };

  return (
    <SubPageContentHolder>
      <SubPageInnerContentHolder
        title={currentProduct?.name}
        /* subTitle={'Manage Products'} */
        backBtnLink='/products'
      >
        <div className='productDetailPage'>
          <div className='productDetailPage-backButton'></div>
          {loading === 'error' && (
            <NoData
              title={t('ErrorFetchingData')}
              subTitle={t('SomethingWentWrong-msg')}
            />
          )}
          {loading === 'pending' && <LoadingSpinner />}
          {loading === 'success' && (
            <>
              <div className='productDetailPage__top'>
                <div
                  className={`productDetailPage__top--left ${
                    isMainImageLoading && !isDarkMode
                      ? 'skeleton-loader-light'
                      : isMainImageLoading && isDarkMode
                      ? 'skeleton-loader-dark'
                      : ''
                  }`}
                >
                  {finalImageRender()}
                </div>
                {/* center start*/}
                <div className='productDetailPage__top--center'>
                  <h1>{t('ProductDetails')}</h1>
                  <h2>
                    {t('Type')}:{' '}
                    <span>{makeFirstLetterCapital(currentProduct?.type)}</span>
                  </h2>
                  <h2>
                    SKU: <span>{currentProduct?.sku}</span>
                  </h2>
                  <h2>
                    {t('Currency')}: <span>{currentProduct?.currency}</span>
                  </h2>
                  <h2>
                    {t('DisplayPrice')}:{' '}
                    <span>{`${getCurrencySymbol('NL', 'EUR')} ${
                      currentProduct?.price
                    }`}</span>
                  </h2>
                  {currentProduct?.type === 'simple' && (
                    <h2>
                      {t('Stock')}: <span>{simpleProductExtra?.stock}</span>
                    </h2>
                  )}
                  {currentProduct?.description && (
                    <h2>
                      {t('Description')}:{' '}
                      <span>{truncate(currentProduct?.description, 150)}</span>
                    </h2>
                  )}
                  {currentProduct?.type === 'simple' && (
                    <>
                      <h2 className='productDetailPage__top--center-status'>
                        {t('Status')}:{' '}
                        <span>
                          {makeFirstLetterCapital(getStatusInSimpleProducts())}
                        </span>
                        {/*  <div
                          data-single-product-status={
                            simpleProductExtra?.status
                          }
                        /> */}
                      </h2>
                      <h2>
                        {t('TotalSales')}:{' '}
                        <span>{simpleProductExtra?.total_sales}</span>
                      </h2>
                    </>
                  )}
                  {currentProductSyncStatus &&
                    currentProductSyncStatus?.status && (
                      <>
                        <h2 className='productDetailPage__top--center-status'>
                          {t('LastSyncedStatus')}:{' '}
                          <span>
                            {makeFirstLetterCapital(
                              currentProductSyncStatus?.status
                            )}
                          </span>
                        </h2>
                        <h2>
                          {t('LastSyncedOn')}:{' '}
                          <span>
                            {' '}
                            {currentProductSyncStatus?.updated_at
                              ? formatDate(currentProductSyncStatus?.updated_at)
                              : currentProductSyncStatus?.created_at
                              ? formatDate(currentProductSyncStatus?.created_at)
                              : '-'}
                          </span>
                        </h2>
                      </>
                    )}
                  {/*  <h2>
                    Last updated: <span>12 hours ago</span>
                  </h2> */}
                </div>
                {/* center end*/}
                <div className='productDetailPage__top--right'>
                  {/* <h1>Product orders</h1>
                  <h2>
                    Total prders: <span>125</span>
                  </h2>
                  <h2>
                    Popular variant: <span>varsku3</span>
                  </h2>
                  <h2>
                    Carts abandoned: <span>15</span>
                  </h2>
                  <h2>
                    Last order: <span>2 hours ago</span>
                  </h2> */}
                </div>
              </div>
              {currentProduct?.type === 'variable' && (
                /* show varinats table only if the product type is "variable" */
                <>
                  {' '}
                  <div className='productDetailPage--varients-text'>
                    {t('ProductVarients')}
                  </div>
                  <div className='productDetailPage__bottom'>
                    {/* <CustomTable
                      headerData={columns}
                      rowData={productVariants}
                    /> */}

                    <CustomDataGrid
                      allRows={productVariantsForDataGrid}
                      titleField='name'
                      imageField='firstImage'
                      onShareHandler={(params) => {
                        setSelectedProductSKU(params?.sku);
                        togglePopupModal();
                      }}
                    />
                  </div>
                </>
              )}
            </>
          )}
        </div>

        {/* ------popup-------- */}
        <Popup
          styles={{
            backgroundColor: '#fff',
          }}
          setTrigger={togglePopupModal}
          trigger={isPopupOpen}
        >
          <ShareProduct
            shopSlug={userCompany.shop_slug}
            shopId={userCompany.shop_id}
            sku={selectedProductSKU}
          />
        </Popup>
      </SubPageInnerContentHolder>
    </SubPageContentHolder>
  );
};

export default ProductDetailPage;
