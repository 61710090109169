import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../store/store';
import { motion } from 'framer-motion';
import PSPSelector from '../../components/selection/PSPSelector';
import {
  OnboardingStagesMap,
  setStageComplete,
  updateIntercom,
  updateOnboardingStage,
} from '../../store/reducers/userSlice';
import { useEffect, useState } from 'react';
import '../../styles/components/onboarding/setShopPSPs1.scss';
import { useNavigate } from 'react-router-dom';
import MainPurpleButton from '../../ui/buttons/MainPurpleButton';
import { useTranslation } from 'react-i18next';
import { submitOnboardingStatus } from '../../lib/api/post-onboarding-status';
import { setIsOnboardingCompleteBannerOpen } from '../../store/reducers/uiSlice';
import getOnlyVisiblePSPs from '../../lib/helper-functions/getOnlyVisiblePSPs';
import { setRunInitOnFirstLoad } from '../../store/reducers/appSlice';
import hostedMollieButton from '../../assets/HostedMollieButton.png';

const SetShopPSPs1 = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );

  const options = getOnlyVisiblePSPs(onboarding_object);

  const selectedPSP_In_store = useSelector(
    (state: RootState) => state.user.company.psp
  );
  const preSelectedPSP = useSelector(
    (state: RootState) => state.user.company.preSelectedCMS_PSP.preSelectedPSP
  );
  const [showAllPSP, setShowAllPSP] = useState(false);

  const userProfile = useSelector((state: RootState) => state.user.profile);

  const [isOnePSPAvailable, setIsOnePSPAvailable] = useState(false);

  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  const mollieOauthRedirectURL = useSelector(
    (state: RootState) => state.auth?.authorization?.mollieOauthRedirectURL
  );

  /* console.log(selectedPSP_In_store?.toString()); */
  const navigate = useNavigate();

  const [selectedPSP, setSelectedPSP] = useState<string>('');

  async function handleContinue() {
    /* try {
      const response = await sendSelectedPSP(pspList.map((psp) => psp.name));
      if (response.data) {
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.IntegratingPSP1.name,
            isCompleted: true,
          })
        );
        dispatch(completeOnboarding(true));
        navigate('/dashboard');
      } else {
        setError(response.message);
        onFormSubmitedFailed(response.message);
      }
    } catch (exception) {
      console.log('Error during authentication request');
    } */
    /*  if (selectedPSP_In_store?.toString() !== 'null') {
      dispatch(
        updateIntercom({
          psp: selectedPSP_In_store?.toString(),
        })
      );
    } */

    if (selectedPSP_In_store?.toString() === 'nopsp') {
      dispatch(
        updateIntercom({
          psp: 'nopsp',
        })
      );
      const statusRes = await submitOnboardingStatus('psp', 'nopsp');
      if (statusRes) {
        await skip();
      }
    } else {
      if (selectedPSP_In_store?.toString() === 'other') {
        dispatch(
          updateOnboardingStage(OnboardingStagesMap.SettingPSPConnection.name)
        );
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.IntegratingPSP1.name,
            isCompleted: true,
          })
        );
      } else {
        dispatch(
          updateIntercom({
            psp: selectedPSP_In_store?.toString(),
          })
        );
        const statusRes = await submitOnboardingStatus(
          'psp',
          selectedPSP_In_store?.toString()
        );
        if (statusRes) {
          dispatch(
            updateOnboardingStage(OnboardingStagesMap.SettingPSPConnection.name)
          );
          dispatch(
            setStageComplete({
              stage: OnboardingStagesMap.IntegratingPSP1.name,
              isCompleted: true,
            })
          );
        }
      }
    }
  }

  /*  function skip() {
    dispatch(updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name));
    dispatch(
      setStageComplete({
        stage: OnboardingStagesMap.IntegratingCMS1.name,
        isCompleted: true,
      })
    );
  } */

  async function skip() {
    // navigate('/dashboard');
    dispatch(setIsOnboardingCompleteBannerOpen(true));
    const statusRes = await submitOnboardingStatus(
      'onboarding_completed',
      'true'
    );
    if (statusRes) {
      dispatch(setRunInitOnFirstLoad(true));
      navigate('/shop');
      dispatch(
        setStageComplete({
          stage: OnboardingStagesMap.IntegratingPSP1.name,
          isCompleted: true,
        })
      );
    } else {
      dispatch(setIsOnboardingCompleteBannerOpen(false));
    }
  }

  const isDisabled = selectedPSP === '' ? true : false;

  useEffect(() => {
    if (isOnePSPAvailable) {
      console.log('==only 1 PSP available, Skipping PSP Select page==');
      handleContinue();
    }
  }, [isOnePSPAvailable]);

  return (
    <div className='setShopPSPs1 animate-fade_in_from_right'>
      <div
        style={{ opacity: options?.length === 1 ? '0' : '1' }}
        className='setShopPSPs1-container'
      >
        <div className='setShopPSPs1--top'>
          <h1>{t('OnboardingPSP-SelectH1')}</h1>
          <h2>{t('OnboardingPSP-SelectH2')}</h2>
        </div>
        <PSPSelector
          setSelectedPSP={(pspName) => {
            setSelectedPSP(pspName);
          }}
          setIsOnePSPAvailable={(isOnePSPAvailable) => {
            setIsOnePSPAvailable(isOnePSPAvailable);
          }}
          options={options}
          showAllPSP={showAllPSP}
        />

        <motion.div
          className='setShopPSPs1--bottom'
          initial={{ opacity: 0, width: 0 }}
          animate={{ opacity: 1, width: '100%' }}
        >
          {selectedPSP !== 'nopsp' && (
            <MainPurpleButton
              onClick={() => handleContinue()}
              value={t('Next')}
              disabled={isDisabled}
              classes='text-white rounded-full'
            />
          )}
          {selectedPSP === 'nopsp' && ( // Mollie oAuth
            <>
              <p>{t('MollieOAuthText2')} : </p>
              <button
                style={{ width: '160px' }}
                onClick={() => {
                  navigate('/shop');
                  window.open(`${mollieOauthRedirectURL}`, '_blank');
                }}
              >
                <a rel='mollie.com'>
                  <img src={hostedMollieButton} alt='Connect via Mollie' />
                </a>
              </button>
              <br />
            </>
          )}
          <div className='pt-2'>
            {preSelectedPSP && (
              <>
                <a
                  className='skip-link'
                  onClick={() => {
                    setShowAllPSP(true);
                  }}
                >
                  {t('SelectOtherPSP')}
                </a>
                {` ${t('Or')} `}
              </>
            )}
            <a className='skip-link' onClick={skip}>
              {t('SkipStep')}
            </a>
          </div>
        </motion.div>
        {/* )} */}
      </div>

      <div className='setShopPSPs1--footer'>
        <a target={'_blank'} href={`${merchantTheme?.terms_url}`}>
          {t('Terms')}
        </a>
        {` & `}
        <a target={'_blank'} href={`${merchantTheme?.privacy_url}`}>
          {t('Privacy')}
        </a>
      </div>
    </div>
  );
};

export default SetShopPSPs1;
