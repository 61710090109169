import '../../../styles/pages/admin/allShops/shopsPage.scss';
import { useEffect, useState } from 'react';
import useTitle from '../../../customHooks/useTitle';
import store, { RootState } from '../../../store/store';
import SubPageContentHolder from '../../../components/subPageContentHolder/SubPageContentHolder';
import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import NoData from '../../../ui/noData/NoData';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { ADMIN_SHOPS_ENDPOINT } from '../../../lib/constants/urls';
import { asyncHandler } from '../../../lib/api/async-handler';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import { eyeSVG, shopSVG } from '../../../lib/allCustomSVGs';
import Popup from '../../../ui/popup/Popup';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import { setAccessToken } from '../../../store/reducers/authSlice';
import NormalInput from '../../../ui/inputs/NormalInput';
import { debounce } from '@mui/material';
import { useTranslation } from 'react-i18next';
import formatDate from '../../../lib/helper-functions/formatDate';
import { useSelector } from 'react-redux';

const AllSellers = () => {
  useTitle('Merchant');
  const { t } = useTranslation();
  const [availableShops, setAvailableShops] = useState<any>();
  const [loading, setLoading] = useState('pending');
  const [isSearching, setIsSearching] = useState(false);
  const [loadingShopLogin, setLoadingShopLogin] = useState(false);
  const [isShopViewPopupOpen, setIsShopViewPopupOpen] = useState(false);
  const [popupData, setPopupData] = useState<any>();
  const [rowCountState, setRowCountState] = useState();
  const [page, setPage] = useState(0);
  const [searchString, setSearchString] = useState<any>('');
  let pageSize = 20;

  const shopperAppUrl = useSelector(
    (state: RootState) => state.user.merchant?.fastcheckout_domain
  );

  const columns: GridColDef[] = [
    { field: 'id', headerName: `${t('ShopId')}`, width: 100 },
    { field: 'shop_slug', headerName: `${t('ShopSlug')}`, width: 190 },
    { field: 'name', headerName: `${t('ShopName')}`, width: 220 },
    {
      field: 'active_products_count',
      headerName: `${t('Products')}`,
      width: 100,
    },
    { field: 'orders_count', headerName: `${t('Orders')}`, width: 100 },
    {
      field: 'status',
      headerName: `${t('Status')}`,
      width: 100,
      renderCell: (params) => {
        return (
          <div className=''>
            {params.row.status && (
              <span>
                {params.row.status === '1' || params.row.status === 1 ? (
                  <>
                    <div className='flex-row'>
                      <div
                        style={{ backgroundColor: '#28a745' }}
                        className='customTable__top--column-rows-textholder-changeableField'
                      />
                      <span
                        className='mui-table-status-text'
                        style={{ paddingLeft: 8 }}
                      >
                        {t('Active')}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='flex-row'>
                      <div
                        style={{ backgroundColor: '#dc3545' }}
                        className='customTable__top--column-rows-textholder-changeableField'
                      />
                      <span
                        className='mui-table-status-text'
                        style={{ paddingLeft: 8 }}
                      >
                        {t('Inactive')}
                      </span>
                    </div>
                  </>
                )}
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const populateTableData = () => {
    const response = getAllAvailableShops();
    response
      .then((result) => {
        if (result) {
          setAvailableShops(result);
          setLoading('success');
          setIsSearching(false);
        } else {
          setLoading('error');
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading('error');
        setIsSearching(false);
      });
  };

  useEffect(() => {
    populateTableData();
  }, [page, searchString]);

  useEffect(() => {
    setPage(0);
  }, [searchString]);

  const searchStringChangeDebounce = debounce(
    (shopName) => setSearchString(shopName),
    400
  );

  const toggleShopViewPopupModal = (id: any) => {
    var selectedShop = availableShops.find((i: { id: any }) => i.id === id);
    setPopupData(selectedShop);
    setIsShopViewPopupOpen(!isShopViewPopupOpen);
  };

  /* ACTION COLUMN FOR DATA TABLE START */
  const actionColumn = [
    {
      field: 'actions',
      headerName: 'Actions',
      width: 280,
      renderCell: (params: any) => {
        return (
          <>
            <div className='cellAction' style={{ padding: '8px' }}>
              <div
                style={{ width: '150px' }}
                onClick={() => {
                  window.open(
                    `${shopperAppUrl}/${params?.row?.shop_slug}`,
                    '_blank'
                  );
                }}
                className='datatableActionBtn press editButton'
              >
                <CustomSVGs svg={shopSVG} />
                <span>{t('VisitStore')}</span>
              </div>
            </div>
            <div className='cellAction' style={{ padding: '8px' }}>
              <div
                onClick={() => {
                  toggleShopViewPopupModal(params?.row?.id);
                }}
                className='datatableActionBtn press editButton'
              >
                <CustomSVGs svg={eyeSVG} />
                <span>{t('View')}</span>
              </div>
            </div>
          </>
        );
      },
    },
  ];
  /* -ACTION COLUMN FOR DATA TABLE END- */

  /* ----------------------------API CALL START---------------------------- */
  async function getAllAvailableShops() {
    try {
      setIsSearching(true);
      const accessToken = store.getState().auth.authorization.accessToken;

      const requestInfo: RequestInfo = {
        url: `${ADMIN_SHOPS_ENDPOINT}?page=${
          page + 1
        }&perpage=${pageSize}&shopname=${searchString ?? ''}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + accessToken.merchantToken,
        },
      };

      const actionType = 'GET_ALL_SHOPS';
      const customMsg = 'All available Shops Loaded Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        setRowCountState(response.result.total);
        return response.result.data;
      }
    } catch (exception) {
      setLoading('error');
      setIsSearching(false);
      console.log('Error during request');
    }
  }
  async function logInToSelectedShop(shopId: number) {
    try {
      setLoadingShopLogin(true);
      const accessToken = store.getState().auth.authorization.accessToken;

      const requestInfo: RequestInfo = {
        url: `${ADMIN_SHOPS_ENDPOINT}/${shopId}/login`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + accessToken.merchantToken,
        },
      };

      const actionType = 'LOGIN_TO_SELECTED_SHOP';
      const customMsg = 'Seller Login Successful';

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        if (response.result?.token && response.result?.token !== '') {
          store.dispatch(
            setAccessToken({
              sellerToken: response.result?.token,
              merchantToken: accessToken.merchantToken,
              adminToken: accessToken?.adminToken,
              type: accessToken.type,
              expiresOn: accessToken?.expiresOn,
            })
          );
          window.open(`${window.location.origin}/shop`, '_blank');
        }
      }
      setLoadingShopLogin(false);
    } catch (exception) {
      console.log('Error during request');
      setLoadingShopLogin(false);
    }
  }
  /* ----------------------------API CALL END---------------------------- */
  return (
    <SubPageContentHolder>
      <SubPageInnerContentHolder
        title={t('AllSellers')}
        subTitle={t('ManageSellers')}
        backBtnLink={'/admin'}
      >
        {loading === 'null' && (
          <NoData
            title='No Shops Found'
            /* subTitle='Add Products to view them here.' */
          />
        )}
        {loading === 'error' && (
          <NoData
            title='Error Fetching Data'
            subTitle='Something went wrong :('
          />
        )}
        {loading === 'pending' && <LoadingSpinner />}
        {loading === 'success' && (
          <>
            <NormalInput
              changeListeners={[
                (e) => {
                  searchStringChangeDebounce(e.target.value);
                },
              ]}
              id={`shop-search`}
              value={searchString}
              placeholder={'🔎 Shop Name '}
              classes='lg:w-[13rem]'
            />
            <div className='subPage__container'>
              <div style={{ height: '70vh' }}>
                <DataGrid
                  sx={{
                    /* boxShadow: '0px 3px 10px -6px rgba(0, 0, 0, 0.84);', */
                    border: 'none',
                    '& .MuiDataGrid-cell:hover': {
                      color: 'primary.main',
                    },
                  }}
                  rows={availableShops}
                  getRowHeight={() => 'auto'}
                  disableSelectionOnClick
                  getRowId={(log) => log?.id}
                  columns={columns.concat(actionColumn)}
                  pagination
                  paginationMode='server'
                  loading={isSearching}
                  page={page}
                  pageSize={pageSize}
                  rowCount={rowCountState}
                  onPageChange={(newPage) => setPage(newPage)}
                  style={{ fontSize: '1.5rem' }}
                />
              </div>
            </div>
            <Popup
              styles={{ backgroundColor: '#fff' }}
              setTrigger={() => {
                setIsShopViewPopupOpen(!isShopViewPopupOpen);
              }}
              trigger={isShopViewPopupOpen}
            >
              {popupData && (
                <div className='orderDetailPage__top'>
                  {/* center start*/}
                  <div
                    className='orderDetailPage__top--center'
                    style={{ textAlign: 'center' }}
                  >
                    <h1>{t('ShopDetails')}</h1>
                    <br />
                    <h2>
                      {t('ShopId')} : <span>{popupData?.id}</span>
                    </h2>
                    <h2>
                      {t('SellerId')} : <span>{popupData?.seller_id}</span>
                    </h2>
                    <h2>
                      {t('ShopSlug')} : <span>{popupData?.shop_slug}</span>
                    </h2>
                    <h2>
                      {t('ShopName')} : <span>{popupData?.name}</span>
                    </h2>
                    <h2>
                      {t('Initial')} : <span>{popupData?.initial}</span>
                    </h2>
                    <h2>
                      {t('Currency')} : <span>{popupData?.currency}</span>
                    </h2>
                    <h2>
                      {t('TotalActiveProducts')} :{' '}
                      <span>{popupData?.active_products_count}</span>
                    </h2>
                    <h2>
                      {t('TotalOrders')} :{' '}
                      <span>{popupData?.orders_count}</span>
                    </h2>
                    <h2>
                      {t('CreatedDateTime')} :{' '}
                      <span>
                        {popupData?.created_at
                          ? formatDate(popupData?.created_at)
                          : '-'}
                      </span>
                    </h2>
                    <h2>
                      {t('UpdatedDateTime')} :{' '}
                      <span>
                        {popupData?.updated_at
                          ? formatDate(popupData?.updated_at)
                          : '-'}
                      </span>
                    </h2>
                    {(popupData?.status === '1' || popupData?.status === 1) && (
                      <h2
                        className='orderDetailPage__top--center-status'
                        style={{ justifyContent: 'center' }}
                      >
                        {t('Status')} :<span>{t('Active')}</span>
                        <div />
                      </h2>
                    )}
                    {(popupData?.status === '0' || popupData?.status === 0) && (
                      <h2
                        className='orderDetailPage__top--center-statusInactive'
                        style={{ justifyContent: 'center' }}
                      >
                        {t('Status')} :<span>{t('Inactive')}</span>
                        <div />
                      </h2>
                    )}
                    <br />
                    <div className='shopPage__container1'>
                      <MainSquareButton
                        onClick={(e) => {
                          logInToSelectedShop(popupData?.id);
                        }}
                        type={'button'}
                        value={
                          loadingShopLogin
                            ? `${t('LoggingInTo')} "${popupData?.name}"`
                            : `${t('Visit')} "${popupData?.name}" ${t(
                                'ShopDashboard'
                              )}`
                        }
                        icon={''}
                        normal={true}
                        disabled={loadingShopLogin}
                      />
                    </div>
                  </div>
                  {/* center end*/}
                </div>
              )}
            </Popup>
          </>
        )}
      </SubPageInnerContentHolder>
    </SubPageContentHolder>
  );
};

export default AllSellers;
