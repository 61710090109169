import { useEffect, useState } from 'react';
import '../../../../styles/generic/normalForm.scss';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import NormalInput from '../../../../ui/inputs/NormalInput';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../../lib/utils/body-builder';
import {
  ADUP_PAY_ENDPOINT,
  PSP_ENDPOINT,
} from '../../../../lib/constants/urls';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../../lib/api/async-handler';
import store from '../../../../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { useNavigate, useParams } from 'react-router-dom';
import ToggleSwitch from '../../../../ui/buttons/ToggleSwitch';
import { PaymentMethodsInterface } from './AddPSP';
import { updateIntercom } from '../../../../store/reducers/userSlice';
import NormalSelectInput from '../../../../ui/inputs/NormalSelectInput';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../../ui/noData/NoData';
import { useTranslation } from 'react-i18next';
import makeFirstLetterCapital from '../../../../lib/helper-functions/makeFirstLetterCapital';

const EditPSP = () => {
  const [pspInputs, setPSPInputs] = useState([]);
  const [pspInputValues, setPSPInputValues] = useState<any>();
  const { t } = useTranslation();
  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  /* ============================================================== */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const shopId = useSelector((state: RootState) => state.user.company.shop_id);
  const alreadyAddedPSP = useSelector(
    (state: RootState) => state.user.company.alreadyAddedPSP
  ) as any;
  const paymentMethods = useSelector(
    (state: RootState) => state.user.onboarding.paymentMethods
  );
  const [editingPSP, setEditingPSP] = useState<any>([]);
  const [selectedPSP, setSelectedPSP] = useState({ value: '', label: '' });
  const { pspId } = useParams();
  const [selectedPaymentMethods, setSelectedPaymentMethods] =
    useState<PaymentMethodsInterface[]>();
  const [visiblePaymentMethods, setVisiblePaymentMethods] =
    useState<JSX.Element>();
  const [paymentMethodClickChanges, setPaymentMethodClickChanges] = useState(0);
  const tempItems: JSX.Element[] = [];
  const [adUpPayProfileIds, setAdUpPayProfileIds] = useState(undefined);
  const [loading, setLoading] = useState('pending');

  /* ----------------------------API CALL START---------------------------- */

  const accessToken = store.getState().auth.authorization.accessToken;

  const getAdUpPayProfileIds = async () => {
    try {
      const requestInfo: RequestInfo = {
        url: ADUP_PAY_ENDPOINT + '/get-profile-ids',
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + accessToken.sellerToken,
        },
      };

      const actionType = 'GET_ADUP_PAY_PROFILE_IDS';
      const customMsg = 'AdUp Pay profile Id fetch Successful.';

      setLoading('pending');
      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;
      if (!response.success) {
        setLoading('success');
      }
      if (res.actionType === actionType && response.success === true) {
        if (response.result?.length > 0) {
          const finalArr: any = [];
          for (let i = 0; i < response.result.length; i++) {
            finalArr.push({
              OptionValue: response.result[i]?.profile_id,
              OptionName: response.result[i]?.profile_id,
            });
          }
          setAdUpPayProfileIds(finalArr);
          setLoading('success');
          console.log(response.result);
        } else {
          setLoading('success');
          console.log(response.result);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading('success');
    }
  };

  useEffect(() => {
    if (selectedPSP?.value === 'aduppay') {
      getAdUpPayProfileIds();
    } else {
      setLoading('success');
    }
  }, [selectedPSP]);

  /* ----------------------------API CALL END---------------------------- */

  useEffect(() => {
    let finalArray: PaymentMethodsInterface[] = [];
    if (selectedPSP?.value) {
      let data: any = paymentMethods?.[selectedPSP?.value];
      for (const key in data) {
        let method = {
          value: key,
          label: data?.[key]?.label,
          isSelected: undefined,
        };
        finalArray.push(method);
      }
    }
    setSelectedPaymentMethods(finalArray);
  }, [selectedPSP]);

  if (selectedPaymentMethods) {
    for (let method of selectedPaymentMethods) {
      let updatedValues = selectedPaymentMethods;
      let isSelected =
        selectedPaymentMethods[selectedPaymentMethods.indexOf(method)]
          .isSelected ?? editingPSP?.methods.includes(`${method.value}`);
      if (
        selectedPaymentMethods[selectedPaymentMethods.indexOf(method)]
          .isSelected === undefined
      ) {
        updatedValues[updatedValues.indexOf(method)] = {
          ...method,
          isSelected: editingPSP?.methods.includes(`${method.value}`),
        };
        setSelectedPaymentMethods(updatedValues);
      }
      const onClickHandler = (e: any) => {
        updatedValues[updatedValues.indexOf(method)] = {
          ...method,
          isSelected: e,
        };
        setSelectedPaymentMethods(updatedValues);
        isSelected = e;
        setPaymentMethodClickChanges(paymentMethodClickChanges + 1);
      };
      tempItems.push(
        <div className='normalForm__switch'>
          <ToggleSwitch
            id={selectedPaymentMethods.indexOf(method)}
            isOn={isSelected}
            defaultChecked={editingPSP?.methods.includes(`${method.value}`)}
            title={method?.label}
            onClick={onClickHandler}
            classes='margin-top-2rem'
          />
        </div>
      );
    }
  }

  useEffect(() => {
    if (tempItems) {
      setVisiblePaymentMethods(tempItems as any);
    }
  }, [paymentMethodClickChanges, selectedPaymentMethods]);

  useEffect(() => {
    setSelectedPSP({
      value: editingPSP?.gateway_name,
      label: editingPSP?.label,
    });
  }, [editingPSP]);

  useEffect(() => {
    if (pspId) {
      setEditingPSP(alreadyAddedPSP[pspId]);
    }
  }, [pspId]);

  /* ================================================================================= */
  useEffect(() => {
    const currentPSP = onboarding_object?.available_psp?.filter((el: any) => {
      const currentPSP_Obj: any = Object.values(el)[0];
      const currentPSP_Obj_label = currentPSP_Obj?.label;
      return currentPSP_Obj_label?.toLowerCase() ===
        selectedPSP?.label?.toLowerCase()
        ? currentPSP_Obj
        : null;
    });

    const current_PSP_API_values_Arr = alreadyAddedPSP?.filter((el: any) => {
      return (
        el?.gateway_name?.toLowerCase() === selectedPSP?.value?.toLowerCase()
      );
    });

    let current_PSP_API_values: any;
    if (current_PSP_API_values_Arr?.length > 0) {
      current_PSP_API_values = current_PSP_API_values_Arr[0]?.secure_data;
    }

    if (currentPSP?.length > 0) {
      const temp: any = Object.values(currentPSP)[0];
      const found_PSP: any = Object.values(temp)[0];
      const currentPSP_inputs = found_PSP?.connection_method?.manual;
      if (currentPSP_inputs?.length > 0) {
        let temp_inputs: any = [];
        for (let i = 0; i < currentPSP_inputs[0].length; i++) {
          const temp = currentPSP_inputs[0][i];
          temp_inputs.push(temp);
        }

        setPSPInputs(temp_inputs);
        setPSPInputValues(current_PSP_API_values);

        //* removing invisible payment details from Input values payload
        for (let i = 0; i < temp_inputs.length; i++) {
          if (!temp_inputs[i]?.visible) {
            setPSPInputValues((prev: any) => ({
              ...prev,
              [temp_inputs[i]?.label]: undefined,
            }));
          }
        }
      }
    }
  }, [selectedPSP]);
  /* ================================================================================= */

  let isDisabled;

  if (pspInputValues) {
    isDisabled = Object.values(pspInputValues)?.some((el: any) => el === '');
  }

  const onInputChange = (e: any, value: string) => {
    setPSPInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    try {
      const accessToken = store.getState().auth.authorization.accessToken;

      const sendingData = {
        psp_module: selectedPSP?.value,
        shop_id: shopId,
        payment_details: JSON.stringify(pspInputValues),
        payment_method: selectedPaymentMethods
          ?.filter((el) => {
            return el.isSelected === true;
          })
          .map(({ value }) => value)
          .join(','),
      };

      const requestInfo: RequestInfo = {
        url: PSP_ENDPOINT,
        method: 'PATCH',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + accessToken.sellerToken,
        },
      };

      const actionType = 'UPDATE_PSP_CONNECTION';
      const customMsg = t('PSP-ConnectionUpdateSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        dispatch(
          updateIntercom({
            psp: selectedPSP?.value,
            pspdatafilled: 'true',
          })
        );
        navigate('/shop/psp/connection');
      }
    } catch (exception) {
      console.log('Error during authentication request');
    }
  }

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'error' && (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      )}
      {loading === 'success' && selectedPSP.label && selectedPSP.value && (
        <form
          id='PSPEdit-form'
          onSubmit={onSubmitHandler}
          className='normalForm'
        >
          <div className='normalForm__top'>
            <div className='normalForm__container3'>
              {selectedPSP && (
                <div className='normalForm__container3--item'>
                  <div className='normalForm__container3--item-content'>
                    <NormalInput
                      disabled
                      id={'selected-psp-edit'}
                      value={selectedPSP?.label}
                      defaultValue={selectedPSP?.label}
                      placeholder={t('PSP-Type')}
                    />
                  </div>
                </div>
              )}

              {pspInputs.map((item: any, index: number) => {
                const el = item?.label;
                const isDisabled = !item?.visible;
                return (
                  <div className='normalForm__container3--item' key={index}>
                    {selectedPSP?.value === 'aduppay' &&
                    el === 'profile_id' &&
                    !isDisabled ? (
                      <div className='normalForm__container3--item-content'>
                        <NormalSelectInput
                          defaultValue={pspInputValues[el]}
                          setSelect={(value) => {
                            setPSPInputValues((prev: any) => ({
                              ...prev,
                              [el]: value,
                            }));
                          }}
                          disabled={isDisabled}
                          selectOptionArr={
                            adUpPayProfileIds ?? [
                              {
                                OptionValue: pspInputValues[el],
                                OptionName: pspInputValues[el],
                              },
                            ]
                          }
                          placeholder={makeFirstLetterCapital(
                            el.replaceAll('_', ' ')
                          )}
                          id={`psp-integration-${el}-edit`}
                        />
                      </div>
                    ) : !isDisabled ? (
                      <div className='normalForm__container3--item-content'>
                        <NormalInput
                          changeListeners={[(e) => onInputChange(e, el)]}
                          id={`psp-integration-${el}-edit`}
                          value={pspInputValues ? pspInputValues[el] : ''}
                          placeholder={makeFirstLetterCapital(
                            el.replaceAll('_', ' ')
                          )}
                          disabled={isDisabled}
                          required={true}
                          autoFocus={index === 0 ? true : false}
                          classes={`edit-psp-${selectedPSP?.value}-${el}-input`}
                          labelClasses={`edit-psp-${selectedPSP?.value}-${el}-label`}
                        />
                      </div>
                    ) : null}
                  </div>
                );
              })}

              {/* Choose Payment Methods : */}
              <div className='normalForm__switches'>
                {visiblePaymentMethods}
              </div>
            </div>
          </div>

          <div className='normalForm__bottom'>
            <MainSquareButton
              value={t('Edit')}
              /* icon={<CustomSVGs svg={plusSignSVG} />} */
              disabled={isDisabled}
              type='submit'
              form='PSPEdit-form'
            />
          </div>
        </form>
      )}
    </>
  );
};

export default EditPSP;
