import '../../styles/pages/shop/shopPage.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import PageWrapper from '../../components/page-wrappers/PageWrapper';
import CategoryCard from '../../ui/cards/CategoryCard';
import { twoUsersSVG } from '../../lib/allCustomSVGs';
import ProfileView from '../../components/profileView/ProfileView';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import userPic from '../../imgs/user.jpg';
import MerchantSellersPage from './allSellers/MerchantSellersPage';

const MerchantHomePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const activeLink = location.pathname.substring(1);
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const isInitSuccess = useSelector(
    (state: RootState) => state.app.isInitSuccess
  );

  const sellersPageContent = () => {
    return (
      <div className='shopPage'>
        <ProfileView
          pic={userPic}
          title={
            isInitSuccess
              ? `${userProfile?.first_name} ${userProfile?.last_name}`
              : ''
          }
          description={t('WelcomeMerchant')}
        />

        <div className='shopPage__container1'>
          <CategoryCard
            icon={twoUsersSVG}
            title={t('AllSellers')}
            subTitle={t('ManageAvailableSellers')}
            link='sellers'
          />
          {/* <CategoryCard  //TODO: hidden since BE is not ready 
            icon={settingSVG}
            title={t('Settings')}
            subTitle={t('ManageAllSettings')}
            link='settings'
          /> */}
        </div>
      </div>
    );
  };
  return (
    <PageWrapper>
      {activeLink === 'merchant' && sellersPageContent()}
      <Routes>
        <Route path='sellers/*' element={<MerchantSellersPage />} />
        {/* <Route path='settings/*' element={<MerchantSettings />} />  //TODO: hidden since BE is not ready  */}
      </Routes>
    </PageWrapper>
  );
};

export default MerchantHomePage;
