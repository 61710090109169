import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import ShowInfoBlock from '../../../components/showInfoBlock/ShowInfoBlock';
import store from '../../../store/store';
import '../../../styles/pages/settings/settingsShippingOptions.scss';
import SettingsShippingAdd from './SettingsShippingAdd';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { SETTINGS_ENDPOINT } from '../../../lib/constants/urls';
import { asyncHandler } from '../../../lib/api/async-handler';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import { useTranslation } from 'react-i18next';
import MaskedCurrencyInput from '../../../ui/inputs/CurrencyInput';
import SelectableButton from '../../../ui/buttons/SelectableButton';
import { setUserCompanySettings } from '../../../store/reducers/userSlice';
import '../../../styles/generic/normalForm.scss';

interface CountryShippingCostInterface {
  id: number;
  country: string;
  rateType: 'percentage' | 'flat' | string;
  cost: string;
}

const SettingsShippingOptions = () => {
  const [defaultShippingCost, setDefaultShippingCost] = useState<string>('');
  const [minShippingCost, setMinShippingCost] = useState<string>('');
  const [isFixedShippingCost, setIsFixedShippingCost] =
    useState<boolean>(false);
  const [loading, setLoading] = useState('pending');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { t } = useTranslation();

  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelsellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  //add shipping cost foreach country state
  const [countriesShippingCost, setCountriesShippingCost] = useState<
    CountryShippingCostInterface[]
  >([]);

  useEffect(() => {
    const response = getShippingSettings();
    response
      .then((result) => {
        setDefaultShippingCost(result?.default_shipping_cost);
        setMinShippingCost(result?.min_free_shipping);
        setIsFixedShippingCost(
          result?.fixed_shipping === 'true' || result?.fixed_shipping === true
            ? true
            : false
        );
        setLoading('success');
      })
      .catch((err) => {
        console.error(err);
        setLoading('error');
      });
  }, []);

  /* ----------------------------API CALL START---------------------------- */
  async function getShippingSettings() {
    try {
      const requestInfo: RequestInfo = {
        url: SETTINGS_ENDPOINT + '?group=Shipping',
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_SHIPPING_SETTINGS';
      const customMsg = 'Shipping Settings fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return response.result;
      }
    } catch (exception) {
      console.log('Error during request');
    }
  }

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const sendingData = {
        group: 'Shipping',
        default_shipping_cost: defaultShippingCost ?? null,
        min_free_shipping: minShippingCost ?? null,
        fixed_shipping: isFixedShippingCost,
      };

      const requestInfo: RequestInfo = {
        url: SETTINGS_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'UPDATE_SHIPPING_SETTINGS';
      const customMsg = 'Shipping Settings updated Successfully.';

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      setIsSubmitting(false);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        store.dispatch(setUserCompanySettings(response?.result));
        return response.result;
      }
    } catch (exception) {
      console.log('Error during authentication request');
      setIsSubmitting(false);
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  const isDisabled =
    defaultShippingCost === '' || minShippingCost === '' ? true : false;

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'error' && (
        <NoData
          title='Error Fetching Data'
          subTitle='Something went wrong :('
        />
      )}
      {loading === 'success' && (
        <div className='settingsShippingOptions'>
          <div className='settingsShippingOptions__top'>
            {/* ------------------------------- */}
            {/* //* Shipping cost model Selection -- Start */}
            <div className='shopPage__title'>
              <h2>{t('ShippingCostModel')}</h2>
            </div>
            {/* <div className='shopPage__divider' /> */}
            <div className='shopPage__container1-alignLeft'>
              <SelectableButton
                icon={''}
                title={''}
                subTitle={t('FixedPricing')}
                subTitle2={t('FixedPricing-Sub')}
                link=''
                onSelect={() => {
                  setIsFixedShippingCost(true);
                }}
                isSelected={isFixedShippingCost}
              />
              <SelectableButton
                icon={''}
                title={''}
                subTitle={t('CountryPricing')}
                subTitle2={t('CountryPricing-Sub')}
                link=''
                onSelect={() => {
                  setIsFixedShippingCost(false);
                }}
                isSelected={!isFixedShippingCost}
              />
            </div>
            {/* //* Shipping cost model Selection -- End */}

            {/* //* Country specific shipping component -- Start */}
            <AnimatePresence>
              {!isFixedShippingCost && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className='settingsShippingOptions__bottom'
                >
                  <SettingsShippingAdd
                    countriesShippingCost={countriesShippingCost}
                    setCountriesShippingCost={setCountriesShippingCost}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            {/* //* Country specific shipping component -- End */}

            <div className='normalForm__container1'>
              {/* //* Default shipping cost Input -- Start */}
              <div className='normalForm__container1--left'>
                <div className='flex-row'>
                  <div className='flex-grow-container'>
                    <MaskedCurrencyInput
                      changeListeners={[
                        (value: any) => setDefaultShippingCost(value),
                      ]}
                      id='default-shipping-cost'
                      value={
                        defaultShippingCost &&
                        !Number.isNaN(defaultShippingCost)
                          ? defaultShippingCost
                          : '0'
                      }
                      placeholder={t('DefaultShippingCost')}
                      required={true}
                      title={t('OnlyNumbers-validation')}
                      type='text'
                      pattern='^[\d|\.|\,]+/'
                      defaultValue={
                        defaultShippingCost &&
                        !Number.isNaN(defaultShippingCost)
                          ? defaultShippingCost
                          : '0'
                      }
                    />
                  </div>
                  <ShowInfoBlock infoText={t('DefaultShippingCost-Info')} />
                </div>
              </div>
              {/* //* Default shipping cost Input -- End */}

              {/* //* Minimum amount for free shipping Input -- Start */}
              <div className='normalForm__container1--right'>
                <div className='flex-row'>
                  <div className='flex-grow-container'>
                    <MaskedCurrencyInput
                      changeListeners={[
                        (value: any) => setMinShippingCost(value),
                      ]}
                      id='min-shipping-cost'
                      value={
                        minShippingCost && !Number.isNaN(minShippingCost)
                          ? minShippingCost
                          : '0'
                      }
                      placeholder={t('MinimumAmountFreeShipping')}
                      title={t('OnlyNumbers-validation')}
                      type='text'
                      pattern='^[\d|\.|\,]+/'
                      defaultValue={
                        minShippingCost && !Number.isNaN(minShippingCost)
                          ? minShippingCost
                          : '0'
                      }
                    />
                  </div>
                  <ShowInfoBlock
                    infoText={t('MinimumAmountFreeShipping-info')}
                  />
                </div>
              </div>
              {/* //* Minimum amount for free shipping Input -- End */}
            </div>
            {/* ------------------------------- */}
          </div>

          <div className='normalForm__bottom flex-start'>
            <MainSquareButton
              value={isSubmitting ? `${t('Updating')}...` : t('UpdateSettings')}
              disabled={isDisabled || isSubmitting}
              type='submit'
              onClick={onSubmitHandler}
              form='shipping-settings-form'
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsShippingOptions;
