import './styles/root/app.scss';
import { Navigate, Route, useLocation, useNavigate } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import Onboarding from './pages/onboarding';
import { useEffect, useLayoutEffect, useState } from 'react';
import history from './history';
import { fetchAppInit } from './lib/api/app-init';
import SignUpPage from './pages/sign-up/Signup';
import LoginPage from './pages/login/Login';
import SplashScreen from './components/splash-screen/SplashScreen';
import NewHeader from './components/header/NewHeader';
import ProductsPage from './pages/products/ProductsPage';
import OrdersPage from './pages/orders/OrdersPage';
import ShopPage from './pages/shop/ShopPage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './store/store';
import {
  clearUserSlice,
  setIsAdmin,
  setIsMerchant,
  updateIntercom,
} from './store/reducers/userSlice';
import { AnimatePresence } from 'framer-motion';
import Message from './components/generic/Message';
import PageNotFound from './pages/pageNotFound/PageNotFound';
import EmailVerificationSuccess from './pages/redirects/EmailVerificationSuccess';
import StripeSubscriptionSuccess from './pages/redirects/StripeSubscriptionSuccess';
import StripeSubscriptionFailed from './pages/redirects/StripeSubscriptionFailed';
import AdminHomePage from './pages/admin/AdminHomePage';
import { clearAuthSlice, setAccessToken } from './store/reducers/authSlice';
import ForgotPassword from './pages/forgotPassword/ForgotPassword';
import ResetPassword from './pages/forgotPassword/ResetPassword';
import i18n from './internationalization/i18n';
import AdUpPayOnboardingSuccess from './pages/redirects/AdUpPayOnboardingSuccess';
import OnboardingCompleteBanner from './ui/banners/OnboardingCompleteBanner';
import appThemeEngine from './lib/helper-functions/appThemeEngine';
import moment from 'moment';
import { tokenRevokeAtLogOutUser } from './lib/api/logout-user';
import { setMessage } from './store/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import PluginAuthPage from './pages/plugin/PluginAuthPage';
import WhiteLabel from './pages/whiteLabel/WhiteLabel';
import { setIsWhiteLabel } from './store/reducers/uiSlice';
import MerchantHomePage from './pages/merchant/MerchantHomePage';
import Unsubscribe from './pages/redirects/Unsubscribe';
import SettingsPage from './pages/settings/SettingsPage';
import IntegrationsPage from './pages/integrations/IntegrationsPage';
import MollieOnboardingSuccess from './pages/redirects/MollieOnboardingSuccess';

function App() {
  const { t } = useTranslation();
  const location = useLocation();
  const currentRoute = location.pathname;
  const [isTopBarVisible, setIsTopBarVisible] = useState<boolean>(false);
  const [isAdminTopBarVisible, setIsAdminTopBarVisible] =
    useState<boolean>(false);

  const [isSidebarDrawerOpen, setIsSidebarDrawerOpen] =
    useState<boolean>(false);

  const toggleSidebarDrawer = () => {
    setIsSidebarDrawerOpen(!isSidebarDrawerOpen);
  };

  /* get onboarding data */
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const onboardingObject = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const intercomData = useSelector((state: RootState) => state.user.intercom);
  const accessToken = useSelector(
    (state: RootState) => state.auth.authorization.accessToken
  );
  const subscriptionLock = useSelector(
    (state: RootState) => state.auth.authorization.subscriptionLock
  );
  const message = useSelector((state: RootState) => state.app.message);
  const isInitSuccess = useSelector(
    (state: RootState) => state.app.isInitSuccess
  );
  const appLanguage = useSelector((state: RootState) => state.app.language);
  const isAppThemeDark = useSelector((state: RootState) => state.UI.theme.dark);
  const isWhiteLabel = useSelector((state: RootState) => state.UI.isWhiteLabel);
  const isOnboardingCompleteBannerOpen = useSelector(
    (state: RootState) => state.UI.isOnboardingCompleteBannerOpen
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let googleToken = window.location?.search.split('?')[1];

  useLayoutEffect(() => {
    const unlisten = history.listen(({ location, action }) => {
      let unblock = history.block((tx) => {
        // Navigation was blocked! Let's show a confirmation dialog
        // so the user can decide if they actually want to navigate
        // away and discard changes they've made in the current page.
        let url = tx.location.pathname;
        if (window.confirm(`Are you sure you want to go to ${url}?`)) {
          // Unblock the navigation.
          unblock();

          // Retry the transition.
          tx.retry();
        }
      });
    });

    return unlisten;
  }, []);

  /* function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } */

  //changing app language
  useEffect(() => {
    i18n.changeLanguage(appLanguage);
  }, [appLanguage]);

  /* useEffect(() => {
    dispatch(setIsWhiteLabel(true));
  }, []); */

  useEffect(() => {
    if (currentRoute.includes('/admin') && accessToken?.adminToken) {
      dispatch(
        setAccessToken({
          ...accessToken,
          merchantToken: undefined,
          sellerToken: undefined,
        })
      );
    } else if (
      currentRoute.includes('/merchant') &&
      accessToken?.merchantToken
    ) {
      dispatch(
        setAccessToken({
          ...accessToken,
          sellerToken: undefined,
        })
      );
    }
    if (currentRoute !== '/authorize') {
      dispatch(setIsWhiteLabel(false));
    }
    if (!googleToken && currentRoute !== '/authorize') {
      fetchAppInit();
    }
  }, []);

  useEffect(() => {
    //* Handle Google Login
    if (
      (currentRoute === '/shop' || currentRoute === '/onboarding') &&
      googleToken
    ) {
      dispatch(
        setAccessToken({
          sellerToken: googleToken,
          merchantToken: undefined,
          adminToken: undefined,
          type: 'Bearer',
          expiresOn: moment().add(21, 'days'),
        })
      );
      if (currentRoute === '/shop') {
        setIsTopBarVisible(true);
      }
      fetchAppInit();
    }
    //* Handle Admin User
    if (currentRoute.includes('/admin') && accessToken?.adminToken) {
      dispatch(
        setAccessToken({
          ...accessToken,
          merchantToken: undefined,
          sellerToken: undefined,
        })
      );
      setIsAdminTopBarVisible(true);
      dispatch(setIsAdmin(true));
    } else {
      setIsAdminTopBarVisible(false);
      dispatch(setIsAdmin(false));
    }
    //* Handle Merchant User
    if (currentRoute.includes('/merchant') && accessToken?.merchantToken) {
      dispatch(
        setAccessToken({
          ...accessToken,
          sellerToken: undefined,
        })
      );
      dispatch(setIsMerchant(true));
    } else {
      dispatch(setIsMerchant(false));
    }
    //* Handle Seller User
    if (
      (currentRoute.includes('/dashboard') ||
        currentRoute.includes('/orders') ||
        currentRoute.includes('/products') ||
        currentRoute.includes('/shop')) &&
      (accessToken?.sellerToken || googleToken)
    ) {
      setIsTopBarVisible(true);
    } else {
      setIsTopBarVisible(false);
    }
    /* console.log(currentRoute); */
  }, [currentRoute, window.location?.search]);

  /* --------------Token expiry check Start---------------- */
  useEffect(() => {
    console.log(
      '⏰ 🚧 Current access token will be expired on :',
      accessToken?.expiresOn
    );
    if (!googleToken && currentRoute !== '/authorize') {
      if (accessToken?.expiresOn && accessToken?.expiresOn !== null) {
        if (moment(accessToken?.expiresOn) < moment()) {
          console.log('❌ Access token expired. Logging Out user');
          logoutHandler();
          dispatch(
            setMessage({
              message: t('SessionExpired'),
              messageType: 'error',
            })
          );
        } else {
          console.log('✅ Access token still active');
        }
      } else {
        logoutHandler();
      }
    }
  }, [accessToken?.expiresOn]);
  /* --------------Token expiry check End---------------- */

  /* --------------App Theme Settings Start---------------- */
  useEffect(() => {
    //color testing object
    /* const th = {
      main_brand_color: '#5829c7',
      secondary_brand_color: '#ffdd33',
      main_btn_text_color: '#ffffff',
      main_text_color: '#2e333d',
    }; */

    appThemeEngine(isAppThemeDark, merchantTheme);
    // appThemeEngine(isAppThemeDark, th);
  }, [isAppThemeDark, merchantTheme]);
  /* --------------App Theme Settings End------------------ */

  /* --------------Setting Favicon Start---------------- */
  useEffect(() => {
    const link = document.querySelector("link[rel*='icon']") as HTMLLinkElement;

    if (merchantTheme?.merchant_favicon) {
      link.href = `${staticURL}/medium/${merchantTheme.merchant_favicon}`;
      console.log('has merchant favicon');
    } else {
      link.href = '/favicon.ico'; // Assuming the favicon is located in the public folder
      console.log('no merchant favicon');
    }
  }, [staticURL, merchantTheme]);
  /* --------------Setting Favicon End------------------ */

  /* -------------run intercom when loggedin user data loads code start--------------- */
  useEffect(() => {
    if (
      userProfile.first_name &&
      accessToken?.sellerToken &&
      merchantTheme?.merchant_name === 'AdUp' //*  Intercom only enabled for AdUp
    ) {
      console.log(
        'intercom enabled for merchant : ',
        merchantTheme?.merchant_name
      );
      if (!intercomData.isFirstBootComplete) {
        console.log('intercom first boot running...');
        // @ts-ignore:next-line
        window.Intercom('boot', {
          app_id: intercomData.app_id,
          name: `${userProfile.first_name} ${userProfile.last_name}`,
          email: `${userProfile.email}`,
          api_base: 'https://api-iam.intercom.io',
        });

        dispatch(
          updateIntercom({
            isFirstBootComplete: true,
          })
        );
      }
      // @ts-ignore:next-line
      window.intercomSettings = {
        email: `${userProfile.email}`,
        app_id: intercomData.app_id,
        cms: intercomData.cms,
        cmsdatafilled: intercomData.cmsdatafilled,
        psp: intercomData.psp,
        pspdatafilled: intercomData.pspdatafilled,
      };

      console.log('intercom updating...');
      // @ts-ignore:next-line
      window.Intercom('update', {
        app_id: intercomData.app_id,
        name: `${userProfile.first_name} ${userProfile.last_name}`,
        email: `${userProfile.email}`,
        api_base: 'https://api-iam.intercom.io',
      });
    } else {
      console.log(
        'intercom disabled for merchant : ',
        merchantTheme?.merchant_name
      );
    }
    // @ts-ignore:next-line
    /* console.log(window.intercomSettings); */
  }, [
    userProfile,
    merchantTheme,
    accessToken?.sellerToken,
    intercomData,
    dispatch,
  ]);
  /* -------------run intercom when loggedin user data loads code end--------------- */

  const logoutHandler = () => {
    tokenRevokeAtLogOutUser();
    // @ts-ignore:next-line
    window.Intercom('shutdown'); //shutdown intercom
    dispatch(clearUserSlice());
    dispatch(clearAuthSlice());
    window.localStorage.clear();
    navigate(
      window?.location?.pathname === '/register' ? '/register' : '/login'
    );
  };

  const renderHeader = () => {
    if (
      accessToken?.sellerToken ||
      accessToken?.adminToken ||
      accessToken?.merchantToken
    ) {
      return (
        <NewHeader
          isSidebarDrawerOpen={isSidebarDrawerOpen}
          toggleSidebarDrawer={toggleSidebarDrawer}
        />
      );
    }

    return null;
  };

  return (
    <div data-is-white-label={isWhiteLabel?.toString()} className='App'>
      <AnimatePresence>
        {message.messageType && (
          <Message msgType={message.messageType} msg={message.message} />
        )}
        {isOnboardingCompleteBannerOpen && <OnboardingCompleteBanner />}
      </AnimatePresence>

      {renderHeader()}

      <Routes>
        <Route
          path='/'
          element={
            !isInitSuccess ? <SplashScreen /> : <Navigate to={'/login'} />
          }
        />
        <Route
          path='/register'
          element={
            !accessToken?.sellerToken && !accessToken?.adminToken ? (
              <SignUpPage />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/shop'} />
            )
          }
        />
        <Route
          path='/login'
          element={
            !accessToken?.sellerToken &&
            !accessToken?.adminToken &&
            !accessToken?.merchantToken ? (
              <LoginPage />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : accessToken?.merchantToken ? (
              <Navigate to={'/merchant'} />
            ) : (
              <Navigate to={'/shop'} />
            )
          }
        />
        <Route
          path='/forgot-password'
          element={
            !accessToken?.sellerToken && !accessToken?.adminToken ? (
              <ForgotPassword />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/shop'} />
            )
          }
        />
        <Route
          path='/reset-password'
          element={
            !accessToken?.sellerToken && !accessToken?.adminToken ? (
              <ResetPassword />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/shop'} />
            )
          }
        />
        {/* <Route path='/dashboard' element={<Dashboard />} /> */}
        <Route
          path='/onboarding'
          element={
            accessToken?.sellerToken || googleToken ? (
              <Onboarding />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        {/* <Route
          path='/dashboard'
          element={
            subscriptionLock && subscriptionLock === 'true' ? (
              <Navigate to={'/settings/subscription'} />
            ) : accessToken?.sellerToken ? (
              <NewDashboard />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        /> */}
        <Route
          path='/products/*'
          element={
            subscriptionLock && subscriptionLock === 'true' ? (
              <Navigate to={'/settings/subscription'} />
            ) : accessToken?.sellerToken || isWhiteLabel ? (
              <ProductsPage />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        {/* <Route
          path='/products/:productId'
          element={
            accessToken?.sellerToken ? <ProductDetailPage /> : <Navigate to={'/login'} />
          }
        /> */}
        <Route
          path='/orders/*'
          element={
            subscriptionLock && subscriptionLock === 'true' ? (
              <Navigate to={'/settings/subscription'} />
            ) : accessToken?.sellerToken || isWhiteLabel ? (
              <OrdersPage />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        <Route
          path='/settings/*'
          element={
            accessToken?.sellerToken || googleToken || isWhiteLabel ? (
              <SettingsPage />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        <Route
          path='/integrations/*'
          element={
            accessToken?.sellerToken || googleToken || isWhiteLabel ? (
              <IntegrationsPage />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        <Route
          path='/shop/*'
          element={
            accessToken?.sellerToken || googleToken || isWhiteLabel ? (
              <ShopPage />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        <Route
          path='/plugin-connect'
          element={
            subscriptionLock && subscriptionLock === 'true' ? (
              <Navigate to={'/settings/subscription'} />
            ) : accessToken?.sellerToken ? (
              <PluginAuthPage />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        <Route
          path='/admin/*'
          element={
            accessToken?.adminToken ? (
              <AdminHomePage />
            ) : accessToken?.sellerToken ? (
              <Navigate to={'/shop'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        <Route
          path='/merchant/*'
          element={
            accessToken?.merchantToken ? (
              <MerchantHomePage />
            ) : accessToken?.sellerToken ? (
              <Navigate to={'/shop'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />

        <Route
          path='/email-verification-success'
          element={<EmailVerificationSuccess />}
        />
        <Route path='/unsubscribe' element={<Unsubscribe />} />
        <Route
          path='/stripe-subscription-success'
          element={
            accessToken?.sellerToken ? (
              <StripeSubscriptionSuccess />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        <Route
          path='/stripe-subscription-failed'
          element={
            accessToken?.sellerToken ? (
              <StripeSubscriptionFailed />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        <Route
          path='/aduppay/onboarding'
          element={
            accessToken?.sellerToken ? (
              <AdUpPayOnboardingSuccess />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        <Route
          path='/mollie/onboarding'
          element={
            accessToken?.sellerToken ? (
              <MollieOnboardingSuccess />
            ) : accessToken?.adminToken ? (
              <Navigate to={'/admin'} />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />
        <Route path='/authorize' element={<WhiteLabel />} />
        <Route path='*' element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
