/* ------api imports------- */
import store from '../../store/store';
import { applicationWWWXRLEncodedBodyBuilder } from '../utils/body-builder';
import { ONBOARDING_EVENTS_ENDPOINTS } from '../constants/urls';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from './async-handler';

/* ------api connection------- */
export async function submitOnboardingStatus(
  optionType: 'psp' | 'cms' | 'onboarding_completed',
  optionValue: string
) {
  let actionName = '';
  let message = 'Done';
  let sendingData;

  if (optionType === 'psp') {
    actionName = 'SUBMIT_SELECTED_PSP';
    sendingData = { psp: optionValue };
  } else if (optionType === 'cms') {
    actionName = 'SUBMIT_SELECTED_CMS';
    sendingData = { cms: optionValue };
  } else {
    actionName = 'SUBMIT_ONBOARDING_COMPLETED';
    sendingData = { completed: optionValue };
  }

  try {
    const accessToken = store.getState().auth.authorization.accessToken;

    /*  */
    const requestInfo: RequestInfo = {
      url: ONBOARDING_EVENTS_ENDPOINTS,
      method: 'POST',
      body: applicationWWWXRLEncodedBodyBuilder(sendingData),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
        Authorization: accessToken.type + ' ' + accessToken.sellerToken,
      },
    };

    const actionType = actionName;
    const customMsg = message;

    const res: any = await asyncHandler(
      requestInfo,
      actionType,
      customMsg,
      false
    );

    const response = res.data;

    if (res.actionType === actionType && response.success === true) {
      return true;
    } else {
      return null;
    }
  } catch (exception) {
    console.log('Error during request');
  }
}
