import SubPageList from '../../../../components/subPageList/SubPageList';
import { editSVG, trashSVG } from '../../../../lib/allCustomSVGs';
import { useState, useEffect } from 'react';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const ListPSP = () => {
  const origin = window.location.origin;
  const [subPageListItems, setSubPageListItems] = useState([]);
  const { t } = useTranslation();
  const alreadyAddedPSP = useSelector(
    (state: RootState) => state.user.company.alreadyAddedPSP
  ) as any;
  const staticURL = useSelector((state: RootState) => state.app.staticURL);

  const subPageDropdownItems = [
    {
      title: t('Edit'),
      subTitle: t('EditPSP-Connection'),
      id: '1',
      icon: editSVG,
      url: '/shop/psp/connection/edit',
    },
    {
      title: t('Delete'),
      subTitle: t('DeletePSP-Connection'),
      id: '2',
      icon: trashSVG,
      url: '/shop/psp/connection/delete',
    },
  ];

  //creating initial state for each list item
  const initialEditButtonState: any = {};

  subPageListItems?.map((item: any) => {
    initialEditButtonState[item?.id] = false;
  });

  const onListItemClick = () => {
    //on list dropdown item click, make dropdown open states to initial state
    setIsListMenuOpen(initialEditButtonState);
  };

  const [isListMenuOpen, setIsListMenuOpen] = useState(initialEditButtonState);

  const onListMenuClick = (itemId: any) => {
    setIsListMenuOpen((isListMenuOpen: any) => ({
      ...isListMenuOpen,
      [itemId]: !isListMenuOpen[itemId],
    }));
  };

  useEffect(() => {
    let finalArray: any = [];
    if (alreadyAddedPSP.length > 0) {
      for (const i of alreadyAddedPSP) {
        let method = {
          id: alreadyAddedPSP.indexOf(i),
          title: i?.label ?? '',
          img: `${staticURL}/medium/payment-icons/${i.gateway_name}_logo.png`, //! image should have the exact gateway_name from API
        };
        finalArray.push(method);
      }
    }
    setSubPageListItems(finalArray);
  }, [alreadyAddedPSP]);

  return (
    <SubPageList
      onListMenuClick={onListMenuClick}
      onListItemClick={onListItemClick}
      isListMenuOpen={isListMenuOpen}
      items={subPageListItems}
      dropdownItems={subPageDropdownItems}
    />
  );
};

export default ListPSP;
