import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { OnboardingStagesMap } from '../../store/reducers/userSlice';
import { RootState } from '../../store/store';
import '../../styles/components/onboarding/onboardingNavProgressBar.scss';
import ProgressBar from '../../ui/progressBar/ProgressBar';

const OnboardingNavProgressBar = () => {
  const onboardingState = useSelector(
    (state: RootState) => state.user.onboarding
  );

  const [progress, setProgress] = useState(0);
  const max = useRef(Object.keys(onboardingState.stagesCompleted).length - 2);
  useEffect(() => {
    let totalProgress = 0;
    for (const stage in onboardingState.stagesCompleted) {
      if (onboardingState.stagesCompleted[stage]) totalProgress++;
    }

    // const ratio = (totalProgress / max.current);
    setProgress(totalProgress + 0);
  }, [onboardingState]);

  if (
    onboardingState.currentStage === OnboardingStagesMap.SigningIn.name ||
    onboardingState.currentStage === OnboardingStagesMap.SigningUp.name ||
    onboardingState.currentStage === null
  ) {
    return <></>;
  }

  return (
    <div className='onboardingNavProgressBar'>
      <ProgressBar currentLevel={progress} steps={max.current} />
    </div>
  );
};

export default OnboardingNavProgressBar;
