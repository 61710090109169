import ProgressBar from '../../ui/progressBar/ProgressBar';
import '../../styles/components/productWizard/wizardNavbar.scss';
import { useTranslation } from 'react-i18next';

interface Props {
  currentStep: number;
}

const WizardNavbar = ({ currentStep }: Props) => {
  const totalSteps = 7;
  const { t } = useTranslation();

  return (
    <div className='wizardNavbar'>
      <div className='wizardNavbar__content'>
        <div className='wizardNavbar__content--left'>
          <div className='wizardNavbar__content--left-dropdown '>
            <div className='wizardNavbar__content--left-dropdown-text'>
              <h2>{`${t('Step')} ${currentStep} / ${totalSteps}`}</h2>
              <p>
                {currentStep === 1
                  ? `(${t('1-Minute-Remaining')})`
                  : `(${t('LessThan-Minute-Remaining')})`}
              </p>
            </div>
            {/*  <div
              onClick={() => {
                setStepsOpen(!stepsOpen);
              }}
              className='wizardNavbar__content--left-dropdown-button '
            >
              <CustomSVGs svg={arrowHeadLeftSVG} />
            </div> */}
            {/* {stepsOpen && <StepsDropdown currentLevel={currentLevel} />} */}
          </div>
        </div>
        <div className='wizardNavbar__content--right'>
          {/* <p>
            {currentStep === 1
              ? '(1 minute remaining)'
              : '(Less than a minute remaining)'}
          </p> */}
        </div>
      </div>

      <ProgressBar steps={7} currentLevel={currentStep} />
    </div>
  );
};

export default WizardNavbar;
