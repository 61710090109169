//api call imports
import { PRODUCTS_ENDPOINT } from '../../lib/constants/urls';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
// import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import store from '../../store/store';

//other imports
import { useEffect, useState } from 'react';
import NormalInput from '../../ui/inputs/NormalInput';
import NormalSelectInput from '../../ui/inputs/NormalSelectInput';
import TextArea from '../../ui/inputs/TextArea';
import { AnimatePresence, motion } from 'framer-motion';
import {
  productInitialState,
  VariantAttributesInterface,
} from '../../lib/initialStates/productPagesInitialState';
import AddProductAttributes from '../../pages/products/addProduct/AddProductAttributes';
import MultipleImagesInput from '../../ui/inputs/MultipleImagesInput';
import MaskedCurrencyInput from '../../ui/inputs/CurrencyInput';
import { useTranslation } from 'react-i18next';
import '../../styles/components/productWizard/wizardSteps.scss';
import WizardButtonPanel from './WizardButtonPanel';
import convertAttributesArrayToKeyValues from '../../lib/helper-functions/convertAttributesArrayToKeyValues';
import convertStringAttritubtesToObject from '../../lib/helper-functions/convertStringAttritubtesToObject';
import generateVariants from '../../lib/helper-functions/generateVariants';
import generateSKU from '../../lib/helper-functions/generateSKU';
import {
  trimFromStartToLimitedNumber,
  getOnlyFirstLettersOfAttributes,
} from '../../lib/helper-functions/trimSKU';
import TextAreaAI from '../../ui/inputs/TextAreaAI';

interface Props {
  currentStep: number;
  increaseStep: () => void;
  decreaseStep: () => void;
  setAddedProductSKU: (input: any) => void;
}

const WizardSteps = ({
  currentStep,
  increaseStep,
  decreaseStep,
  setAddedProductSKU,
}: Props) => {
  const { t } = useTranslation();

  const stepsData = [
    { step: 1, text: t('wizardStep-1-Text') },
    { step: 2, text: t('wizardStep-2-Text') },
    { step: 3, text: t('wizardStep-3-Text') },
    { step: 4, text: t('wizardStep-4-Text') },
    { step: 5, text: t('wizardStep-5-Text') },
    { step: 6, text: t('wizardStep-6-Text') },
    { step: 7, text: t('wizardStep-7-Text') },
  ];
  const currentLevelText = stepsData.filter((el) => el.step === currentStep)[0]
    ?.text;

  const [loading, setLoading] = useState(false);

  /* product state */
  const [product, setProduct] = useState(productInitialState);
  const [productVariantsArr, setProductVariantsArr] = useState<any[]>([]);
  const [productType, setProductType] = useState('simple');

  const [generatedSKU, setGeneratedSKU] = useState<string>('');

  useEffect(() => {
    setGeneratedSKU(
      trimFromStartToLimitedNumber(generateSKU(product.productName), 20)
    );
  }, [product.productName]);

  /* Product images state */
  const [selectedProductImages, setSelectedProductImages] = useState([]);
  const [previewProductImages, setPreviewProductImages] = useState([]);

  let isPrice;
  if (product?.productPrice === '' && productType === 'simple') {
    isPrice = true;
  } else if (product?.productPrice === '' && productType === 'variable') {
    isPrice = false;
  }

  let isDisabled =
    product.productName === '' ||
    product.productDescription === '' ||
    product.productSKU === '' ||
    isPrice ||
    product.productStock === ''
      ? true
      : false;

  /* product types select options array */
  const productTypesArr = [
    { OptionValue: 'simple', OptionName: 'No' },
    { OptionValue: 'variable', OptionName: 'Yes' },
  ];

  //Variant Attributes state
  const [variantAttributes, setVariantAttributes] = useState<
    VariantAttributesInterface[]
  >([]);

  const copyDataFromProduct = true;

  /* ----------------------------API CALL ADD VARIANTS START---------------------------- */
  async function addVarinatsAsync(
    accessToken: any,
    sellerToken: any,
    productId: string,
    variant: any
  ) {
    const sendindAttributes =
      convertAttributesArrayToKeyValues(variant?.variantAttributes) || 'null';
    const formData = new FormData();
    formData.append('name', variant?.productVariants?.productVariantName);
    formData.append(
      'description',
      variant?.productVariants?.productVariantDescription
    );
    formData.append('status', variant?.productVariantStatus);
    formData.append('sku', variant?.productVariants?.productVariantSKU);
    formData.append('price', variant?.productVariants?.productVariantPrice);
    formData.append('stock', variant?.productVariants?.productVariantStock);
    formData.append('attributes', sendindAttributes);
    formData.append('type', 'variable');
    if (
      variant?.productVariantImages?.selectedProductVariantImages?.length > 0
    ) {
      variant?.productVariantImages?.selectedProductVariantImages?.forEach(
        (item: any) => {
          formData.append('media[]', item);
        }
      );
    }
    try {
      const requestInfo: RequestInfo = {
        url: `${PRODUCTS_ENDPOINT}/${productId}/variants`,
        method: 'POST',
        body: formData,
        headers: {
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'ADD_PRODUCT_VARIANT';
      const customMsg = 'Product Variant added Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return 1;
      }
    } catch (exception) {
      console.log('Error during request');
    }
  }
  /* ----------------------------API CALL ADD VARIANTS END---------------------------- */

  /* ----------------------------API CALL ADD PRODUCT START---------------------------- */
  async function onSubmitHandler() {
    try {
      const accessToken = store.getState().auth.authorization.accessToken;
      const isWhiteLabel = store.getState().UI?.isWhiteLabel;
      let sellerToken: string;
      if (isWhiteLabel) {
        sellerToken = accessToken?.currentWhiteLabelsellerToken;
      } else {
        sellerToken = accessToken?.sellerToken;
      }

      setAddedProductSKU(
        product?.productSKU === '' ? generatedSKU : product?.productSKU
      );

      const formData = new FormData();
      formData.append('name', product?.productName);
      formData.append('description', product?.productDescription);
      formData.append(
        'sku',
        product?.productSKU === '' ? generatedSKU : product?.productSKU
      );
      formData.append('price', product?.productPrice);
      formData.append('stock', product?.productStock);
      formData.append('type', productType);
      if (selectedProductImages?.length > 0) {
        selectedProductImages?.forEach((item: any) => {
          formData.append('media[]', item);
        });
      }

      const requestInfo: RequestInfo = {
        url: PRODUCTS_ENDPOINT,
        method: 'POST',
        body: formData,
        headers: {
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'ADD_PRODUCT';
      const customMsg = t('ProductAddedSuccessfully-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        if (res.actionType === actionType && response.success) {
          let variantSuccessCount = 0;
          productVariantsArr.forEach(async (el: any, index: number) => {
            const result = response?.result;
            const productId = result?.id;
            const variantResult = await addVarinatsAsync(
              accessToken,
              sellerToken,
              productId,
              el
            );
            if (variantResult) {
              variantSuccessCount++;
            }
            if (productVariantsArr?.length === variantSuccessCount) {
              setLoading(false);
              increaseStep();
            }
            if (
              index + 1 === productVariantsArr?.length &&
              productVariantsArr?.length !== variantSuccessCount
            ) {
              setLoading(false);
              console.log(
                'Something went wrong all the variants were not created!'
              );
            }
          });

          if (productType === 'simple') {
            setLoading(false);
            increaseStep();
          }
        }
      }
    } catch (exception) {
      console.log('Error during request');
    }
  }
  /* ----------------------------API CALL ADD PRODUCT END---------------------------- */

  const createVariantObject = (possibleVariantsList: string[][]) => {
    if (possibleVariantsList.length > 0) {
      const output = possibleVariantsList[0].map(
        (item: string, index: number) => {
          const firstLettersOfAttributes =
            getOnlyFirstLettersOfAttributes(item);
          if (copyDataFromProduct) {
            const filledVariantObj = {
              productVariants: {
                productVariantName:
                  product.productName + '-' + item?.replaceAll('~~', '-'),
                productVariantDescription:
                  product.productDescription +
                  '-' +
                  item?.replaceAll('~~', '-'),
                productVariantSKU:
                  product.productSKU === ''
                    ? trimFromStartToLimitedNumber(
                        generateSKU(
                          generatedSKU + '-' + firstLettersOfAttributes
                        ),
                        20
                      )
                    : trimFromStartToLimitedNumber(
                        generateSKU(
                          product.productSKU + '-' + firstLettersOfAttributes
                        ),
                        20
                      ),
                productVariantPrice: product.productPrice,
                productVariantStock: product.productStock,
              },
              productVariantStatus: 'active',
              productVariantImages: {
                selectedProductVariantImages: selectedProductImages,
                previewProductVariantImages: previewProductImages,
              },
              variantAttributes: convertStringAttritubtesToObject(
                possibleVariantsList[1][index]
              ),
            };

            return filledVariantObj;
          } else {
            const unfilledVariantObj = {
              productVariants: {
                productVariantName: '',
                productVariantDescription: '',
                productVariantSKU: '',
                productVariantPrice: '',
                productVariantStock: '',
              },
              productVariantStatus: '',
              productVariantImages: {
                selectedProductVariantImages: [],
                previewProductVariantImages: '',
              },
              variantAttributes: convertStringAttritubtesToObject(
                possibleVariantsList[1][index]
              ),
            };

            return unfilledVariantObj;
          }
        }
      );

      setProductVariantsArr(output);
    }
  };

  const getAllPossibleVariants = () => {
    createVariantObject(generateVariants(variantAttributes));
  };

  useEffect(() => {
    if (productVariantsArr?.length > 0) {
      onSubmitHandler();
    }
  }, [productVariantsArr]);

  const btnPanelDisableHandler = () => {
    if (currentStep === 1 && product.productName !== '') {
      return false;
    }
    if (currentStep === 2 && product.productDescription !== '') {
      return false;
    }
    if (currentStep === 3 && product.productPrice !== '') {
      return false;
    }
    if (currentStep === 4 /* && product.productSKU !== '' */) {
      return false;
    }
    if (currentStep === 5 && product.productStock !== '') {
      return false;
    }
    if (currentStep === 6) {
      return false;
    }
    if (currentStep === 7 && productType === 'simple') {
      return false;
    }
    if (
      currentStep === 7 &&
      variantAttributes[0]?.value?.length > 0 &&
      productType === 'variable'
    ) {
      return false;
    }

    return true;
  };

  return (
    <>
      <div className='wizardSteps'>
        {/* ------------------------------------------------------- */}

        <div className={`wizardSteps__item ${currentStep === 1 ? '' : 'hide'}`}>
          <div className='wizardSteps__item--text'>
            <h2>{currentLevelText}</h2>
          </div>
          <NormalInput
            changeListeners={[
              (e) =>
                setProduct((prev) => {
                  return { ...prev, productName: e.target.value };
                }),
            ]}
            id='add-product-name'
            value={product.productName}
            placeholder={t('ProductName')}
            required={true}
            title={t('ProductName-validation')}
            autoFocus={true}
          />
        </div>

        {/* ------------------------------------------------------- */}

        {currentStep === 2 && (
          <div className={`wizardSteps__item`}>
            <div className='wizardSteps__item--text'>
              <h2>{currentLevelText}</h2>
            </div>
            <TextAreaAI
              id='add-product-description'
              productDescription={product.productDescription}
              setProductDescription={(description) =>
                setProduct((prevProduct) => ({
                  ...prevProduct,
                  productDescription: description,
                }))
              }
              placeholder={t('ProductDescription')}
              required={true}
              title={t('ProductDescription-validation')}
              textForAI={product.productName}
              autoFocus={true}
            />
          </div>
        )}

        {/* ------------------------------------------------------- */}

        {currentStep === 3 && (
          <div
            className={`wizardSteps__item ${currentStep === 3 ? '' : 'hide'}`}
          >
            <div className='wizardSteps__item--text'>
              <h2>{currentLevelText}</h2>
            </div>
            <MaskedCurrencyInput
              changeListeners={[
                (value: any) =>
                  setProduct((prev: any) => {
                    return { ...prev, productPrice: value };
                  }),
              ]}
              id='add-product-price'
              value={
                product.productPrice && !Number.isNaN(product.productPrice)
                  ? product.productPrice
                  : '0'
              }
              defaultValue={
                product.productPrice && !Number.isNaN(product.productPrice)
                  ? product.productPrice
                  : '0'
              }
              placeholder={t('Price')}
              required={true}
              title={t('OnlyNumbers-validation')}
              type='text'
              pattern='^[\d|\.|\,]+/'
              autoFocus={true}
              // defaultValue='0,00'
            />
          </div>
        )}

        {/* ------------------------------------------------------- */}

        {currentStep === 4 && (
          <div className={`wizardSteps__item `}>
            <div className='wizardSteps__item--text'>
              <h2>{currentLevelText}</h2>
            </div>
            <NormalInput
              changeListeners={[
                (e) =>
                  setProduct((prev) => {
                    return { ...prev, productSKU: e.target.value };
                  }),
              ]}
              id='add-product-sku'
              value={product.productSKU}
              placeholder='SKU'
              /* required={true} */
              pattern='[^ ]{1,20}$'
              title={t('ProductSKU-validation')}
              innerPlaceholder={generatedSKU}
              autoFocus={true}
              maxlength={20}
            />
          </div>
        )}

        {/* ------------------------------------------------------- */}

        {currentStep === 5 && (
          <div
            className={`wizardSteps__item ${currentStep === 5 ? '' : 'hide'}`}
          >
            <div className='wizardSteps__item--text'>
              <h2>{currentLevelText}</h2>
            </div>
            <NormalInput
              changeListeners={[
                (e) =>
                  setProduct((prev) => {
                    return { ...prev, productStock: e.target.value };
                  }),
              ]}
              id='add-product-stock'
              value={product.productStock}
              placeholder={t('Stock')}
              required={true}
              pattern='^[0-9]{1,10}$'
              title={t('ProductStock-validation')}
              type='number'
              autoFocus={true}
            />
          </div>
        )}

        {/* ------------------------------------------------------- */}

        <div className={`wizardSteps__item ${currentStep === 6 ? '' : 'hide'}`}>
          <div className='wizardSteps__item--text'>
            <h2>{currentLevelText}</h2>
          </div>

          <MultipleImagesInput
            setSendingFiles={setSelectedProductImages}
            setSendingFilesPreviews={setPreviewProductImages}
            labelName={t('ProductMedia')}
            acceptedFileTypes={'.png, .jpeg, .webp, .mp4, .gif'}
            id={'add-product-images'}
            manyImages={true}
          />
        </div>

        {/* ------------------------------------------------------- */}

        <div
          className={`wizardSteps__item--type ${
            currentStep === 7 ? '' : 'hide'
          }`}
        >
          <div className='wizardSteps__item--text'>
            <h2>{currentLevelText}</h2>
          </div>
          <div className='wizardSteps__item--content'>
            <NormalSelectInput
              defaultValue={productType}
              setSelect={setProductType}
              selectOptionArr={productTypesArr}
              placeholder={t('AddVariants')}
              // placeholder={t('Type')}
              id={'add-product-type'}
            />
            <div
              style={{
                display: `${productType === 'variable' ? 'block' : 'none'}`,
              }}
            >
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className='settingsShippingOptions__bottom'
                >
                  <AddProductAttributes
                    variantAttributes={variantAttributes}
                    setVariantAttributes={setVariantAttributes}
                  />
                </motion.div>
              </AnimatePresence>
              {/* ------------------------------------ */}
            </div>
          </div>
        </div>
        {/* ------------------------------------------------------- */}
      </div>

      <WizardButtonPanel
        currentStep={currentStep}
        increaseStep={increaseStep}
        decreaseStep={decreaseStep}
        isDisabled={btnPanelDisableHandler()}
        loading={loading}
        handlerFunction={() => {
          if (currentStep === 7 && productType === 'simple') {
            setLoading(true);
            onSubmitHandler();
          } else if (currentStep === 7 && productType === 'variable') {
            setLoading(true);
            getAllPossibleVariants();
          } else {
            increaseStep();
          }
        }}
      />
    </>
  );
};

export default WizardSteps;
