import SubPageContentHolder from '../../../components/subPageContentHolder/SubPageContentHolder';
import { Route, Routes } from 'react-router-dom';
import PSPConnection from './pspConnection/PSPConnection';
import PSPOptions from './pspOptions/PSPOptions';
import { useTranslation } from 'react-i18next';

const PSP = () => {
  const { t } = useTranslation();

  return (
    <div className='subPage'>
      <SubPageContentHolder>
        <Routes>
          <Route path='connection/*' element={<PSPConnection />} />
          <Route path='options/*' element={<PSPOptions />} />
        </Routes>
      </SubPageContentHolder>
    </div>
  );
};

export default PSP;
