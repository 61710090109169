import { useState, useEffect } from 'react';
import '../../styles/pages/whiteLabel.scss';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { SELLER_AUTH_ENDPOINT } from '../../lib/constants/urls';
import { applicationJSONBodyBuilder } from '../../lib/utils/body-builder';
import { asyncHandler } from '../../lib/api/async-handler';
import store from '../../store/store';
import { setMessage } from '../../store/reducers/appSlice';
import { setIsWhiteLabel } from '../../store/reducers/uiSlice';
import { setAccessToken } from '../../store/reducers/authSlice';
import { useNavigate } from 'react-router-dom';

const WhiteLabel = () => {
  const navigate = useNavigate();
  const [seller, setSeller] = useState<any>({});
  const [sellerToken, setsellerToken] = useState<string>();
  const accessToken = store.getState().auth.authorization.accessToken;
  const availableWhiteLabelSellerTokens = accessToken.whiteLabelSellerTokens;

  useEffect(() => {
    if (window.location === window.parent.location) {
      console.log('--INVALID parent window ❌--');
      store.dispatch(setIsWhiteLabel(false));
      navigate('/');
    }
    function receiveHash(event: any) {
      //TODO: Need PSP domains : Check the origin of the message to ensure it's from a trusted source
      // if (event.origin !== "https://parent-domain.com") {
      //   return;
      // }

      // Process the message
      setSeller(event.data);
    }
    window.addEventListener('message', receiveHash, false);
    return () => {
      window.removeEventListener('message', receiveHash);
    };
  }, []);

  useEffect(() => {
    try {
      if (
        seller &&
        seller?.hash &&
        seller?.page &&
        seller?.email &&
        seller?.origin
      ) {
        console.log('--HASH :: 💡', seller?.hash);
        console.log('--PAGE :: 💡', seller?.page);
        console.log('--EMAIL :: 💡', seller?.email);
        console.log('--Seller Origin :: 💡', seller?.origin);

        authenticate();
      }
    } catch (error) {
      console.log(error);
    }
  }, [seller]);

  useEffect(() => {
    if (seller?.origin && seller?.origin !== '') {
      setsellerToken(availableWhiteLabelSellerTokens[seller?.origin]);
      console.log(
        '--Seller Token :: 💡',
        availableWhiteLabelSellerTokens[seller?.origin]
      );
    }
  }, [seller?.origin]);

  async function authenticate() {
    try {
      const requestInfo: RequestInfo = {
        url: SELLER_AUTH_ENDPOINT,
        method: 'POST',
        body: applicationJSONBodyBuilder({
          domain: seller?.origin,
          email: seller?.email,
          hash: seller?.hash,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'SELLER_AUTH_VERIFICATION';
      const customMsg = 'Seller Authentication Successful.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success === true) {
        const { auth_key, user } = response.result;

        if (seller?.origin && auth_key && auth_key !== '') {
          store.dispatch(setIsWhiteLabel(true));
          store.dispatch(
            setAccessToken({
              ...accessToken,
              whiteLabelSellerTokens: {
                ...accessToken?.whiteLabelSellerTokens,
                [seller?.origin]: auth_key,
              },
              currentWhiteLabelsellerToken: auth_key,
            })
          );
          if (seller?.page) {
            navigate(seller?.page);
          }
        }
      } else {
        store.dispatch(
          setMessage({
            message: response.message,
            messageType: 'error',
          })
        );
        console.log(response);
      }
    } catch (exception) {
      store.dispatch(
        setMessage({
          message: 'Authorization failed',
          messageType: 'error',
        })
      );
      console.log('Error during authentication request');
    }
  }

  return (
    <div className='container'>
      <div className='loadingSpinner__roller'>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default WhiteLabel;
