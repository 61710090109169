import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../../styles/generic/normalForm.scss';
import NormalInput from '../../../ui/inputs/NormalInput';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import store from '../../../store/store';
import { WEBHOOK_ENDPOINT } from '../../../lib/constants/urls';
import { asyncHandler } from '../../../lib/api/async-handler';
import ToggleSwitch from '../../../ui/buttons/ToggleSwitch';
import NoData from '../../../ui/noData/NoData';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import { eyeSVG } from '../../../lib/allCustomSVGs';
import Popup from '../../../ui/popup/Popup';
import TextArea from '../../../ui/inputs/TextArea';
import { useTranslation } from 'react-i18next';

const ViewWebhook = () => {
  const { webhookId } = useParams();
  const [loading, setLoading] = useState('pending');
  const [isLogViewPopupOpen, setIsLogViewPopupOpen] = useState(false);
  const [name, setName] = useState<string>(``);
  const [method, setMethod] = useState<string>(``);
  const [endpoint, setEndpoint] = useState<string>(``);
  const [status, setStatus] = useState<boolean>();
  const [logs, setLogs] = useState<any>();
  const [popupData, setPopupData] = useState<any>();
  const { t } = useTranslation();

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'log id', width: 70 },
    { field: 'created_at', headerName: 'created date', width: 160 },
    { field: 'updated_at', headerName: 'modified date', width: 160 },
    {
      field: 'success',
      headerName: t('status'),
      width: 100,
      renderCell: (params) => {
        return (
          <div className=''>
            {params.row.success && (
              <span>
                {params.row.success === 1 ? (
                  <>
                    <div className='flex-row'>
                      <div
                        style={{ backgroundColor: '#28a745' }}
                        className='customTable__top--column-rows-textholder-changeableField'
                      />
                      <span style={{ paddingLeft: 8 }}>active</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='flex-row'>
                      <div
                        style={{ backgroundColor: '#dc3545' }}
                        className='customTable__top--column-rows-textholder-changeableField'
                      />
                      <span style={{ paddingLeft: 8 }}>inactive</span>
                    </div>
                  </>
                )}
              </span>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const data = getWebhookData();
    data
      .then((result) => {
        setName(result.name ?? '');
        setMethod(result.method ?? 'GET');
        setEndpoint(result.endpoint ?? '');
        setStatus(result.status ? Boolean(result.status) : false);
        setLogs(result.logs);
        setLoading('success');
      })
      .catch((err) => {
        console.error(err);
        setLoading('error');
      });
  }, []);

  const toggleLogViewPopupModal = (id: any) => {
    var selectedLog = logs.find((i: { id: any }) => i.id === id);
    setPopupData(selectedLog);
    setIsLogViewPopupOpen(!isLogViewPopupOpen);
  };

  /* ACTION COLUMN FOR DATA TABLE START */
  const actionColumn = [
    {
      field: 'action',
      headerName: t('Action'),
      width: 100,
      renderCell: (params: any) => {
        return (
          <div className='cellAction'>
            <div
              onClick={() => {
                toggleLogViewPopupModal(params?.row?.id);
              }}
              className='datatableActionBtn press editButton'
            >
              <CustomSVGs svg={eyeSVG} />
              <span>{t('View')}</span>
            </div>
          </div>
        );
      },
    },
  ];
  /* -ACTION COLUMN FOR DATA TABLE END- */

  /* ----------------------------API CALL START---------------------------- */
  async function getWebhookData() {
    try {
      const accessToken = store.getState().auth.authorization.accessToken;
      const isWhiteLabel = store.getState().UI?.isWhiteLabel;
      let sellerToken: string;
      if (isWhiteLabel) {
        sellerToken = accessToken?.currentWhiteLabelsellerToken;
      } else {
        sellerToken = accessToken?.sellerToken;
      }

      const requestInfo: RequestInfo = {
        url: `${WEBHOOK_ENDPOINT}/${webhookId}`,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_SINGLE_WEBHOOK';
      const customMsg = 'Webhook data loaded Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return response.result;
      }
    } catch (exception) {
      console.log('Error during request');
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'error' && (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      )}
      {loading === 'success' && (
        <>
          <form id='webhookView-form' className='normalForm'>
            <div className='normalForm__top'>
              {/* <h1>Add the Following Information.</h1> */}

              <NormalInput
                id='view-webhook-name'
                value={name}
                placeholder={t('Name')}
                readonly={true}
              />

              <NormalInput
                id='view-webhook-method'
                value={method}
                placeholder={t('Method')}
                readonly={true}
              />

              <NormalInput
                id='view-webhook-endpoint'
                value={endpoint}
                placeholder={t('Endpoint')}
                readonly={true}
              />
              <br />
              <ToggleSwitch isOn={status} title={t('Status')} />
              <br />
              {logs && logs?.length > 0 && (
                <div className='subPage__container'>
                  <div className='subPage__table--container sync-history-table-height'>
                    <DataGrid
                      sx={{
                        /* boxShadow: '0px 3px 10px -6px rgba(0, 0, 0, 0.84);', */
                        border: 'none',
                        '& .MuiDataGrid-cell:hover': {
                          color: 'primary.main',
                        },
                      }}
                      rows={logs}
                      getRowHeight={() => 'auto'}
                      disableSelectionOnClick
                      getRowId={(log) => log?.id}
                      columns={columns.concat(actionColumn)}
                      paginationMode={'client'}
                      pageSize={10}
                      rowsPerPageOptions={[10]}
                      style={{ fontSize: '1.5rem' }}
                    />
                  </div>
                </div>
              )}
            </div>
          </form>
          <Popup
            styles={{ backgroundColor: '#fff' }}
            setTrigger={() => {
              setIsLogViewPopupOpen(!isLogViewPopupOpen);
            }}
            trigger={isLogViewPopupOpen}
          >
            <TextArea
              id='edit-product-description'
              value={JSON.stringify(popupData?.request)}
              placeholder={t('Request')}
              readonly={true}
            />
            <TextArea
              id='edit-product-description'
              value={JSON.stringify(popupData?.response)}
              placeholder={t('Response')}
              readonly={true}
            />
          </Popup>
        </>
      )}
    </>
  );
};

export default ViewWebhook;
