const getFilterString = (filterItems: any) => {
  let string = '';
  let arr: any = [];
  for (let i = 0; i < filterItems?.length; i++) {
    const currentEl = filterItems[i];
    const filtersArr = currentEl?.filters;
    arr.push({ para: currentEl?.parameterName, filter: [] });
    for (let j = 0; j < filtersArr?.length; j++) {
      const currentItem = filtersArr[j];
      if (arr[i].para === currentEl?.parameterName && currentItem?.value) {
        arr[i]?.filter?.push(currentItem?.parameterName);
      }
      /* if (currentItem?.value && currentItem?.parameterName !== 'none') {
          const currentString = `&${currentEl?.parameterName}=${currentItem?.parameterName}`;
          string += currentString;
        } */
    }
  }

  if (arr?.length > 0) {
    for (let i = 0; i < arr?.length; i++) {
      const currentEl = arr[i];
      const filtersArr = currentEl?.filter;

      if (filtersArr?.length > 0) {
        string += `&${currentEl?.para}=${filtersArr?.toString()}`;
      }
    }
  }
  console.log(string);
  return string;
};

export default getFilterString;
