import { useTranslation } from 'react-i18next';
import '../../styles/components/productWizard/wizardStart.scss';
import WizardButtonPanel from './WizardButtonPanel';
import lottieBanner from '../../assets/json/lottie_animation/lets-add-product.json';
import Lottie from 'lottie-react';
interface Props {
  currentStep: number;
  increaseStep: () => void;
  decreaseStep: () => void;
}

const WizardStart = ({ increaseStep, decreaseStep, currentStep }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className='wizardStart'>
        <div className='wizardStart-animation'>
          <Lottie animationData={lottieBanner} loop={true} />
        </div>
        <div className='wizardStart-text'>
          <h1>{t('wizardStartText')}</h1>
        </div>
      </div>
      <WizardButtonPanel
        currentStep={currentStep}
        increaseStep={increaseStep}
        decreaseStep={decreaseStep}
        handlerFunction={() => {
          increaseStep();
        }}
      />
    </>
  );
};

export default WizardStart;
