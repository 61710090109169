import SubPageList from '../../../components/subPageList/SubPageList';
import { editSVG, eyeSVG, trashSVG } from '../../../lib/allCustomSVGs';
import { useEffect, useState } from 'react';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { WEBHOOK_ENDPOINT } from '../../../lib/constants/urls';
import { asyncHandler } from '../../../lib/api/async-handler';
import { useTranslation } from 'react-i18next';
import makeFirstLetterCapital from '../../../lib/helper-functions/makeFirstLetterCapital';

const ListWebhook = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState('pending');
  const [webhookList, setWebhookList] = useState<any[]>([]);

  const subPageDropdownItems = [
    {
      title: t('View'),
      subTitle: t('ViewSingleWebhook'),
      id: '0',
      icon: eyeSVG,
      url: '/settings/webhooks/view',
    },
    {
      title: t('Edit'),
      subTitle: t('EditWebhook'),
      id: '1',
      icon: editSVG,
      url: '/settings/webhooks/edit',
    },
    {
      title: t('Delete'),
      subTitle: t('DeleteWebhook'),
      id: '2',
      icon: trashSVG,
      url: '/settings/webhooks/delete',
    },
  ];

  useEffect(() => {
    let webhooks: any = [];
    const data = getAllWebhooks();
    data
      .then((result) => {
        if (result.length > 0) {
          for (let i of result) {
            let webhook = {
              id: i.id,
              title: makeFirstLetterCapital(i.name),
              subTitle: `${i.method} : ${i.endpoint}`,
            };
            webhooks.push(webhook);
          }
          setWebhookList(webhooks);
          setLoading('success');
        } else {
          setLoading('null');
        }
      })
      .catch((err) => {
        console.error(err);
        setLoading('error');
      });
  }, []);

  /* ----------------------------API CALL START---------------------------- */
  async function getAllWebhooks() {
    try {
      const accessToken = store.getState().auth.authorization.accessToken;
      const isWhiteLabel = store.getState().UI?.isWhiteLabel;
      let sellerToken: string;
      if (isWhiteLabel) {
        sellerToken = accessToken?.currentWhiteLabelsellerToken;
      } else {
        sellerToken = accessToken?.sellerToken;
      }

      const requestInfo: RequestInfo = {
        url: WEBHOOK_ENDPOINT,
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_ALL_WEBHOOKS';
      const customMsg = 'Webhooks fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return response.result;
      }
    } catch (exception) {
      console.log('Error during request');
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  //creating initial state for each list item
  const initialEditButtonState: any = {};

  webhookList?.forEach((item: any) => {
    initialEditButtonState[item?.id] = false;
  });

  const onListItemClick = () => {
    //on list dropdown item click, make dropdown open states to initial state
    setIsListMenuOpen(initialEditButtonState);
  };

  const [isListMenuOpen, setIsListMenuOpen] = useState(initialEditButtonState);

  const onListMenuClick = (itemId: any) => {
    setIsListMenuOpen((isListMenuOpen: any) => ({
      ...isListMenuOpen,
      [itemId]: !isListMenuOpen[itemId],
    }));
  };

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'null' && (
        <NoData
          title={t('NoWebHooksFound-msg')}
          // subTitle='Something went wrong.'
        />
      )}
      {loading === 'error' && (
        <NoData
          title={t('ErrorFetchingData')}
          subTitle={t('SomethingWentWrong-msg')}
        />
      )}
      {loading === 'success' && (
        <SubPageList
          onListMenuClick={onListMenuClick}
          onListItemClick={onListItemClick}
          isListMenuOpen={isListMenuOpen}
          items={webhookList}
          dropdownItems={subPageDropdownItems}
        />
      )}
    </>
  );
};

export default ListWebhook;
