import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { downloadSVG, importSVG } from '../../../../lib/allCustomSVGs';
import { IMPORT_CSV_ENDPOINT } from '../../../../lib/constants/urls';
import store from '../../../../store/store';
import HeaderStoreButton from '../../../../ui/buttons/headerStoreButton';
import MainSquareButton from '../../../../ui/buttons/MainSquareButton';
import CustomSVGs from '../../../../ui/customSVGs/CustomSVGs';
import ImageInput from '../../../../ui/inputs/ImageInput';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../../lib/api/async-handler';

const CMSImport = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const activeLink = location.pathname;
  const currentOrigin = window.location.origin;

  // CSV File
  const [selectedFile, setSelectedFile] = useState();
  const [previewFile, setPreviewFile] = useState<string>();
  const [fileErrorFile, setFileErrorFile] = useState(false);
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;

  /* ----------------------------API CALL START---------------------------- */
  async function onSubmitHandler(e: any) {
    e.preventDefault();
    try {
      const accessToken = store.getState().auth.authorization.accessToken;
      let sellerToken: string;
      if (isWhiteLabel) {
        sellerToken = accessToken?.currentWhiteLabelsellerToken;
      } else {
        sellerToken = accessToken?.sellerToken;
      }

      const formData: any = new FormData();
      formData.append('csv', selectedFile);

      const requestInfo: RequestInfo = {
        url: IMPORT_CSV_ENDPOINT,
        method: 'POST',
        body: formData,
        headers: {
          // Accept: 'application/json',
          // 'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'IMPORT_CSV';
      const customMsg = t('CMS-CSV-ImportedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return response.result;
      }
    } catch (exception) {
      console.log('Error during authentication request');
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  return (
    <SubPageInnerContentHolder
      title={t('CMS-ImportFrom-CSV-Excel')}
      subTitle={t('Import-CMS')}
      backBtnLink={isWhiteLabel ? undefined : '/shop'}
    >
      {activeLink === '/shop/cms/import' && (
        <form
          id='design-settings-form'
          onSubmit={onSubmitHandler}
          className='normalForm'
        >
          <div className='normalForm__top'>
            {/* ------------------------------------------------------- */}
            <div className='normalForm__top--container1'>
              <div className='normalForm__top--container1-left'>
                <HeaderStoreButton
                  value={t('DownloadTemplate')}
                  icon={<CustomSVGs svg={downloadSVG} />}
                  type='button'
                  onClick={() => {
                    window.open(
                      `${currentOrigin}/adup-import-sample.csv`,
                      '_self'
                    );
                  }}
                />
                <ImageInput
                  isFileUpload={true}
                  mainText={t('Upload-CSV-Excel-File')}
                  subText={`${t('AcceptedFileTypes')} (.xlsx, .csv)`}
                  acceptedFileTypes={'.xlsx, .xls, .csv'}
                  id={'import-csv'}
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  preview={previewFile}
                  setPreview={setPreviewFile}
                  fileError={fileErrorFile}
                  setFileError={setFileErrorFile}
                  manyImages={false}
                  removeImage={() => {
                    setSelectedFile(undefined);
                  }}
                />
              </div>
            </div>
          </div>
          <div className='normalForm__bottom flex-start'>
            <MainSquareButton
              value={t('Import')}
              icon={<CustomSVGs svg={importSVG} />}
              disabled={!selectedFile}
              type='submit'
              form='design-settings-form'
            />
          </div>
        </form>
      )}
    </SubPageInnerContentHolder>
  );
};

export default CMSImport;
