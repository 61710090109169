import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type Country = {
  name: string;
  dialCode: string;
  countryCode: string;
  flag: string;
};

interface Message {
  messageType: string;
  message: string;
}

const initialCountriesAvailable: {
  [countryCode: string]: Country;
} = {};

const initialState = {
  isInitSuccess: false,
  runInitOnFirstLoad: false,
  countries: {
    available: initialCountriesAvailable,
    countryOfIP: '',
  },
  message: {
    messageType: '',
    message: '',
  },
  staticURL: '',
  language: 'en',
  browser: '',
};

export const appSlice = createSlice({
  /* name: 'counter', */
  name: 'app',
  initialState,
  reducers: {
    setAvailableCountries: (
      state,
      action: PayloadAction<{ countries: Country[] }>
    ) => {
      for (const country of action.payload.countries) {
        state.countries.available[country.countryCode] = country;
      }
    },
    setIPCountry: (state, action: PayloadAction<string>) => {
      state.countries.countryOfIP = action.payload;
    },
    setIsInitSuccess: (state, action: PayloadAction<boolean>) => {
      state.isInitSuccess = action.payload;
    },
    setRunInitOnFirstLoad: (state, action: PayloadAction<boolean>) => {
      state.runInitOnFirstLoad = action.payload;
    },
    setMessage: (state, action: PayloadAction<Message>) => {
      state.message = action.payload;
    },
    setStaticURL: (state, action: PayloadAction<string>) => {
      state.staticURL = action.payload;
    },
    setAppLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
    setBrowser: (state, action: PayloadAction<string>) => {
      state.browser = action.payload;
    },
  },
});

export const appReducer = appSlice.reducer;

export const {
  setAvailableCountries,
  setIPCountry,
  setMessage,
  setIsInitSuccess,
  setRunInitOnFirstLoad,
  setStaticURL,
  setAppLanguage,
  setBrowser,
} = appSlice.actions;
