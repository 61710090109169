//api call imports
import { PRODUCTS_ENDPOINT } from '../../../lib/constants/urls';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../lib/api/async-handler';
import store from '../../../store/store';

//other imports
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useTitle from '../../../customHooks/useTitle';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import EditProduct from './EditProduct';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

const LoadEditProduct = () => {
  useTitle('Edit Product');
  const { productSKU } = useParams();
  const navigate = useNavigate();
  const staticURL = useSelector((state: RootState) => state.app.staticURL);

  //state
  const [productId, setProductId] = useState<any>();
  const [productResponse, setProductResponse] = useState<any>();
  const [variantResponse, setVariantResponse] = useState<any>();
  const [productImages, setProductImages] = useState<any>();
  const [loading, setLoading] = useState('pending');

  /* ----------------------------API CALL GET PRODUCT START---------------------------- */
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelsellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: `${PRODUCTS_ENDPOINT}/${productSKU}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_SINGLE_PRODUCT';
  const customMsg = 'Product Loaded Successfully.';

  useEffect(() => {
    const getProducts = async () => {
      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;

      if (res.actionType === actionType && response.success === true) {
        const productObj = response?.result;
        if (productObj === 'products_not_found') {
          navigate('/products');
          return;
        }
        if (productObj?.length > 0) {
          /* const productVariantsArr: any = []; */
          const mainProductObj: any = Object.values(productObj[0]);

          //* Redirects back to Products Table if the product is in synced state *//
          if (mainProductObj[0]?.product_origin === 'synced') {
            console.log(
              'Edit Product permissions denied since product is in synced state'
            );
            navigate('/products');
            return;
          }

          const mainProductVariantArr: any = Object.values(
            mainProductObj[0]?.variant
          );
          setProductId(mainProductObj[0]?.id);

          const productInit = {
            productName: mainProductObj[0]?.name,
            productDescription: mainProductObj[0]?.description,
            productSKU: mainProductObj[0]?.sku,
            productStock: mainProductObj[0]?.stock || '',
            productPrice: '',
            productImages: [],
            productType: mainProductObj[0]?.type,
          };
          if (mainProductObj[0]?.images?.length > 0) {
            productInit.productImages = mainProductObj[0]?.images;
          }
          if (mainProductObj[0]?.price) {
            productInit.productPrice = mainProductObj[0]?.price;
          }
          setProductResponse(productInit);

          if (
            mainProductVariantArr?.length > 0 &&
            productInit.productType === 'variable'
          ) {
            const sendingVariants = mainProductVariantArr.map(
              (el: any, index: number) => {
                let sendingAttributes;
                if (el?.attributes?.length > 0) {
                  sendingAttributes = el?.attributes?.map(
                    (item: any, i: number) => {
                      const attKey = Object.keys(item);
                      const attValue = Object.values(item);
                      return {
                        id: i,
                        name: attKey[0],
                        value: attValue[0],
                      };
                    }
                  );
                }

                return {
                  productVariantId: el?.id,
                  productVariants: {
                    productVariantName: el?.name,
                    productVariantDescription: el?.description,
                    productVariantSKU: el?.sku,
                    productVariantPrice: el?.price,
                    productVariantStock: el?.stock || 0,
                  },
                  productVariantStatus:
                    el?.status === '1' ? 'active' : 'inactive',
                  productVariantImages: {
                    selectedProductVariantImages: [],
                    previewProductVariantImages:
                      el?.images?.length > 0 ? el?.images : [],
                  },
                  variantAttributes: sendingAttributes,
                };
              }
            );

            setVariantResponse(sendingVariants);
          } else {
            setVariantResponse([]);
          }

          setLoading('success');
          /* setProductVariants(mainProductVariantObj);
          setCurrentProduct(mainProductObj[0]); */
          //
        } else {
          setLoading('null');
        }
      } else {
        setLoading('error');
      }
    };

    getProducts();
  }, []);
  /* ----------------------------API CALL GET PRODUCT  END---------------------------- */

  return (
    <>
      {loading === 'error' && (
        <NoData
          title='Error Fetching Data'
          subTitle='Something went wrong :('
        />
      )}
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'success' && (
        <EditProduct
          productId={productId}
          productRes={productResponse}
          variantRes={variantResponse}
        />
      )}
    </>
  );
};

export default LoadEditProduct;
