import '../../styles/pages/orders/orderDetailPage.scss';
import { useParams } from 'react-router-dom';
import CustomTable from '../../ui/customTables/CustomTable';
import { useState, useEffect } from 'react';

//api call imports
import { ORDERS_ENDPOINT } from '../../lib/constants/urls';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import store from '../../store/store';
import LoadingSpinner from '../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../ui/noData/NoData';
import SubPageContentHolder from '../../components/subPageContentHolder/SubPageContentHolder';
import SubPageInnerContentHolder from '../../components/subPageContentHolder/SubPageInnerContentHolder';
import { useTranslation } from 'react-i18next';
import formatDate from '../../lib/helper-functions/formatDate';
import makeFirstLetterCapital from '../../lib/helper-functions/makeFirstLetterCapital';
import timeSince from '../../lib/helper-functions/timeSince';
import getCurrencySymbol from '../../lib/helper-functions/getCurrencySymbol';

/* const dummyProducts = [
  {
    images: '1666396826.jpg',
    name: 'sample variant name',
    sku: 'varsku3',
    price: '$99.99',
    quantity: '1',
    amount: '$99.99',
  },
  {
    images: '1666396826.jpg',
    name: 'sample variant name',
    sku: 'varsku3',
    price: '$99.99',
    quantity: '1',
    amount: '$99.99',
  },
]; */

const billData = {
  subTotal: '',
  shipping: '',
  discount: '',
  grandTotal: '',
};

const OrderDetailPage = () => {
  const { orderId } = useParams();
  const [loading, setLoading] = useState('success');
  // const shopId = useSelector((state: RootState) => state.user.company.shop_id);
  const [mainOrderObj, setMainOrderObj] = useState<any>();
  const [currentOrder, setCurrentOrder] = useState<any>();
  const [cartItems, setCartItems] = useState<any>();
  const { t } = useTranslation();
  const columns = [
    {
      field: 'images',
      headerName: t('Image'),
      width: '10rem',
      isImageField: true,
    },
    {
      field: 'name',
      headerName: t('Name'),
      width: '20rem',
    },
    {
      field: 'sku',
      headerName: 'SKU',
      width: '13rem',
    },
    {
      field: 'price',
      headerName: t('Price'),
      width: '10rem',
    },
    {
      field: 'quantity',
      headerName: t('Quantity'),
      width: '10rem',
    },
    {
      field: 'amount',
      headerName: t('Amount'),
      width: '10rem',
    },
  ];

  /* ----------------------------API CALL START---------------------------- */
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelsellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: `${ORDERS_ENDPOINT}/${orderId}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_SINGLE_ORDER';
  const customMsg = 'Order Details Loaded Successfully.';

  useEffect(() => {
    const getOrder = async () => {
      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;

      if (res.actionType === actionType && response.success === true) {
        const orderObj = response?.result; //this is the actual result response (commented due to testing)
        /* const orderObj = newDummyOrderResponseResult; //this is a dummpy respone (replace this with the correct one after api response is fixed) */
        if (orderObj?.length > 0) {
          const mainOrderObj = orderObj[0];

          setLoading('success');
          setMainOrderObj(mainOrderObj);
          setCartItems(mainOrderObj?.cart_item);
          setCurrentOrder(mainOrderObj?.cart_data);
        } else {
          setLoading('null');
        }
      } else {
        setLoading('error');
      }
    };

    getOrder();
  }, []);
  /* ----------------------------API CALL END---------------------------- */

  const getAddress = (addressObj: any) => {
    const address = `${addressObj?.house_number}, ${addressObj?.street}, ${
      addressObj?.street2 || ''
    }, ${addressObj?.city}, ${addressObj?.province}, ${addressObj?.postcode}, ${
      addressObj?.country
    }`;
    return addressObj?.country ? address : '--- --- --- ---';
  };

  const getCountry = (addressObj: any) => {
    const country = `${addressObj?.country}`;
    return addressObj?.country ? country : '---';
  };
  const orderDetails = {
    dateTime: mainOrderObj?.created_at
      ? formatDate(mainOrderObj?.created_at)
      : '---',
    gateway: currentOrder?.psp
      ? makeFirstLetterCapital(currentOrder?.psp)
      : '---',
    method: currentOrder?.payment_method
      ? makeFirstLetterCapital(currentOrder?.payment_method)
      : '---',
    bank: '---',
    status: mainOrderObj?.status
      ? makeFirstLetterCapital(mainOrderObj?.status)
      : '---',
    cmsPost: '---',
  };

  const shopperDetails = {
    name: `${currentOrder?.shopper?.first_name || '---'} ${
      currentOrder?.shopper?.last_name || '---'
    }`,
    phone: `+${currentOrder?.shopper?.country_code || '---'} ${
      +currentOrder?.shopper?.phone || '---'
    }`,
    email: currentOrder?.shopper?.email || '---',
    joinedAdup: currentOrder?.shopper?.created_at
      ? timeSince(currentOrder?.shopper?.created_at)
      : '---',
    verified: currentOrder?.shopper?.verified ? t('Yes') : t('No'),
    orderPlaced: getCountry(currentOrder?.shopper?.addresses?.shipping_address),
    shippingAddress: getAddress(
      currentOrder?.shopper?.addresses?.shipping_address
    ),
    billingAddress: getAddress(
      currentOrder?.shopper?.addresses?.billing_address
    ),
  };

  const products = currentOrder?.products;

  const createProductTableData = () => {
    const sendingData = cartItems?.map((el: any) => {
      const tableItem = {
        images: '',
        name: '',
        sku: '',
        price: '',
        quantity: '',
        amount: '',
      };
      tableItem.quantity = el?.qty;
      const currentVariantId = el?.sku;
      for (let i = 0; i < products?.length; i++) {
        const currentProduct = products[i];
        if (currentProduct) {
          const currentVariants = currentProduct[currentVariantId]?.variant;
          if (currentVariants) {
            const currentVariant = currentVariants[currentVariantId];
            if (currentVariant) {
              tableItem.name = currentVariant?.name;
              tableItem.sku = currentVariant?.sku;
              tableItem.price = `${getCurrencySymbol('NL', 'EUR')}${
                currentVariant?.price
              }`;
              tableItem.amount = `${getCurrencySymbol('NL', 'EUR')}${(
                +currentVariant?.price * +tableItem.quantity
              )?.toString()}`;
              tableItem.images =
                currentVariant?.images?.length > 0
                  ? currentVariant?.images[0]
                  : '';
            }
          }
        }
      }
      return tableItem;
    });

    return sendingData;
  };

  if (currentOrder) {
    billData.subTotal =
      typeof currentOrder?.sub_total === 'number'
        ? currentOrder?.sub_total
        : '---';
    billData.discount =
      typeof currentOrder?.discount === 'number'
        ? currentOrder?.discount
        : '---';
    billData.shipping =
      typeof currentOrder?.shipping_cost === 'number'
        ? currentOrder?.shipping_cost
        : '---';
    billData.grandTotal =
      typeof currentOrder?.cart_total === 'number'
        ? currentOrder?.cart_total
        : '---';
  }

  return (
    <SubPageContentHolder>
      <SubPageInnerContentHolder
        title={`Order #${orderId}`}
        /* subTitle={'Manage Products'} */
        backBtnLink='/orders'
      >
        <div className='orderDetailPage'>
          <div className='orderDetailPage-backButton'></div>
          {loading === 'error' && (
            <NoData
              title={t('ErrorFetchingData')}
              subTitle={t('SomethingWentWrong-msg')}
            />
          )}
          {loading === 'pending' && <LoadingSpinner />}
          {loading === 'success' && (
            <>
              <div className='orderDetailPage__top'>
                <div className='orderDetailPage__top--left'>
                  <h1>{t('OrderDetails')}</h1>
                  <h2>
                    {t('Date & Time')}: <span>{orderDetails.dateTime}</span>
                  </h2>
                  <h2>
                    {t('Gateway')}: <span>{orderDetails.gateway}</span>
                  </h2>
                  <h2>
                    {t('Method')}: <span>{orderDetails.method}</span>
                  </h2>
                  {/* <h2>
                      Bank: <span>{orderDetails.bank}</span>
                    </h2> */}
                  <h2 className='orderDetailPage__top--center-status'>
                    {t('Status')}: <span>{orderDetails.status}</span>
                    {/* <div /> */}
                  </h2>
                  {/* <h2>
                      CMS post: <span>{orderDetails.cmsPost}</span>
                    </h2> */}
                </div>
                {/* center start*/}
                <div className='orderDetailPage__top--center'>
                  <h1>{t('Shopper details')}</h1>
                  <h2>{shopperDetails.name}</h2>
                  <h2>
                    {t('Phone')}: <span>{shopperDetails.phone}</span>
                  </h2>
                  <h2>
                    {t('Email')}: <span>{shopperDetails.email}</span>
                  </h2>
                  {/*  <h2>
                    {t('Joined adup')}:{' '}
                    <span>
                      {shopperDetails.joinedAdup} {t('ago')}
                    </span>
                  </h2> */}
                  <h2>
                    {t('Verified')}: <span>{shopperDetails.verified}</span>
                  </h2>
                  <h2>
                    {t('Order placed')}:{' '}
                    <span>
                      {t('from')} {shopperDetails.orderPlaced}
                    </span>
                  </h2>
                </div>
                {/* center end*/}
                <div className='orderDetailPage__top--right'>
                  <div className='orderDetailPage__top--right-shipping'>
                    <h1>{t('ShippingAddress')}</h1>
                    <span>{shopperDetails.shippingAddress}</span>
                  </div>

                  <div className='orderDetailPage__top--right-billing'>
                    <h1>{t('BillingAddress')}</h1>
                    <span>{shopperDetails.billingAddress}</span>
                  </div>
                </div>
              </div>
              <div className='orderDetailPage--varients-text'>{t('Order')}</div>
              <div className='orderDetailPage__bottom'>
                <CustomTable
                  headerData={columns}
                  rowData={createProductTableData() || []}
                  billData={billData}
                />
              </div>
            </>
          )}
        </div>
      </SubPageInnerContentHolder>
    </SubPageContentHolder>
  );
};

export default OrderDetailPage;
