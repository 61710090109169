import { useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import PageWrapper from '../../components/page-wrappers/PageWrapper';
import AddProduct from './addProduct/AddProduct';
import AllProducts from './AllProducts';
import LoadEditProduct from './editProduct/LoadEditProduct';
import ProductDetailPage from './ProductDetailPage';

const ProductsPage = () => {
  const location = useLocation();
  const activeLink = location.pathname.substring(1);
  /* ----- page number states of all products page start----- */
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  /*  const [currentOrdersPageNumber, setCurrentOrdersPageNumber] = useState(1); */
  /* ----- page number states of all products page  end  ----- */

  return (
    <PageWrapper>
      {/* {activeLink === 'products' && <AllProducts />} */}
      <Routes>
        <Route
          path='/*'
          element={
            <AllProducts
              currentPageNumber={currentPageNumber}
              setCurrentPageNumber={setCurrentPageNumber}
            />
          }
        />
        <Route path='/:productId' element={<ProductDetailPage />} />
        <Route path='add/*' element={<AddProduct />} />
        <Route path='edit/:productSKU' element={<LoadEditProduct />} />
      </Routes>
    </PageWrapper>
  );
};

export default ProductsPage;
