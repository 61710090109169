import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import '../../styles/components/productWizard/wizardEnd.scss';
import phone from '../../assets/iphone.png';
import { arrowHeadLeftSVG, linkSVG } from '../../lib/allCustomSVGs';
import WizardEndLink from './WizardEndLink';
import { RootState } from '../../store/store';
import {
  setIsProductWizardOpen,
  setShouldShowProductWizard,
} from '../../store/reducers/userSlice';

interface Props {
  currentStep: number;
  increaseStep: () => void;
  decreaseStep: () => void;
  addedProductSKU: any;
}

const WizardEnd = ({
  increaseStep,
  decreaseStep,
  currentStep,
  addedProductSKU,
}: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { hasInitPSP } = useSelector((state: RootState) => state.user.shop);
  const shopSlug = useSelector(
    (state: RootState) => state.user.company.shop_slug
  );

  const shopperAppUrl = useSelector(
    (state: RootState) => state.user.merchant?.fastcheckout_domain
  );

  console.log(hasInitPSP);
  const link = `${shopperAppUrl}/${shopSlug?.replaceAll(
    ' ',
    ''
  )}/${addedProductSKU}`;
  return (
    <>
      <div className='wizardEnd'>
        <div className='wizardEnd__left'>
          <img src={phone} alt='phone' />
          <div className='wizardEnd__left--iframe'>
            {addedProductSKU && (
              <iframe
                /* scrolling='no' */
                title='phone-iframe'
                /* src={'#'} */
                src={link}
              ></iframe>
            )}
          </div>
        </div>
        <div className='wizardEnd__right'>
          <div className='wizardEnd__right--text'>
            <h1>{t('wizardEndText-1')}</h1>
            {hasInitPSP && <h2>{t('wizardEndText-2')}</h2>}
            {!hasInitPSP && <h2>{t('wizardEndText-3')}</h2>}
          </div>
          <div className='wizardEnd__right--links'>
            {hasInitPSP && (
              <>
                <WizardEndLink
                  title1={t('GoTo')}
                  title2={t('ProductsOverview')}
                  icon={arrowHeadLeftSVG}
                  iconType={'arrow'}
                  onClickHandler={() => {
                    navigate('/products');
                    dispatch(setShouldShowProductWizard(false));
                    dispatch(setIsProductWizardOpen(false));
                  }}
                />
                <a
                  href={link}
                  target='_blank'
                  rel='noreferrer'
                  onClick={() => {
                    dispatch(setShouldShowProductWizard(false));
                    dispatch(setIsProductWizardOpen(false));
                  }}
                >
                  <WizardEndLink
                    title1={t('SeeYour')}
                    title2={t('Checkout')}
                    icon={linkSVG}
                    onClickHandler={() => {}}
                  />
                </a>
              </>
            )}

            {!hasInitPSP && (
              <>
                <WizardEndLink
                  title1={t('GoTo')}
                  title2={t('ProductsOverview')}
                  icon={arrowHeadLeftSVG}
                  iconType={'arrow'}
                  onClickHandler={() => {
                    navigate('/products');
                    dispatch(setShouldShowProductWizard(false));
                    dispatch(setIsProductWizardOpen(false));
                  }}
                />
                <WizardEndLink
                  title1={t('Add')}
                  title2={t('PaymentProvider')}
                  icon={arrowHeadLeftSVG}
                  iconType={'arrow'}
                  onClickHandler={() => {
                    navigate('/shop/psp/connection/add');
                    dispatch(setShouldShowProductWizard(false));
                    dispatch(setIsProductWizardOpen(false));
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {/* <WizardButtonPanel
        currentStep={currentStep}
        increaseStep={increaseStep}
        decreaseStep={decreaseStep}
        handlerFunction={() => {
          navigate('/products');
          dispatch(setShouldShowProductWizard(false));
          dispatch(setIsProductWizardOpen(false));
        }}
      /> */}
    </>
  );
};

export default WizardEnd;
