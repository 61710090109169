import '../../styles/generic/topFloatLogo.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';

const TopFloatLogo = () => {
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  return (
    <a
      className='topFloatLogo'
      // onClick={() => {
      //   window.location.assign('/');
      // }}
    >
      <img
        src={`${staticURL}/medium/${merchantTheme?.merchant_logo_dark}`}
        alt='floating logo'
      />
    </a>
  );
};

export default TopFloatLogo;
