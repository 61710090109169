import React from 'react';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import '../../styles/components/productWizard/wizardEndLink.scss';

interface Props {
  title1: string;
  title2: string;
  icon: any;
  iconType?: 'arrow';
  onClickHandler: () => void;
}

const WizardEndLink = ({
  title1,
  title2,
  icon,
  iconType,
  onClickHandler,
}: Props) => {
  return (
    <div onClick={onClickHandler} className='wizardEndLink'>
      <div className='wizardEndLink__left'>
        <h2>{title1}</h2>
        <h3>{title2}</h3>
      </div>
      <div
        data-wizard-end-link-icon-type={iconType}
        className='wizardEndLink__right'
      >
        <CustomSVGs svg={icon} />
      </div>
    </div>
  );
};

export default WizardEndLink;
