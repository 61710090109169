import { useState } from 'react';
import { navigationSVG, crossSVG } from '../../lib/allCustomSVGs';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';
import '../../styles/components/topbar/topbar.scss';
import userPNG from '../../imgs/user.jpg';
// import SidebarMobile from '../sidebar/SidebarMobile';
import ProfileDropdown from '../dropdowns/ProfileDropdown';
import { toggleSidebarDrawer } from '../../store/reducers/uiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { useComponentVisibility } from '../../customHooks/useComponentVisibility';

/* interface Props {
  toggleSidebarDrawer: any;
  isSidebarDrawerOpen: boolean;
} */

const Topbar = () => {
  // const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isProfileDropdownOpen, setIsProfileDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const staticURL = useSelector((state: RootState) => state.app.staticURL);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );
  const isSidebarDrawerOpen = useSelector(
    (state: RootState) => state.UI.isSidebarDrawerOpen
  );
  const isDarkMode = useSelector((state: RootState) => state.UI.theme.dark);

  /* useComponentVisibility codes start */
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisibility<HTMLDivElement>(false);

  const handleDropdownClick = () => {
    setIsComponentVisible((prev) => !prev);
  };

  const handleDropdownItemClick = () => {
    setIsComponentVisible(false);
    // Handle specific actions when a dropdown item is clicked
  };
  /* useComponentVisibility codes end */

  const RenderLogo = () => {
    if (
      merchantTheme.merchant_logo_dark &&
      merchantTheme.merchant_name === 'Buckaroo'
    ) {
      return (
        <img
          src={`${staticURL}/medium/${merchantTheme.merchant_logo_dark}`}
          alt='logo'
        />
      );
    } else if (merchantTheme.merchant_logo_light) {
      return (
        <img
          src={`${staticURL}/medium/${merchantTheme.merchant_logo_light}`}
          alt='logo'
        />
      );
    } else {
      return <img src={''} alt='logo' />;
    }
  };

  return (
    <>
      <div className='topbar'>
        <div
          onClick={() => {
            // setIsSidebarOpen(!isSidebarOpen);
            dispatch(toggleSidebarDrawer());
          }}
          className='topbar__left'
        >
          {isSidebarDrawerOpen && <CustomSVGs svg={crossSVG} />}
          {!isSidebarDrawerOpen && <CustomSVGs svg={navigationSVG} />}
        </div>
        <div className='topbar__center'>{RenderLogo()}</div>
        <div className='topbar__right'>
          {/* <div className='topbar__right--notification'>
            <CustomSVGs svg={bellSVG} />
          </div> */}

          <div
            className='topbar__right--usermenu'
            ref={ref}
            onClick={handleDropdownClick}
          >
            <img src={userPNG} alt='profile-pic' />
            {isComponentVisible && (
              <div onClick={handleDropdownItemClick}>
                <ProfileDropdown />
              </div>
            )}
          </div>
          {/*  */}
        </div>
      </div>
      {/* <Sidebar
        isSidebarDrawerOpen={isSidebarDrawerOpen}
        toggleSidebarDrawer={toggleSidebarDrawer}
      /> */}
      {/* {isSidebarOpen && <SidebarMobile />} */}
    </>
  );
};

export default Topbar;
