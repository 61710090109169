import {
  setAppLanguage,
  setAvailableCountries,
  setBrowser,
  setIPCountry,
  setStaticURL,
} from '../../store/reducers/appSlice';
import { setSubscriptionLock } from '../../store/reducers/authSlice';
import {
  updateProfile,
  setUserCompanyDetails,
  setShouldShowProductWizard,
  setIsProductWizardOpen,
  setHasInitPSP,
  setUserCompanySettings,
  updateMerchant,
} from '../../store/reducers/userSlice';
import store from '../../store/store';

import { Country } from '../../store/reducers/appSlice';

/**
 * Update the state using the data received from the /init response
 * @param { any } initResponseData  The data sent by a successful init response
 */
export function initResponseStateUpdate(initResponseData: any) {
  const authToken = store.getState().auth.authorization.accessToken;

  const { user_data, app_data, shop, user, merchant } = initResponseData;

  const { countries, static_base_url } = app_data;

  const { access_token, url, token_type, seller, onboarding, adupio_settings } =
    initResponseData;

  /* console.log(access_token);

  if (access_token) {
    store.dispatch(
      setAccessToken({
        token: access_token,
        type: token_type,
      })
    );
  } */

  if (shop && shop?.length > 0) {
    const {
      id,
      currency,
      name,
      shop_slug,
      subscription_lock,
      products_count,
      cms,
      psp,
      settings,
    } = shop[0];
    store.dispatch(
      setUserCompanyDetails({
        name,
        currency,
        shop_id: id?.toString(),
        shop_slug,
      })
    );
    store.dispatch(setUserCompanySettings(settings));
    if (subscription_lock) {
      store.dispatch(setSubscriptionLock(subscription_lock.toString()));
    } else {
      store.dispatch(setSubscriptionLock('false'));
    }

    //set login init product count and cms status
    if (!products_count && cms?.length < 1) {
      // store.dispatch(setShouldShowProductWizard(true));
      // store.dispatch(setIsProductWizardOpen(true));
    }
    if (psp?.length > 0) {
      store.dispatch(setHasInitPSP(true));
    }
  }

  if (static_base_url) {
    store.dispatch(setStaticURL(static_base_url));
  }

  if (countries) {
    const countriesArray: Country[] = [];

    for (const countryCode in countries) {
      const country = countries[countryCode];

      countriesArray.push({
        countryCode,
        dialCode: country.dial_code,
        flag: country.flag,
        name: country.name,
      });
    }
    store.dispatch(setAvailableCountries({ countries: countriesArray }));
    store.dispatch(setIPCountry(user_data?.device_country?.countryCode));
  }

  //getting browser language
  store.dispatch(setAppLanguage(user_data?.browser_language));

  //getting browser name
  store.dispatch(setBrowser(user_data?.browser_name));

  if (user?.id) {
    const seller_id = user?.id.toString();
    const { email, first_name, last_name, language, phone, country_code } =
      user;
    const country = user_data?.device_country?.countryCode;

    /* console.log({
      seller_id,
      email,
      first_name,
      last_name,
      language,
      phone,
      country_code,
      country,
    }); */

    store.dispatch(
      updateProfile({
        seller_id,
        email,
        first_name,
        last_name,
        language,
        phone,
        country_code,
        country,
      })
    );
  }

  if (merchant?.id) {
    const id = merchant?.id.toString();

    const merchantObj = merchant;
    merchant.id = id;

    store.dispatch(updateMerchant(merchantObj));
  }

  if (authToken?.sellerToken && authToken.sellerToken !== '') {
  }

  /* if (onboarding?.cms_options) {
    const cmsOptions = onboarding.cms_options.map(
      (option: any): CMS => ({ icon: '', name: option })
    );

    store.dispatch(setAvailableCMS(cmsOptions));
  } */

  /* if (onboarding?.psp_options) {
    const pspOptions = onboarding.psp_options.map(
      (option: any): PSP => ({ id: option, icon: '', name: option })
    );

    store.dispatch(setAvailablePSP(pspOptions));
  } */
}

/**
 *
 */ //TODO: remove later
// export function loginResponseStateUpdate(loginResponseData: any) {
//   const {
//     access_token,
//     token_type,
//     redirect_to,
//     onboarding_completed,
//     onboarding,
//   } = loginResponseData;

//   if (access_token && access_token !== '') {
//     store.dispatch(
//       setAccessToken({
//         token: access_token,
//         type: token_type,
//       })
//     );
//   }

//   if (onboarding) {
//     const { steps, completed_steps, current_step, data } = onboarding;

//     store.dispatch(updateOnboardingStage(stepsAndStagesMap[current_step].name));
//   }

//   if (onboarding_completed) {
//     window.location.assign('/dashboard');
//   } else {
//     window.location.assign(redirect_to);
//   }
// }
