import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import ShowInfoBlock from '../../../components/showInfoBlock/ShowInfoBlock';
import store from '../../../store/store';
import '../../../styles/pages/settings/settingsShippingOptions.scss';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { SETTINGS_ENDPOINT } from '../../../lib/constants/urls';
import { asyncHandler } from '../../../lib/api/async-handler';
import LoadingSpinner from '../../../ui/loadingSpinner/LoadingSpinner';
import NoData from '../../../ui/noData/NoData';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import { applicationWWWXRLEncodedBodyBuilder } from '../../../lib/utils/body-builder';
import { useTranslation } from 'react-i18next';
import MaskedCurrencyInput from '../../../ui/inputs/CurrencyInput';
import SelectableButton from '../../../ui/buttons/SelectableButton';
import { setUserCompanySettings } from '../../../store/reducers/userSlice';
import '../../../styles/generic/normalForm.scss';
import SettingsConvenienceFeeAdd from './SettingsConvenienceFeeAdd';

interface MethodConvenienceFeeInterface {
  id: number;
  method: string;
  rateType: 'percentage' | 'flat' | string;
  cost: string;
}

const SettingsConvenienceFeeOptions = () => {
  const [defaultConvenienceFee, setDefaultConvenienceFee] =
    useState<string>('');
  const [isFixedConvenienceFee, setIsFixedConvenienceFee] =
    useState<boolean>(false);
  const [loading, setLoading] = useState('pending');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const { t } = useTranslation();

  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelsellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const [methodsConvenienceFee, setMethodsConvenienceFee] = useState<
    MethodConvenienceFeeInterface[]
  >([]);

  useEffect(() => {
    const response = getConvenienceFeeSettings();
    response
      .then((result) => {
        setDefaultConvenienceFee(result?.default_conveniencefee_cost);
        setIsFixedConvenienceFee(
          result?.fixed_conveniencefee === 'true' ||
            result?.fixed_conveniencefee === true
            ? true
            : false
        );
        setLoading('success');
      })
      .catch((err) => {
        console.error(err);
        setLoading('error');
      });
  }, []);

  /* ----------------------------API CALL START---------------------------- */
  async function getConvenienceFeeSettings() {
    try {
      const requestInfo: RequestInfo = {
        url: SETTINGS_ENDPOINT + '?group=ConvenienceFee',
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'GET_CONVENIENCE_FEE_SETTINGS';
      const customMsg = 'Convenience Fee Settings fetched Successfully.';

      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return response.result;
      }
    } catch (exception) {
      console.log('Error during request');
    }
  }

  async function onSubmitHandler(e: any) {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const sendingData = {
        group: 'ConvenienceFee',
        default_conveniencefee_cost: defaultConvenienceFee ?? null,
        fixed_conveniencefee: isFixedConvenienceFee,
      };

      const requestInfo: RequestInfo = {
        url: SETTINGS_ENDPOINT,
        method: 'POST',
        body: applicationWWWXRLEncodedBodyBuilder(sendingData),
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'UPDATE_CONVENIENCE_FEE_SETTINGS';
      const customMsg = 'Convenience Fee Settings updated Successfully.';

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);
      setIsSubmitting(false);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        store.dispatch(setUserCompanySettings(response?.result));
        return response.result;
      }
    } catch (exception) {
      console.log('Error during authentication request');
      setIsSubmitting(false);
    }
  }
  /* ----------------------------API CALL END---------------------------- */

  const isDisabled = defaultConvenienceFee === '' ? true : false;

  return (
    <>
      {loading === 'pending' && <LoadingSpinner />}
      {loading === 'error' && (
        <NoData
          title='Error Fetching Data'
          subTitle='Something went wrong :('
        />
      )}
      {loading === 'success' && (
        <div className='settingsShippingOptions'>
          <div className='settingsShippingOptions__top'>
            {/* ------------------------------- */}
            {/* //* Convenience Fee model Selection -- Start */}
            <div className='shopPage__title'>
              <h2>{t('ConvenienceFeeModel')}</h2>
            </div>
            {/* <div className='shopPage__divider' /> */}
            <div className='shopPage__container1-alignLeft'>
              <SelectableButton
                icon={''}
                title={''}
                subTitle={t('FixedConvenienceFee')}
                subTitle2={t('FixedConvenienceFee-Sub')}
                link=''
                onSelect={() => {
                  setIsFixedConvenienceFee(true);
                }}
                isSelected={isFixedConvenienceFee}
              />
              <SelectableButton
                icon={''}
                title={''}
                subTitle={t('MethodConvenienceFee')}
                subTitle2={t('MethodConvenienceFee-Sub')}
                link=''
                onSelect={() => {
                  setIsFixedConvenienceFee(false);
                }}
                isSelected={!isFixedConvenienceFee}
              />
            </div>
            {/* //* Convenience Fee model Selection -- End */}

            {/* //* Method specific Convenience Fee component -- Start */}
            <AnimatePresence>
              {!isFixedConvenienceFee && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  className='settingsShippingOptions__bottom'
                >
                  <SettingsConvenienceFeeAdd
                    methodsConvenienceFee={methodsConvenienceFee}
                    setMethodsConvenienceFee={setMethodsConvenienceFee}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            {/* //* Method specific Convenience Fee component -- End */}

            <div className='normalForm__container1'>
              {/* //* Default Convenience Fee Input -- Start */}
              <div className='normalForm__container1--left'>
                <div className='flex-row'>
                  <div className='flex-grow-container'>
                    <MaskedCurrencyInput
                      changeListeners={[
                        (value: any) => setDefaultConvenienceFee(value),
                      ]}
                      id='default-shipping-cost'
                      value={
                        defaultConvenienceFee &&
                        !Number.isNaN(defaultConvenienceFee)
                          ? defaultConvenienceFee
                          : '0'
                      }
                      placeholder={t('DefaultConvenienceFee')}
                      required={true}
                      title={t('OnlyNumbers-validation')}
                      type='text'
                      pattern='^[\d|\.|\,]+/'
                      defaultValue={
                        defaultConvenienceFee &&
                        !Number.isNaN(defaultConvenienceFee)
                          ? defaultConvenienceFee
                          : '0'
                      }
                    />
                  </div>
                  <ShowInfoBlock infoText={t('DefaultConvenienceFee-Info')} />
                </div>
              </div>
              {/* //* Default Convenience Fee Input -- End */}
            </div>
            {/* ------------------------------- */}
          </div>

          <div className='normalForm__bottom flex-start'>
            <MainSquareButton
              value={isSubmitting ? `${t('Updating')}...` : t('UpdateSettings')}
              disabled={isDisabled || isSubmitting}
              type='submit'
              onClick={onSubmitHandler}
              form='shipping-settings-form'
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SettingsConvenienceFeeOptions;
