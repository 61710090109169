import { successSVG } from '../../lib/allCustomSVGs';
import { useNavigate } from 'react-router-dom';
import MessageBanner from '../../components/messageBanner/MessageBanner';
import { useTranslation } from 'react-i18next';

const EmailVerificationSuccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <>
      <MessageBanner
        title={t('EmailVerifySuccessMainText')}
        subTitle={t('EmailVerifySuccessSubText')}
        icon={successSVG}
        btnTitle={t('Ok')}
        onSelect={() => {
          navigate('/shop');
        }}
        success={true}
      />
    </>
  );
};

export default EmailVerificationSuccess;
