import '../../styles/components/profileView/profileView.scss';

interface Props {
  pic: any;
  title: string;
  description: string;
}

const ProfileView = ({ pic, title, description }: Props) => {
  return (
    <div className='profileView'>
      <div className='profileView__pic'>
        <img src={pic} alt='pic' />
      </div>
      <div className='profileView__info'>
        <h1>{title}</h1>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default ProfileView;
