import WooCommerceIcon from '../../assets/woocommerce.png';
import MagentoIcon from '../../assets/magento.png';
import OpencartIcon from '../../assets/opencart.png';
import PrestashopIcon from '../../assets/prestashop.png';

import {
  cubeSVG,
  shoppingBagSVG,
  webShopSVG,
  homeSVG,
  shopSVG,
  webhookSVG,
  settingSVG,
  twoUsersSVG,
  IntegrationSVG,
  settingsPlusSVG,
  cmsSVG,
  pspSvg,
  fastcheckoutSVG,
  autofillSVG,
  designSVG,
  shipSVG,
  notificationSVG,
  marketingSVG,
  importSVG,
  syncSVG,
  infoSVG,
  halfStarSVG,
} from '../allCustomSVGs';

const linksTopLevel_seller = [
  {
    icon: cubeSVG,
    iconType: 'svg',
    title: 'Products',
    link: '/products',
    urlName: 'product',
    level: 1,
  },
  {
    icon: shoppingBagSVG,
    iconType: 'svg',
    title: 'Orders',
    link: '/orders',
    urlName: 'order',
    level: 1,
  },
  {
    icon: webShopSVG,
    iconType: 'svg',
    title: 'Webshop',
    link: '/shop',
    urlName: 'shop',
    level: 1,
  },
  {
    icon: IntegrationSVG,
    iconType: 'svg',
    title: 'Integration',
    link: '/integrations',
    urlName: 'integrations',
    level: 1,
  },
  {
    icon: settingsPlusSVG,
    iconType: 'svg',
    title: 'Settings',
    link: '/settings',
    urlName: 'settings',
    level: 1,
  },
];

const linksAddProduct = [
  {
    icon: cubeSVG,
    iconType: 'svg',
    title: 'AddProduct',
    link: '/products/add',
    urlName: 'add',
    level: 2,
  },
];

const linksTopLevel_admin = [
  {
    icon: homeSVG,
    iconType: 'svg',
    title: 'Home',
    link: '/admin',
    urlName: 'admin',
    specialURL: '/admin',
    level: 1,
  },
  {
    icon: shopSVG,
    iconType: 'svg',
    title: 'AllShops',
    link: '/admin/shops',
    urlName: 'shops',
    level: 1,
  },
];

const linksTopLevel_merchant = [
  {
    icon: homeSVG,
    iconType: 'svg',
    title: 'Home',
    link: '/merchant',
    urlName: 'merchant',
    specialURL: '/merchant',
    level: 1,
  },
  {
    icon: twoUsersSVG,
    iconType: 'svg',
    title: 'AllSellers',
    link: '/merchant/sellers',
    urlName: 'sellers',
    level: 1,
  },
  // {  //TODO: hidden since BE is not ready
  //   icon: settingSVG,
  //   iconType: 'svg',
  //   title: 'Settings',
  //   link: '/merchant/settings',
  //   urlName: 'settings',
  //   level: 1,
  // },
];

const links_merchant_settings = [
  {
    icon: settingSVG,
    iconType: 'svg',
    title: 'General',
    link: '/merchant/settings/general',
    urlName: 'general',
    level: 2,
  },
  {
    icon: designSVG,
    iconType: 'svg',
    title: 'Design',
    link: '/merchant/settings/design',
    urlName: 'design',
    level: 2,
  },
];

/* const linksProducts = [
  {
    icon: plusSignSVG,
    iconType: 'svg',
    title: 'Add Products',
    link: '/products/add',
    urlName: 'add',
    level: 2,
  },
]; */

const linksWebshop = [
  {
    icon: cmsSVG,
    iconType: 'svg',
    title: 'CMS',
    link: '/shop/cms',
    urlName: 'cms',
    level: 2,
  },
  {
    icon: pspSvg,
    iconType: 'svg',
    title: 'PSP',
    link: '/shop/psp',
    urlName: 'psp',
    level: 2,
  },
];

const linksIntegrations = [
  {
    icon: fastcheckoutSVG,
    iconType: 'svg',
    title: 'FastCheckout',
    link: '/integrations/fastcheckout',
    urlName: 'fastcheckout',
    level: 2,
  },
  {
    icon: fastcheckoutSVG,
    iconType: 'svg',
    title: 'SocialCommerce',
    link: '/integrations/socialcommerce',
    urlName: 'socialcommerce',
    level: 2,
  },
  {
    icon: autofillSVG,
    iconType: 'svg',
    title: 'Autofill',
    link: '/integrations/autofill',
    urlName: 'autofill',
    level: 2,
  },
];

const linksSettings = [
  {
    icon: settingSVG,
    iconType: 'svg',
    title: 'General',
    link: '/settings/general',
    urlName: 'general',
    level: 2,
  },
  {
    icon: designSVG,
    iconType: 'svg',
    title: 'Design',
    link: '/settings/design',
    urlName: 'design',
    level: 2,
  },
  {
    icon: shipSVG,
    iconType: 'svg',
    title: 'Shipping',
    link: '/settings/shipping',
    urlName: 'shipping',
    level: 2,
  },
  {
    icon: pspSvg,
    iconType: 'svg',
    title: 'ConvenienceFee',
    link: '/settings/convenienceFee',
    urlName: 'convenienceFee',
    level: 2,
  },
  {
    icon: notificationSVG,
    iconType: 'svg',
    title: 'Notifications',
    link: '/settings/notifications',
    urlName: 'notifications',
    level: 2,
  },
  // { //? commented out since it is not needed in White Label
  //   icon: subscribeSVG,
  //   iconType: 'svg',
  //   title: 'Subscription',
  //   link: '/settings/subscription',
  //   urlName: 'subscription',
  //   level: 2,
  // },
  {
    icon: webhookSVG,
    iconType: 'svg',
    title: 'Webhooks',
    link: '/settings/webhooks',
    urlName: 'webhooks',
    level: 2,
  },
  {
    icon: marketingSVG,
    iconType: 'svg',
    title: 'Marketing',
    link: '/settings/marketing',
    urlName: 'marketing',
    level: 2,
  },
  /* {
    icon: securitySVG,
    iconType: 'svg',
    title: 'Security',
    link: '/settings/security',
    urlName: 'security',
    level: 2,
  }, */
];

const linksCMS = [
  {
    icon: IntegrationSVG,
    iconType: 'svg',
    title: 'Connection',
    link: '/shop/cms/connection',
    urlName: 'connection',
    level: 3,
  },
  {
    icon: importSVG,
    iconType: 'svg',
    title: 'ImportCSV',
    link: '/shop/cms/import',
    urlName: 'import',
    level: 3,
  },
  {
    icon: syncSVG,
    iconType: 'svg',
    title: 'Sync',
    link: '/shop/cms/sync',
    urlName: 'sync',
    level: 3,
  },
];

const linksPSP = [
  {
    icon: IntegrationSVG,
    iconType: 'svg',
    title: 'Connection',
    link: '/shop/psp/connection',
    urlName: 'connection',
    level: 3,
  },
];

const linksFastcheckout = [
  {
    icon: WooCommerceIcon,
    iconType: 'img',
    title: 'WooCommerce',
    link: '/integrations/fastcheckout/woocommerce',
    urlName: 'woocommerce',
    level: 3,
  },
  {
    icon: MagentoIcon,
    iconType: 'img',
    title: 'Magento',
    link: '/integrations/fastcheckout/magento',
    urlName: 'magento',
    level: 3,
  },
  {
    icon: OpencartIcon,
    iconType: 'img',
    title: 'OpenCart',
    link: '/integrations/fastcheckout/opencart',
    urlName: 'opencart',
    level: 3,
  },
  {
    icon: PrestashopIcon,
    iconType: 'img',
    title: 'PrestaShop',
    link: '/integrations/fastcheckout/prestashop',
    urlName: 'prestashop',
    level: 3,
  },
  /* {
    icon: LightspeedIcon,
    iconType: 'img',
    title: 'Lightspeed',
    link: '/integrations/fastcheckout/lightspeed',
    urlName: 'lightspeed',
    level: 3,
  }, */
  {
    icon: halfStarSVG,
    iconType: 'svg',
    title: 'CustomIntegration',
    link: '/integrations/fastcheckout/custom',
    urlName: 'custom',
    level: 3,
  },
];

const linksAutofill = [
  {
    icon: IntegrationSVG,
    iconType: 'svg',
    title: 'Connections',
    link: '/integrations/autofill/connections',
    urlName: 'connections',
    level: 3,
  },
  {
    icon: infoSVG,
    iconType: 'svg',
    title: 'Instructions',
    link: '/integrations/autofill/instructions',
    urlName: 'instructions',
    level: 3,
  },
];

export const sellerAllSidebarLinks = [
  ...linksTopLevel_seller,
  ...linksAddProduct,
  ...linksWebshop,
  ...linksIntegrations,
  ...linksSettings,
  ...linksCMS,
  ...linksPSP,
  ...linksFastcheckout,
  ...linksAutofill,
  // ...linksProducts,
];
export const merchantAllSidebarLinks = [
  ...linksTopLevel_merchant,
  ...links_merchant_settings,
];

export const adminAllSidebarLinks = [...linksTopLevel_admin];

export const allTopLevelLinks = [
  ...linksTopLevel_seller,
  ...linksTopLevel_merchant,
  ...linksTopLevel_admin,
];
