import '../../styles/components/filter/filter.scss';
import ToggleSwitch from '../../ui/buttons/ToggleSwitch';
import { motion } from 'framer-motion';

interface Props {
  filterItems: any;
  setFilterItems: (input: any) => void;
}

const Filter = ({ filterItems, setFilterItems }: Props) => {
  return (
    <motion.div
      initial={{ scale: 0.95, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0.95, opacity: 0 }}
      transition={{ type: 'just' }}
      className={`filter`}
    >
      {filterItems?.map((el: any, i: number) => {
        return (
          <div key={i} className='filter__items'>
            <div className='filter__items--title'>
              <h2>{el?.title}</h2>
            </div>
            <div className='filter__items--container'>
              {el?.filters?.map((item: any, index: number) => {
                return (
                  <div
                    key={i + index}
                    className='filter__items--container-item'
                  >
                    <ToggleSwitch
                      title={item?.name}
                      defaultChecked={item?.value}
                      // isOn={item?.value}
                      onClick={() => {
                        let filterItemsCopy;
                        if (filterItems?.length > 0) {
                          filterItemsCopy = [...filterItems];

                          /*    //set all current group filters values as false
                          const currentGroupFilters =
                            filterItemsCopy[i]?.filters;
                          for (
                            let j = 0;
                            j < currentGroupFilters?.length;
                            j++
                          ) {
                            filterItemsCopy[i].filters[j].value = false;
                          } */

                          //select clicked option
                          const previousValue =
                            filterItemsCopy[i]?.filters[index]?.value;
                          const newValue = !previousValue;
                          filterItemsCopy[i].filters[index].value = newValue;
                          setFilterItems(filterItemsCopy);
                        }
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </motion.div>
  );
};

export default Filter;
