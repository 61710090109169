import { useSelector } from 'react-redux';
import CMSSelector from '../../components/selection/CMSSelector';
import { RootState, useAppDispatch } from '../../store/store';
import { motion } from 'framer-motion';
import {
  OnboardingStagesMap,
  setStageComplete,
  updateIntercom,
  updateOnboardingStage,
} from '../../store/reducers/userSlice';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import { useState } from 'react';
import './onboarding-page.css';
import '../../styles/components/onboarding/setShopCMS1.scss';
import MainPurpleButton from '../../ui/buttons/MainPurpleButton';
import { submitCustomOption } from '../../lib/api/psp-cms-otherOptions-call';
import { useTranslation } from 'react-i18next';
import { submitOnboardingStatus } from '../../lib/api/post-onboarding-status';

const SetShopCMS1 = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const options = useSelector(
    (state: RootState) => state.user.onboarding.availableCMS
  );
  const preSelectedCMS = useSelector(
    (state: RootState) => state.user.company.preSelectedCMS_PSP.preSelectedCMS
  );
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  const [selectedCMS, setSelectedCMS] = useState('');
  const [showAllCMS, setShowAllCMS] = useState(false);

  async function handleContinue() {
    /* try {
      const response = await sendSelectedCMS(selectedCMS, companyName);
      if (response.data) {
        dispatch(
          updateOnboardingStage(OnboardingStagesMap.SettingCMSConnection.name)
        );
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.IntegratingCMS1.name,
            isCompleted: true,
          })
        );
      } else {
        setError(response.message);
        onFormSubmitedFailed(response.message);
      }
    } catch (exception) {
      console.log('Error during authentication request');
    } */

    /* if (selectedCMS !== 'null') {
      dispatch(
        updateIntercom({
          cms: selectedCMS,
        })
      );
    } */

    if (selectedCMS === 'nocms') {
      const statusRes = await submitOnboardingStatus('cms', 'SocialCommerce');
      dispatch(
        updateIntercom({
          cms: 'social_commerce',
        })
      );
      if (statusRes) {
        skip();
      }
    } else if (
      /* selectedCMS === 'magento' || */
      selectedCMS === 'shopify' ||
      selectedCMS === 'bigcommerce'
    ) {
      const res: any = await submitCustomOption('cms', selectedCMS);
      const statusRes = await submitOnboardingStatus('cms', selectedCMS);
      if (res && statusRes) {
        skip();
      }
    } else {
      if (selectedCMS === 'other') {
        dispatch(
          updateOnboardingStage(OnboardingStagesMap.SettingCMSConnection.name)
        );
        dispatch(
          setStageComplete({
            stage: OnboardingStagesMap.IntegratingCMS1.name,
            isCompleted: true,
          })
        );
      } else {
        dispatch(
          updateIntercom({
            cms: selectedCMS,
          })
        );
        const statusRes = await submitOnboardingStatus('cms', selectedCMS);
        if (statusRes) {
          dispatch(
            updateOnboardingStage(OnboardingStagesMap.SettingCMSConnection.name)
          );
          dispatch(
            setStageComplete({
              stage: OnboardingStagesMap.IntegratingCMS1.name,
              isCompleted: true,
            })
          );
        }
      }
    }
  }

  function skip() {
    dispatch(updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name));
    dispatch(
      setStageComplete({
        stage: OnboardingStagesMap.IntegratingCMS1.name,
        isCompleted: true,
      })
    );
  }
  const isDisabled = selectedCMS === '' ? true : false;
  return (
    <motion.div
      {...pageSlideOutForward}
      className='setShopCMS1 animate-fade_in_from_right'
    >
      <div className='setShopCMS1-container'>
        <div className='setShopCMS1--top '>
          <h1>{t('OnboardingCMS-SelectH1')}</h1>
          <h2>{t('OnboardingCMS-SelectH2')}</h2>
        </div>

        <CMSSelector
          setSelectedCMS={(cmsName) => setSelectedCMS(cmsName)}
          options={[...options]}
          showAllCMS={showAllCMS}
        />

        <motion.div className='setShopCMS1--bottom'>
          <MainPurpleButton
            onClick={() => handleContinue()}
            value={t('Continue')}
            disabled={isDisabled}
            classes='text-white rounded-full'
          />
          <div className='pt-2'>
            {preSelectedCMS && (
              <>
                <a
                  className='skip-link'
                  onClick={() => {
                    setShowAllCMS(true);
                  }}
                >
                  {t('SelectOtherCMS')}
                </a>
                {` ${t('Or')} `}
              </>
            )}
            <a className='skip-link' onClick={skip}>
              {t('SkipStep')}
            </a>
          </div>
        </motion.div>
        {/* )} */}
      </div>
      <div className='setShopCMS1--footer'>
        <a target={'_blank'} href={`${merchantTheme?.terms_url}`}>
          {t('Terms')}
        </a>
        {` & `}
        <a target={'_blank'} href={`${merchantTheme?.privacy_url}`}>
          {t('Privacy')}
        </a>
      </div>
    </motion.div>
  );
};

export default SetShopCMS1;
