import '../../styles/pages/shop/shopPage.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import PageWrapper from '../../components/page-wrappers/PageWrapper';
import CategoryCard from '../../ui/cards/CategoryCard';
import { shopSVG } from '../../lib/allCustomSVGs';
import ProfileView from '../../components/profileView/ProfileView';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AdminShopsPage from './allShops/AdminShopsPage';
import userPic from '../../imgs/user.jpg';

const AdminHomePage = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const activeLink = location.pathname.substring(1);
  const userProfile = useSelector((state: RootState) => state.user.profile);
  const isInitSuccess = useSelector(
    (state: RootState) => state.app.isInitSuccess
  );
  const shopsPageContent = () => {
    return (
      <div className='shopPage'>
        <ProfileView
          pic={userPic}
          title={
            isInitSuccess
              ? `${userProfile?.first_name} ${userProfile?.last_name}`
              : ''
          }
          description={t('WelcomeAdmin')}
        />
        <div className='shopPage__container1'>
          <CategoryCard
            icon={shopSVG}
            title={t('AllShops')}
            subTitle={t('ManageAvailableShops')}
            link='shops'
          />
        </div>
      </div>
    );
  };

  return (
    <PageWrapper>
      {activeLink === 'admin' && shopsPageContent()}
      <Routes>
        <Route path='shops/*' element={<AdminShopsPage />} />
      </Routes>
    </PageWrapper>
  );
};

export default AdminHomePage;
