import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Routes, useLocation } from 'react-router-dom';
import PageWrapper from '../../components/page-wrappers/PageWrapper';
import AllOrders from './AllOrders';
import OrderDetailPage from './OrderDetailPage';

const OrdersPage = () => {
  const location = useLocation();
  const activeLink = location.pathname.substring(1);
  const [t] = useTranslation();
  /* ----- page number states of all orders page start----- */
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  /* ----- page number states of all orders page  end  ----- */

  /*------------------------- FILTER STATE START ------------------------- */
  const [filterItems, setFilterItems] = useState([
    /* {
      title: t('Order'),
      parameterName: 'order',
      filters: [
        // { name: t('None'), parameterName: 'none', value: true },
        { name: t('Ascending'), parameterName: 'asc', value: false },
        { name: t('Descending'), parameterName: 'desc', value: false },
      ],
    },
    {
      title: t('Date'),
      parameterName: 'date',
      filters: [
        // { name: t('None'), parameterName: 'none', value: true },
        { name: t('Latest'), parameterName: '1', value: false },
        { name: t('Oldest'), parameterName: '-1', value: false },
      ],
    }, */
    {
      title: t('Status'),
      parameterName: 'status',
      filters: [
        // { name: t('None'), parameterName: 'none', value: true },
        // { name: t('Completed'), parameterName: 'completed', value: false },
        // { name: t('All'), parameterName: '', value: false },
        { name: t('Paid'), parameterName: 'paid', value: true },
        { name: t('Pending'), parameterName: 'pending', value: false },
        { name: t('Cancelled'), parameterName: 'cancelled', value: false },
        { name: t('Refunded'), parameterName: 'refunded', value: false },
        // { name: t('Created'), parameterName: 'created', value: false },
        // { name: t('Open'), parameterName: 'open', value: false },
        // { name: t('Failed'), parameterName: 'failed', value: false },
      ],
    },
  ]);

  useEffect(() => {
    setCurrentPageNumber(1);
  }, [filterItems]);
  /*------------------------- FILTER STATE END --------------------------- */

  return (
    <PageWrapper>
      <Routes>
        <Route
          path='/*'
          element={
            <AllOrders
              currentPageNumber={currentPageNumber}
              setCurrentPageNumber={setCurrentPageNumber}
              filterItems={filterItems}
              setFilterItems={setFilterItems}
            />
          }
        />
        <Route path='/:orderId' element={<OrderDetailPage />} />
      </Routes>
    </PageWrapper>
  );
};

export default OrdersPage;
