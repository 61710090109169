import { useTranslation } from 'react-i18next';
import { warningWithCircleSVG } from '../../lib/allCustomSVGs';
import '../../styles/generic/commonModal.scss';
import MainSquareButton from '../../ui/buttons/MainSquareButton';
import CustomSVGs from '../../ui/customSVGs/CustomSVGs';

interface Props {
  handler: () => void;
  closeModal: () => void;
  mainTitle: string;
  subTitle1: string;
  subTitle2: string;
}

const CommanModal = ({
  handler,
  closeModal,
  mainTitle,
  subTitle1,
  subTitle2,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className='commonModal'>
      <div className='commonModal__title'>
        <h1>{mainTitle}</h1>
      </div>
      <div className='commonModal__content'>
        <CustomSVGs svg={warningWithCircleSVG} />
        <h1>{subTitle1}</h1>
        <h1>{subTitle2}</h1>
        <div className='commonModal__content--buttons'>
          <MainSquareButton
            onClick={(e) => {
              e.preventDefault();
              handler();
              closeModal();
            }}
            type={'button'}
            value={t('Yes')}
            normal={true}
            buttonType={'danger'}
          />
          <MainSquareButton
            onClick={(e) => {
              e.preventDefault();
              closeModal();
            }}
            type={'button'}
            value={t('No')}
            normal={true}
            buttonType={'light'}
          />
        </div>
      </div>
    </div>
  );
};

export default CommanModal;
