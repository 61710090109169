import { useTranslation } from 'react-i18next';
import '../../styles/components/productWizard/wizardButtonPanel.scss';
import loadingGif from '../../assets/gif/loading.gif';

interface Props {
  increaseStep: () => void;
  decreaseStep: () => void;
  isDisabled?: boolean;
  handlerFunction?: () => void;
  currentStep: number;
  loading?: boolean;
}

const WizardButtonPanel = ({
  increaseStep,
  decreaseStep,
  currentStep,
  isDisabled,
  handlerFunction,
  loading,
}: Props) => {
  const { t } = useTranslation();

  const handleNextBtn = () => {
    if (!isDisabled) {
      if (handlerFunction) {
        handlerFunction();
      }
    }
  };

  const renderNextBtnText = () => {
    if (currentStep === 7) {
      return t('AddProduct');
    }
    if (currentStep === 8) {
      return t('ViewProduct');
    }

    return t('Next');
  };

  return (
    <div
      data-wizard-btn-panel-current-step={currentStep?.toString()}
      className='wizardButtonPanel'
    >
      <button onClick={decreaseStep}>{t('Previous')}</button>
      <button disabled={isDisabled} onClick={handleNextBtn} type='submit'>
        {/*  {renderNextBtnText()} */}
        {loading && <img src={loadingGif} alt='Loading...' />}
        {!loading && renderNextBtnText()}
      </button>
    </div>
  );
};

export default WizardButtonPanel;
