import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { crossSVG } from '../../lib/allCustomSVGs';
import '../../styles/inputs/tagInput.scss';
import CustomSVGs from '../customSVGs/CustomSVGs';

/* 
---useage in parent component--- 
--Create a function like this and get the values
--------------------------------------------------------
const selectedTages = (tags: string[], id?: string) => {
  console.log(id, tags);
}; 
--------------------------------------------------------
*/

interface Props {
  selectedTags: (input: string[], id?: string) => void;
  id: string;
}

const TagInput = ({ selectedTags, id }: Props) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState<string[]>([]);
  const removeTags = (indexToRemove: number) => {
    setTags(tags.filter((_, index) => index !== indexToRemove));
    selectedTags(
      tags.filter((_, index) => index !== indexToRemove),
      id
    );
  };
  const addTags = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if ((event.target as HTMLInputElement).value !== '') {
      setTags([...tags, (event.target as HTMLInputElement).value]);
      selectedTags([...tags, (event.target as HTMLInputElement).value], id);
      (event.target as HTMLInputElement).value = '';
    }
  };
  return (
    <div className='tagInput'>
      <input
        type='text'
        id={id}
        onKeyUp={(e) => (e.key === 'Enter' ? addTags(e) : null)}
        style={{ fontFamily: 'var(--main-font-secondary)' }}
        placeholder={t('EnterToAddTags')}
      />
      <ul className='tagInput__list'>
        {tags.map((tag: string, index: number) => {
          return (
            <li key={index} className='tagInput__list--item'>
              <span className='tagInput__list--item-title'>{tag}</span>
              <div
                title='Remove Tag'
                onClick={() => {
                  removeTags(index);
                }}
                className='tagInput__list--item-icon'
              >
                <CustomSVGs svg={crossSVG} />
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default TagInput;
