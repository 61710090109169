import { useNavigate } from 'react-router-dom';
import '../../styles/cards/categoryCard.scss';
import CustomSVGs from '../customSVGs/CustomSVGs';

interface Props {
  icon: any;
  title: string;
  subTitle: string;
  subTitle2?: string;
  link: string;
  isSelected?: boolean;
  onSelect?: (event: React.MouseEvent) => void;
}

const CategoryCard = ({
  icon,
  title,
  subTitle,
  subTitle2,
  link,
  isSelected,
  onSelect,
}: Props) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={
        onSelect
          ? onSelect
          : () => {
              navigate(link);
            }
      }
      className={
        isSelected ? 'categoryCard categoryCard__isSelected' : 'categoryCard'
      }
    >
      <div
        className={
          isSelected ? 'categoryCard__icon__isSelected' : 'categoryCard__icon'
        }
      >
        <CustomSVGs svg={icon} />
      </div>
      <div className='categoryCard__titles'>
        <p>{title}</p>
        <span>{subTitle}</span>
        {subTitle2 && (
          <>
            <br />
            <span style={{ fontSize: 8 }}>{subTitle2}</span>
          </>
        )}
      </div>
    </div>
  );
};

export default CategoryCard;
