import SubPageInnerContentHolder from '../../../components/subPageContentHolder/SubPageInnerContentHolder';
import { Route, Routes, useLocation } from 'react-router-dom';
import SettingsShippingOptions from './SettingsShippingOptions';
import { useTranslation } from 'react-i18next';
import store from '../../../store/store';

const SettingsShipping = () => {
  const location = useLocation();
  const activeLink = location.pathname;
  const { t } = useTranslation();
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;

  return (
    <SubPageInnerContentHolder
      title={t('CompanyShipping')}
      subTitle={t('ManageCompanyShippingDetails')}
      backBtnLink={isWhiteLabel ? undefined : '/products'}
      /* mainBtnIcon={plusSignSVG}
            mainBtnTitle='Create'
            mainBtnLink='/shop/integrations/autofill/add' */
    >
      <Routes>
        {/* <Route path='/add' element={<AutofillAdd />} /> */}
        <Route path='/' element={<SettingsShippingOptions />} />
      </Routes>
    </SubPageInnerContentHolder>
  );
};

export default SettingsShipping;
