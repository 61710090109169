import { useNavigate } from 'react-router-dom';
import { warningWithCircleSVG } from '../../../lib/allCustomSVGs';
import '../../../styles/components/subPageList/subPageListDelete.scss';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import CustomSVGs from '../../../ui/customSVGs/CustomSVGs';
import { useParams } from 'react-router-dom';
import store from '../../../store/store';
import { RequestInfo } from '../../../interfaces/RequestInfo';
import { WEBHOOK_ENDPOINT } from '../../../lib/constants/urls';
import { asyncHandler } from '../../../lib/api/async-handler';
import { useTranslation } from 'react-i18next';

const DeleteWebhook = () => {
  const navigate = useNavigate();
  const { webhookId } = useParams();
  const { t } = useTranslation();

  const confirmDeletion = () => {
    const data = deleteWebhook();
    data
      .then((result) => {
        navigate('/settings/webhooks');
      })
      .catch((err) => {
        console.error(err);
        navigate('/settings/webhooks');
      });
  };

  /* ----------------------------API CALL START---------------------------- */
  async function deleteWebhook() {
    try {
      const accessToken = store.getState().auth.authorization.accessToken;
      const isWhiteLabel = store.getState().UI?.isWhiteLabel;
      let sellerToken: string;
      if (isWhiteLabel) {
        sellerToken = accessToken?.currentWhiteLabelsellerToken;
      } else {
        sellerToken = accessToken?.sellerToken;
      }

      const requestInfo: RequestInfo = {
        url: `${WEBHOOK_ENDPOINT}/${webhookId}`,
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-type': 'application/x-www-form-urlencoded',
          Authorization: accessToken.type + ' ' + sellerToken,
        },
      };

      const actionType = 'DELETE_WEBHOOK';
      const customMsg = t('WebhookDeletedSuccess-msg');

      const res: any = await asyncHandler(requestInfo, actionType, customMsg);

      const response = res.data;

      if (res.actionType === actionType && response.success) {
        return response.result;
      }
    } catch (exception) {
      console.log('Error during request');
    }
  }
  /* ----------------------------API CALL END---------------------------- */
  return (
    <div className='subPageListDelete'>
      <CustomSVGs svg={warningWithCircleSVG} />
      <h1>{`${t('AskToDelete-msg')} ${t('TheSelectedWebhook')}`}</h1>
      <div className='subPageListDelete__buttons'>
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            confirmDeletion();
          }}
          type={'button'}
          value={t('Yes')}
          normal={true}
          buttonType={'danger'}
        />
        <MainSquareButton
          onClick={(e) => {
            e.preventDefault();
            navigate('/settings/webhooks');
          }}
          type={'button'}
          value={t('No')}
          normal={true}
          buttonType={'light'}
        />
      </div>
    </div>
  );
};

export default DeleteWebhook;
