import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import generateSKU from '../../../lib/helper-functions/generateSKU';
import { trimFromStartToLimitedNumber } from '../../../lib/helper-functions/trimSKU';
import MainSquareButton from '../../../ui/buttons/MainSquareButton';
import MaskedCurrencyInput from '../../../ui/inputs/CurrencyInput';
import MultipleImagesInput from '../../../ui/inputs/MultipleImagesInput';
import NormalInput from '../../../ui/inputs/NormalInput';
import NormalSelectInput from '../../../ui/inputs/NormalSelectInput';
import TextArea from '../../../ui/inputs/TextArea';
import TextAreaAI from '../../../ui/inputs/TextAreaAI';

interface Props {
  productVariantsArr: any;
  setProductVariantsArr: (input: any) => void;
  currentId: any;
  closeWindow: () => void;
}

const EditProductVariant = ({
  productVariantsArr,
  setProductVariantsArr,
  currentId,
  closeWindow,
}: Props) => {
  const { t } = useTranslation();
  /* productVariant state */
  const [productVariants, setProductVariants] = useState({
    productVariantName:
      productVariantsArr[currentId].productVariants.productVariantName,
    productVariantDescription:
      productVariantsArr[currentId].productVariants.productVariantDescription,
    productVariantSKU:
      productVariantsArr[currentId].productVariants.productVariantSKU,
    productVariantPrice:
      productVariantsArr[currentId].productVariants.productVariantPrice,
    productVariantStock:
      productVariantsArr[currentId].productVariants.productVariantStock,
  });
  const [productVariantStatus, setProductVariantStatus] = useState(
    productVariantsArr[currentId].productVariantStatus
  );

  const [generatedSKU, setGeneratedSKU] = useState<string>('');

  useEffect(() => {
    setGeneratedSKU(
      trimFromStartToLimitedNumber(
        generateSKU(productVariants.productVariantName),
        20
      )
    );
  }, [productVariants.productVariantName]);

  /* ProductVariant images state */
  const [selectedProductVariantImages, setSelectedProductVariantImages] =
    useState(
      productVariantsArr[currentId].productVariantImages
        .selectedProductVariantImages
    );
  const [previewProductVariantImages, setPreviewProductVariantImages] =
    useState<string>(
      productVariantsArr[currentId].productVariantImages
        .previewProductVariantImages
    );

  const productVariantStatusArr = [
    { OptionValue: 'active', OptionName: 'Active' },
    { OptionValue: 'inactive', OptionName: 'Inactive' },
  ];

  //Variant Attributes state
  /* const [variantAttributes, setVariantAttributes] = useState<
    VariantAttributesInterface[]
  >([]); */

  const editedVariant = {
    productVariants,
    productVariantStatus,
    productVariantImages: {
      selectedProductVariantImages,
      previewProductVariantImages,
    },
    variantAttributes: productVariantsArr[currentId].variantAttributes,
  };

  const onSubmitHandler = (e: any) => {
    e.preventDefault();

    if (productVariants.productVariantSKU === '') {
      setProductVariants((prev) => {
        return { ...prev, productVariantSKU: generatedSKU };
      });
    }

    const allVariantsExceptCurrent = productVariantsArr.filter(
      (el: any, i: number) => i !== currentId
    );
    console.log([...allVariantsExceptCurrent, editedVariant]);
    setProductVariantsArr([...allVariantsExceptCurrent, editedVariant]);

    /* giving sometime to process the data and save it in the state*/
    let closeWindowTimeout = setTimeout(() => {
      closeWindow();
      clearTimeout(closeWindowTimeout);
    }, 500);
  };

  return (
    <form
      id='edit-productVariant-form'
      onSubmit={onSubmitHandler}
      className='normalForm'
    >
      <div className='normalForm__top'>
        <div className='normalForm__titleContainer--With-marginBottom'>
          <h1>{t('EditVariant')}</h1>
        </div>
        <MultipleImagesInput
          setSendingFiles={setSelectedProductVariantImages}
          setSendingFilesPreviews={setPreviewProductVariantImages}
          receivingFiles={selectedProductVariantImages}
          labelName={t('ProductMedia')}
          acceptedFileTypes={'.png, .jpeg, .webp, .mp4, .gif'}
          id={'edit-productVariant-images'}
          manyImages={false}
        />
        <div className='normalForm__container1'>
          <div className='normalForm__container1--left'>
            <NormalInput
              changeListeners={[
                (e) =>
                  setProductVariants((prev) => {
                    return { ...prev, productVariantName: e.target.value };
                  }),
              ]}
              id='edit-productVariant-name'
              value={productVariants.productVariantName}
              placeholder={t('ProductName')}
              required={true}
              title={t('ProductName-validation')}
            />
          </div>
          <div className='normalForm__container1--right'>
            <TextAreaAI
              id='edit-productVariant-description'
              productDescription={productVariants.productVariantDescription}
              setProductDescription={(description) =>
                setProductVariants((prevProduct) => ({
                  ...prevProduct,
                  productVariantDescription: description,
                }))
              }
              placeholder={t('ProductDescription')}
              required={true}
              title={t('ProductDescription-validation')}
              textForAI={productVariants.productVariantName}
            />
          </div>
        </div>

        {/* ------------------------------------------------------- */}

        <div className='normalForm__container1'>
          <div className='normalForm__container1--left'>
            <NormalSelectInput
              defaultValue={productVariantStatus}
              setSelect={setProductVariantStatus}
              selectOptionArr={productVariantStatusArr}
              placeholder={t('Status')}
              id={'edit-productVariant-status'}
            />
          </div>
          <div className='normalForm__container1--right'>
            <NormalInput
              changeListeners={[
                (e) =>
                  setProductVariants((prev) => {
                    return { ...prev, productVariantSKU: e.target.value };
                  }),
              ]}
              id='edit-productVariant-sku'
              value={productVariants.productVariantSKU}
              placeholder='SKU'
              pattern='[^ ]{1,20}$'
              /* required={true} */
              innerPlaceholder={generatedSKU}
              title={t('ProductSKU-validation')}
              maxlength={20}
            />
          </div>
        </div>
        <div className='normalForm__container1'>
          <div className='normalForm__container1--left'>
            <NormalInput
              changeListeners={[
                (e) =>
                  setProductVariants((prev) => {
                    return { ...prev, productVariantStock: e.target.value };
                  }),
              ]}
              id='edit-productVariant-stock'
              value={productVariants.productVariantStock}
              placeholder={t('Stock')}
              required={true}
              pattern='^[0-9]{1,10}$'
              title={t('ProductStock-validation')}
            />
          </div>
          <div className='normalForm__container1--right'>
            <MaskedCurrencyInput
              changeListeners={[
                (value) =>
                  setProductVariants((prev) => {
                    return { ...prev, productVariantPrice: value };
                  }),
              ]}
              id='edit-productVariant-price'
              value={
                productVariants.productVariantPrice &&
                !Number.isNaN(productVariants.productVariantPrice)
                  ? productVariants.productVariantPrice
                  : '0'
              }
              placeholder={t('Price')}
              required={true}
              title={t('OnlyNumbers-validation')}
              type='text'
              pattern='^[\d|\.|\,]+/'
              defaultValue={
                productVariants.productVariantPrice &&
                !Number.isNaN(productVariants.productVariantPrice)
                  ? productVariants.productVariantPrice
                  : '0'
              }
            />
          </div>
        </div>
        {/* ------------------------------------------------------- */}

        {/* ----------------------Attributes start--------------------- */}
        <div className='normalForm__titleContainer--With-marginBottom'>
          <h1>{t('Attributes')}</h1>
        </div>
        {productVariantsArr[currentId].variantAttributes.map(
          (el: any, index: number) => {
            return (
              <div
                key={index}
                className='normalForm__top--container1 normalForm-margin-bottom-2rem'
              >
                <div className='normalForm__top--container1-left normalForm-margin-bottom-0'>
                  <NormalInput
                    id={`edit-productVariant-name-${el.id}`}
                    value={el.name}
                    placeholder={t('Name')}
                    readonly={true}
                  />
                </div>
                <div className='normalForm__top--container1-right'>
                  <NormalInput
                    id={`edit-productVariant-value-${el.id}`}
                    value={el.value}
                    placeholder={t('Value')}
                    readonly={true}
                  />
                </div>
              </div>
            );
          }
        )}
        {/* ----------------------Attributes end--------------------- */}
      </div>

      <div className='normalForm__bottom flex-start'>
        <MainSquareButton
          value={t('EditVariant')}
          /* icon={<CustomSVGs svg={plusSignSVG} />} */
          /* disabled={isDisabled} */
          type='submit'
          form='edit-productVariant-form'
        />
      </div>
    </form>
  );
};

export default EditProductVariant;
