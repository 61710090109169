/* ------api imports------- */
import { applicationWWWXRLEncodedBodyBuilder } from '../utils/body-builder';
import { LOG_ENDPOINT } from '../constants/urls';
import { RequestInfo } from '../../interfaces/RequestInfo';

/* ------api connection------- */
export async function submitLog(
  logType: 'warning' | 'error' | 'info',
  data: any
) {
  let sendingData;

  sendingData = { log_type: logType, data: data };

  try {
    const requestInfo: RequestInfo = {
      url: LOG_ENDPOINT,
      method: 'POST',
      body: applicationWWWXRLEncodedBodyBuilder(sendingData),
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/x-www-form-urlencoded',
      },
    };

    if (sendingData?.data && sendingData?.log_type) {
      const res: any = await fetch(requestInfo.url, {
        method: requestInfo.method,
        headers: requestInfo.headers,
        body: requestInfo.body,
      });

      const response = await res.json();
      console.log(sendingData);
      if (response.success) {
        console.log('log sent -->');
      }
    }
  } catch (exception) {
    console.log('Error during request');
  }
}
