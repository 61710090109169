import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';

//mui imports--

//api call imports
import { SYNC_HISTORY_ENDPOINT } from '../../../../lib/constants/urls';
import { RequestInfo } from '../../../../interfaces/RequestInfo';
import { asyncHandler } from '../../../../lib/api/async-handler';
import store from '../../../../store/store';
import LoadingSpinner from '../../../../ui/loadingSpinner/LoadingSpinner';
import { useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import CustomDataGrid from '../../../../ui/customDataGrid.tsx/CustomDataGrid';
import NoData from '../../../../ui/noData/NoData';

const CMSSync = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const activeLink = location.pathname;
  const [syncHistory, setSyncHistory] = useState();
  const [loading, setLoading] = useState('pending');

  /*------------------------- PAGINATION STATE START ------------------------- */
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  /*------------------------- PAGINATION STATE END ------------------------- */

  /* ----------- pagination stuf start ------------ */

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    setCurrentPageNumber(event.selected + 1);
  };
  /* ----------- pagination stuf end   ------------ */

  /* ----------------------------API CALL START---------------------------- */
  const accessToken = store.getState().auth.authorization.accessToken;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  let sellerToken: string;
  if (isWhiteLabel) {
    sellerToken = accessToken?.currentWhiteLabelsellerToken;
  } else {
    sellerToken = accessToken?.sellerToken;
  }

  const requestInfo: RequestInfo = {
    url: `${SYNC_HISTORY_ENDPOINT}?page=${currentPageNumber}`,
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/x-www-form-urlencoded',
      Authorization: accessToken.type + ' ' + sellerToken,
    },
  };

  const actionType = 'GET_SYNC_HISTORY';
  const customMsg = 'Sync History Loaded Successfully.';

  useEffect(() => {
    const getSyncHistory = async () => {
      const res: any = await asyncHandler(
        requestInfo,
        actionType,
        customMsg,
        false
      );

      const response = res?.data;

      if (!response.success) {
        setLoading('error');
      }
      if (response.result.data.length === 0) {
        setLoading('null');
      } else {
        if (res.actionType === actionType && response.success === true) {
          setLoading('success');
          const syncHistoryResult = response?.result;
          setTotalRecords(syncHistoryResult?.total);
          setSyncHistory(syncHistoryResult?.data);
        }
      }
    };

    getSyncHistory();
  }, [currentPageNumber]);
  /* ----------------------------API CALL END---------------------------- */

  return (
    <SubPageInnerContentHolder
      title={t('CMS-Sync')}
      subTitle={t('ManageCMS-Sync')}
      backBtnLink={isWhiteLabel ? undefined : '/shop'}
      /* mainBtnIcon={plusSignSVG}
            mainBtnTitle='Create'
            mainBtnLink='/shop/integrations/autofill/add' */
    >
      {activeLink === '/shop/cms/sync' && (
        <div className='productsPage'>
          <div className='productsPage__top'>
            <div className='productsPage__top--left'></div>
            <div className='productsPage__top--right'></div>
          </div>
          <div className='productsPage__bottom'>
            <div className='productsPage__bottom--dataGridContainer'>
              {loading === 'null' && (
                <NoData title={t('NoSyncHistoryFound-msg')} />
              )}
              {loading === 'error' && (
                <NoData
                  title={t('FetchingDataError-msg')}
                  subTitle='Something went wrong :('
                />
              )}
              {loading === 'pending' && <LoadingSpinner />}
              {loading === 'success' && (
                <>
                  {syncHistory ? (
                    <CustomDataGrid
                      allRows={syncHistory}
                      titleField='product_id'
                      excludingField={[
                        'id',
                        'cms_id',
                        'data',
                        'queue_id',
                        'shop_id',
                        'retry_attempts',
                      ]}
                      borderType={'round'}
                    />
                  ) : (
                    <NoData title={t('NoSyncHistoryFound-msg')} />
                  )}
                </>
              )}
            </div>
            {loading === 'success' && syncHistory && (
              <ReactPaginate
                breakLabel='...'
                nextLabel={t(`${'Next'} >`)}
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                initialPage={currentPageNumber - 1}
                pageCount={pageCount}
                previousLabel={t(`< ${'Previous'}`)}
                renderOnZeroPageCount={undefined}
                marginPagesDisplayed={2}
                containerClassName='pagination'
                pageLinkClassName='pagination__page-num'
                previousLinkClassName='pagination__page-num'
                nextLinkClassName='pagination__page-num'
                disabledLinkClassName='pagination__page-num-disabled'
                activeLinkClassName='pagination__active'
              />
            )}
          </div>
        </div>
      )}

      {/* <Routes>
              <Route path='/add' element={<AutofillAdd />} />
            </Routes> */}
    </SubPageInnerContentHolder>
  );
};

export default CMSSync;
