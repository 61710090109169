export default function getCurrencySymbol(locale: string, currency: string) {
  //@parameter 1 example = "en-US"
  //@parameter 2 example = 'USD'
  //@return value example = "$"
  return (0)
    .toLocaleString(locale, {
      style: 'currency',
      currency: currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}
