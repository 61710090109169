import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { pageSlideOutForward } from '../../framer-animations/page-slide-in-out';
import {
  OnboardingStagesMap,
  setStageComplete,
  updateIntercom,
  updateOnboardingStage,
} from '../../store/reducers/userSlice';
import { RootState } from '../../store/store';
import './onboarding-page.css';
import '../../styles/components/onboarding/enterCMSConnectionDetails.scss';
/* import { arrowRightThickSVG, skipArrowSVG } from '../../lib/allCustomSVGs';
import MainSquareButton from '../../ui/buttons/MainSquareButton'; */
import MainPurpleButton from '../../ui/buttons/MainPurpleButton';
import NormalInput from '../../ui/inputs/NormalInput';
import { applicationWWWXRLEncodedBodyBuilder } from '../../lib/utils/body-builder';
import { CMS_ENDPOINT } from '../../lib/constants/urls';
import { useDispatch } from 'react-redux';
import { RequestInfo } from '../../interfaces/RequestInfo';
import { asyncHandler } from '../../lib/api/async-handler';
import store from '../../store/store';
import { submitCustomOption } from '../../lib/api/psp-cms-otherOptions-call';
import { useTranslation } from 'react-i18next';
import { submitOnboardingStatus } from '../../lib/api/post-onboarding-status';
import makeFirstLetterCapital from '../../lib/helper-functions/makeFirstLetterCapital';

const EnterCMSConnectionDetails = () => {
  const onboarding_object = useSelector(
    (state: RootState) => state.user.onboarding.onboarding_object
  );
  const [cmsInputs, setCMSInputs] = useState([]);
  const [cmsInputValues, setCMSInputValues] = useState<any>();
  console.log(cmsInputs);
  console.log(cmsInputValues);

  const { t } = useTranslation();
  const selectedCMS: any = useSelector(
    (state: RootState) => state.user.company.cmsSelected
  );
  const shopId = useSelector((state: RootState) => state.user.company.shop_id);
  const merchantTheme = useSelector(
    (state: RootState) => state.user.merchant.config
  );

  /* ------other option state ----------*/
  const optionType = 'cms';
  const [customOption, setCustomOption] = useState('');

  const dispatch = useDispatch();

  /* ================================================================================= */
  useEffect(() => {
    const currentCMS = onboarding_object?.available_cms?.filter((el: any) => {
      const currentCMS_Obj: any = Object.values(el)[0];
      const currentCMS_Obj_label = currentCMS_Obj?.label?.toLowerCase();
      return currentCMS_Obj_label === selectedCMS ? currentCMS_Obj : null;
    });

    if (currentCMS?.length > 0) {
      const temp: any = Object.values(currentCMS)[0];
      const found_CMS: any = Object.values(temp)[0];
      const currentCMS_inputs = found_CMS?.connection_method?.manual;
      if (currentCMS_inputs?.length > 0) {
        let temp_inputs: any = [];
        let temp_input_values: any = {};
        for (let i = 0; i < currentCMS_inputs[0].length; i++) {
          const temp = currentCMS_inputs[0][i].label;
          const formattedTemp = temp?.toLowerCase()?.replaceAll(' ', '_');
          temp_inputs.push(formattedTemp);
          temp_input_values[formattedTemp] = '';
        }

        setCMSInputs(temp_inputs);
        setCMSInputValues(temp_input_values);
      }
    }
  }, [selectedCMS]);
  /* ================================================================================= */

  async function onContinue(e: any) {
    e.preventDefault();
    if (selectedCMS === 'other') {
      const res: any = await submitCustomOption('cms', customOption);
      if (res) {
        const statusRes = await submitOnboardingStatus('cms', customOption);
        if (statusRes) {
          dispatch(
            updateIntercom({
              cms: customOption,
            })
          );
          dispatch(
            updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name)
          );
          dispatch(
            setStageComplete({
              stage: OnboardingStagesMap.SettingCMSConnection.name,
              isCompleted: true,
            })
          );
        }
      }
    } else {
      try {
        const accessToken = store.getState().auth.authorization.accessToken;
        const isWhiteLabel = store.getState().UI?.isWhiteLabel;
        let sellerToken: string;
        if (isWhiteLabel) {
          sellerToken = accessToken?.currentWhiteLabelsellerToken;
        } else {
          sellerToken = accessToken?.sellerToken;
        }

        const requestInfo: RequestInfo = {
          url: CMS_ENDPOINT,
          method: 'POST',
          body: applicationWWWXRLEncodedBodyBuilder({
            cms_type: selectedCMS,
            connection_data: JSON.stringify(cmsInputValues),
            shop_id: shopId,
            is_onboard: 'true', //testing
          }),
          headers: {
            Accept: 'application/json',
            'Content-type': 'application/x-www-form-urlencoded',
            Authorization: accessToken.type + ' ' + sellerToken,
          },
        };

        const actionType = 'ONBOARDING_CMS_CONNECTION';
        const customMsg = 'CMS Connection Successful.';

        const res: any = await asyncHandler(requestInfo, actionType, customMsg);

        const response = res.data;

        if (res.actionType === actionType && response.success === true) {
          dispatch(
            updateIntercom({
              cmsdatafilled: 'true',
            })
          );
          dispatch(
            updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name)
          );
          dispatch(
            setStageComplete({
              stage: OnboardingStagesMap.SettingCMSConnection.name,
              isCompleted: true,
            })
          );
        } else {
        }
      } catch (exception) {
        console.log('Error during authentication request');
      }
    }
  }

  function skip() {
    dispatch(updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name));
    dispatch(
      setStageComplete({
        stage: OnboardingStagesMap.SettingCMSConnection.name,
        isCompleted: true,
      })
    );
  }
  function goBack() {
    // dispatch(updateOnboardingStage(OnboardingStagesMap.IntegratingPSP1.name));
    dispatch(updateOnboardingStage(OnboardingStagesMap.IntegratingCMS1.name));
    dispatch(
      setStageComplete({
        stage: OnboardingStagesMap.IntegratingCMS1.name,
        isCompleted: false,
      })
    );
  }

  let isDisabled;
  if (cmsInputValues) {
    isDisabled = Object.values(cmsInputValues)?.some((el: any) => el === '');
  }

  const onInputChange = (e: any, value: string) => {
    setCMSInputValues((prev: any) => ({
      ...prev,
      [value]: e.target.value,
    }));
  };

  return (
    <motion.div
      {...pageSlideOutForward}
      className='enterCMSConnectionDetails  animate-fade_in_from_right'
    >
      <div className='enterCMSConnectionDetails__container '>
        <div className='enterCMSConnectionDetails__container--top'>
          <h1>
            {selectedCMS === 'other'
              ? t('OnboardingCustomCMS-H1')
              : t('OnboardingCMS-ConnectionH1')}
          </h1>
          <h2 className={`onboarding-cms-${selectedCMS}-connection-h2`}>
            {selectedCMS === 'other'
              ? t('OnboardingCustomCMS-H2')
              : t('OnboardingCMS-ConnectionH2')}
          </h2>
        </div>

        <form
          onSubmit={onContinue}
          id='onboarding-CMSConnection'
          className='enterCMSConnectionDetails__container--center'
        >
          {selectedCMS === 'other' && (
            <NormalInput
              id={`other-option-${optionType}`}
              value={customOption}
              changeListeners={[(e) => setCustomOption(e.target.value)]}
              focusListeners={[]}
              placeholder={t('CustomCMS-Option')}
              required={true}
              autoFocus={true}
              /* pattern='^[A-Za-z ]{2,30}$' */
              /* title='Name cannot exceed 30 characters' */
            />
          )}
          {selectedCMS !== 'other' && (
            <>
              {cmsInputs.map((el: string, index: number) => {
                return (
                  <div key={index}>
                    <NormalInput
                      changeListeners={[(e) => onInputChange(e, el)]}
                      id={`cms-integration-${el}`}
                      value={cmsInputValues ? cmsInputValues[el] : ''}
                      placeholder={makeFirstLetterCapital(
                        el.replaceAll('_', ' ')
                      )}
                      required={true}
                      autoFocus={index === 0 ? true : false}
                      classes={`onboarding-cms-${selectedCMS}-${el}-input`}
                      labelClasses={`onboarding-cms-${selectedCMS}-${el}-label`}
                    />
                  </div>
                );
              })}
            </>
          )}
        </form>
        <div className='enterCMSConnectionDetails__container--bottom'>
          <MainPurpleButton
            value={t('Continue')}
            disabled={isDisabled}
            type='submit'
            form={'onboarding-CMSConnection'}
          />
        </div>

        <div>
          <a className='skip-link margin-left-right-1rem' onClick={goBack}>
            &#8592; {t('GoBack')}
          </a>
          <a className='skip-link margin-left-right-1rem' onClick={skip}>
            {t('SkipStep')} &#8594;
          </a>
        </div>

        <div className='enterCMSConnectionDetails__container--footer'>
          <a target={'_blank'} href={`${merchantTheme?.terms_url}`}>
            {t('Terms')}
          </a>
          {` & `}
          <a target={'_blank'} href={`${merchantTheme?.privacy_url}`}>
            {t('Privacy')}
          </a>
        </div>
      </div>
    </motion.div>
  );
};

export default EnterCMSConnectionDetails;
