import { useLocation } from 'react-router-dom';
import SubPageInnerContentHolder from '../../../../components/subPageContentHolder/SubPageInnerContentHolder';
import store from '../../../../store/store';

const CMSOptions = () => {
  const location = useLocation();
  const activeLink = location.pathname;
  const isWhiteLabel = store.getState().UI?.isWhiteLabel;
  return (
    <SubPageInnerContentHolder
      title='CMS Options'
      subTitle='Manage CMS Options'
      backBtnLink={isWhiteLabel ? undefined : '/shop'}
      /* mainBtnIcon={plusSignSVG}
            mainBtnTitle='Create'
            mainBtnLink='/shop/integrations/autofill/add' */
    >
      {activeLink === '/shop/cms/options' && <div>CMS Options</div>}

      {/* <Routes>
              <Route path='/add' element={<AutofillAdd />} />
            </Routes> */}
    </SubPageInnerContentHolder>
  );
};

export default CMSOptions;
